import {
  ActiveFilter,
  EmployeeRole,
  RelationOrderByField,
  RelationsClient,
  RelationSnapShot,
  RelationType,
  SearchRelationRequest,
  SortOrder,
} from "@kolibri/search-api";
import { createThunk } from "helpers/store";

type FetchRelationsArgs = {
  skip: number;
  filterByActive: ActiveFilter;
  realEstateAgencyId: string;
  includeBackOfficeEmployees: boolean;
  order: SortOrder;
  orderBy: RelationOrderByField;
  type: RelationType;
  relationIds?: string[];
};
const fetchRelations = createThunk(
  "fetchRelations",
  async ({ http, handleError, settings }, args: FetchRelationsArgs) => {
    try {
      const client = new RelationsClient(settings?.search_api_url, http);
      const take = 100;
      const {
        filterByActive,
        order,
        orderBy,
        includeBackOfficeEmployees,
        skip,
      } = args;

      let employees: RelationSnapShot[];
      let request: SearchRelationRequest = {
        filterByActive,
        order,
        skip,
        take,
        includeStatistics: false,
        orderBy,
        includeArchivedEmployees: true,
        filterByRelationTypes: [args.type],
        includeBackOfficeEmployees,
        relationIds: args.relationIds?.length ? args.relationIds : undefined,
      };

      const response = await client.search(request, args.realEstateAgencyId);

      if (!response) {
        throw new Error("Could not fetch employees");
      }

      const { resultCount, results, totalResults } = response;
      employees = results || [];

      if (totalResults > take) {
        const laps = Math.ceil((totalResults - resultCount) / take);
        for (let i = 0; i < laps; i++) {
          request = { ...request, skip: (i + 1) * take };
          const items = await client
            .search(request, args.realEstateAgencyId)
            .then(response => response.results || []);
          employees = [...employees, ...items];
        }
      }

      return !includeBackOfficeEmployees
        ? employees
        : employees.filter(
            employee => employee.employeeRole === EmployeeRole.BackOffice
          );
    } catch (error) {
      handleError(error);
    }
  }
);

const thunks = { fetchRelations };
export default thunks;
