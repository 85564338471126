import {
  RealEstateAgencyClient,
  AppClientClient,
  GroupClient,
  FilterByActive,
} from "@kolibri/mls-api";
import { createThunk } from "helpers/store";
import { add, update } from "./slices";

const fetchRealEstateAgency = createThunk(
  "fetchRealEstateAgency",
  async ({ http, dispatch, getState, handleError, settings }, id: string) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new RealEstateAgencyClient(settings?.mls_api_url, http);

      const realEstateAgency = await client
        .read(id, realEstateAgencyId)
        .then(response => response.realEstateAgency);

      if (!realEstateAgency) {
        throw Error("Couldn't find realestate agency");
      }

      dispatch(add(realEstateAgency));
      return;
    } catch (error) {
      handleError(error);
    }
  }
);

type FetchRealEstateAgenciesArgs = {
  skip: number;
  name?: string;
  filterByActive: FilterByActive;
};
const fetchRealEstateAgencies = createThunk(
  "fetchRealEstateAgencies",
  async (
    { http, handleError, getState, settings },
    args: FetchRealEstateAgenciesArgs
  ) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new RealEstateAgencyClient(settings?.mls_api_url, http);
      const { skip, name, filterByActive } = args;

      const response = await client.search(
        {
          skip,
          take: 100,
          name,
          filterByActive,
        },
        realEstateAgencyId
      );

      if (!response) {
        throw new Error("Could not fetch realestate agencies");
      }

      const { totalResult, result } = response;

      return {
        totalResults: totalResult,
        results: result || [],
      };
    } catch (error) {
      handleError(error);
    }
  }
);

const enableMLS = createThunk(
  "enableMLS",
  async ({ http, dispatch, getState, handleError, settings }, id: string) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new RealEstateAgencyClient(settings?.mls_api_url, http);

      const realEstateAgency = await client
        .activate({ realEstateAgencyId: id }, realEstateAgencyId)
        .then(response => response.realEstateAgency);

      if (!realEstateAgency) {
        throw Error("Couldn't activate realestate agency");
      }

      dispatch(update({ id: realEstateAgency.id, changes: realEstateAgency }));
      return;
    } catch (error) {
      handleError(error);
    }
  }
);

const disableMLS = createThunk(
  "disableMLS",
  async ({ http, dispatch, getState, handleError, settings }, id: string) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new RealEstateAgencyClient(settings?.mls_api_url, http);

      const realEstateAgency = await client
        .deactivate({ realEstateAgencyId: id }, realEstateAgencyId)
        .then(response => response.realEstateAgency);

      if (!realEstateAgency) {
        throw Error("Couldn't deactivate realestate agency");
      }

      dispatch(update({ id: realEstateAgency.id, changes: realEstateAgency }));
      return;
    } catch (error) {
      handleError(error);
    }
  }
);

const fetchAppClients = createThunk(
  "fetchAppClients",
  async ({ http, getState, handleError, settings }, id: string) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new AppClientClient(settings?.mls_api_url, http);

      return client
        .search({ skip: 0, take: 100 }, realEstateAgencyId)
        .then(response => response.result);
    } catch (error) {
      handleError(error);
    }
  }
);

const fetchGroups = createThunk(
  "fetchGroups",
  async ({ http, getState, handleError, settings }, id: string) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new GroupClient(settings?.mls_api_url, http);

      return client
        .search({ skip: 0, take: 100 }, realEstateAgencyId)
        .then(response => response.result);
    } catch (error) {
      handleError(error);
    }
  }
);

type AddToAppClientArgs = {
  realEstateAgencyId: string;
  appClientKey: string;
};
const addToAppClient = createThunk(
  "addToAppClient",
  async (
    { http, getState, dispatch, handleError, settings },
    args: AddToAppClientArgs
  ) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new RealEstateAgencyClient(settings?.mls_api_url, http);

      await client.addToAppClient(args, realEstateAgencyId);
      const realEstateAgency = await client
        .read(args.realEstateAgencyId, realEstateAgencyId)
        .then(response => response.realEstateAgency);

      if (!realEstateAgency) {
        throw Error("Couldn't find realestate agency");
      }

      dispatch(update({ id: realEstateAgency.id, changes: realEstateAgency }));
      return;
    } catch (error) {
      handleError(error);
    }
  }
);

type RemoveFromAppClientArgs = {
  realEstateAgencyId: string;
  appClientKey: string;
};
const removeFromAppClient = createThunk(
  "removeFromAppClient",
  async (
    { http, getState, dispatch, handleError, settings },
    args: RemoveFromAppClientArgs
  ) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new RealEstateAgencyClient(settings?.mls_api_url, http);

      await client.removeFromAppClient(args, realEstateAgencyId);
      const realEstateAgency = await client
        .read(args.realEstateAgencyId, realEstateAgencyId)
        .then(response => response.realEstateAgency);

      if (!realEstateAgency) {
        throw Error("Couldn't find realestate agency");
      }

      dispatch(update({ id: realEstateAgency.id, changes: realEstateAgency }));
      return;
    } catch (error) {
      handleError(error);
    }
  }
);

type AddToGroup = {
  realEstateAgencyId: string;
  groupName: string;
};
const addToGroup = createThunk(
  "addToGroup",
  async ({ http, getState, dispatch, handleError, settings }, args: AddToGroup) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new RealEstateAgencyClient(settings?.mls_api_url, http);

      await client.addToGroup(args, realEstateAgencyId);
      const realEstateAgency = await client
        .read(args.realEstateAgencyId, realEstateAgencyId)
        .then(response => response.realEstateAgency);

      if (!realEstateAgency) {
        throw Error("Couldn't find realestate agency");
      }

      dispatch(update({ id: realEstateAgency.id, changes: realEstateAgency }));
      return;
    } catch (error) {
      handleError(error);
    }
  }
);

type RemoveFromGroupArgs = {
  realEstateAgencyId: string;
  groupName: string;
};
const removeFromGroup = createThunk(
  "removeFromGroup",
  async (
    { http, getState, dispatch, handleError, settings },
    args: RemoveFromGroupArgs
  ) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new RealEstateAgencyClient(settings?.mls_api_url, http);

      await client.removeFromGroup(args, realEstateAgencyId);
      const realEstateAgency = await client
        .read(args.realEstateAgencyId, realEstateAgencyId)
        .then(response => response.realEstateAgency);

      if (!realEstateAgency) {
        throw Error("Couldn't find realestate agency");
      }

      dispatch(update({ id: realEstateAgency.id, changes: realEstateAgency }));
      return;
    } catch (error) {
      handleError(error);
    }
  }
);

const thunks = {
  fetchRealEstateAgency,
  fetchRealEstateAgencies,
  enableMLS,
  disableMLS,
  fetchAppClients,
  fetchGroups,
  addToAppClient,
  removeFromAppClient,
  addToGroup,
  removeFromGroup,
};
export default thunks;
