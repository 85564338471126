import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AccountSettings,
  CompanySettings,
  GroupSetting,
} from "@kolibri/core-api";

type State = {
  accountSettings: AccountSettings | null;
  companySettings: CompanySettings | null;
  groupSettings: GroupSetting[];
};

const initialState: State = {
  accountSettings: null,
  companySettings: null,
  groupSettings: [],
};

export const slice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setAccountSettings: (state, action: PayloadAction<AccountSettings>) => {
      return {
        ...state,
        accountSettings: action.payload,
      };
    },
    setCompanySettings: (state, action: PayloadAction<CompanySettings>) => {
      return {
        ...state,
        companySettings: action.payload,
      };
    },
    setGroupSettings: (state, action: PayloadAction<GroupSetting[]>) => {
      return {
        ...state,
        groupSettings: action.payload,
      };
    },
  },
});
