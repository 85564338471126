import { Employee } from "@kolibri/core-api";
import { UserEntity } from "@kolibri/id-api";
import { combineReducers } from "redux";
import { RootState } from "store";
import {
  actions as employeesActions,
  reducer as employeesReducer,
  selectors as employeesSelectors,
  thunks as employeesThunks,
} from "./employees";
import {
  actions as userEntitiesActions,
  reducer as userEntitiesReducer,
  selectors as userEntitiesSelectors,
  thunks as userEntitiesThunks,
} from "./user-entities";

export const reducer = combineReducers({
  employees: employeesReducer,
  userEntities: userEntitiesReducer,
});

export const actions = {
  employees: employeesActions,
  userEntities: userEntitiesActions,
};

export const thunks = {
  employees: employeesThunks,
  userEntities: userEntitiesThunks,
};

export const selectors = {
  employees: employeesSelectors,
  userEntities: userEntitiesSelectors,
  originalShape: (state: RootState, id: string) => {
    const employee = employeesSelectors.original.selectById(state, id);
    const userEntity = userEntitiesSelectors.original
      .selectAll(state)
      .find(userEntity => userEntity.personId === id);

    return {
      employee: employee || null,
      userEntity: userEntity || null,
    } as EditableEmployeeShape;
  },
  editableShape: (state: RootState, id: string) => {
    const employee = employeesSelectors.editable.selectById(state, id);
    const userEntity = userEntitiesSelectors.editable
      .selectAll(state)
      .find(userEntity => userEntity.personId === id);

    return {
      employee: employee || null,
      userEntity: userEntity || null,
    } as EditableEmployeeShape;
  },
};

export type EditableEmployeeShape = {
  employee: Employee | null;
  userEntity: UserEntity | null;
};
