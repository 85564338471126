import escapeRegExp from "lodash-es/escapeRegExp";

export const mapRouteParams = (
  route: string,
  values: Record<string, string>,
  hash?: string
) => {
  let returnValue = route;

  for (const key in values) {
    const replaceWith = values[key];
    const match = new RegExp(`:${escapeRegExp(key)}`, "g");
    if (!!match) {
      returnValue = returnValue.replace(match, replaceWith);
    }
  }

  if (hash) {
    returnValue = `${returnValue}#${hash}`;
  }

  return returnValue;
};
