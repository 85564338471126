import React from "react";
import { ListColumnProps, ListRowProps } from "../../";

type Props = {
  index: number;
  style: React.CSSProperties;
  hover: boolean;
  getColumnConfig: (id: string) => ListColumnProps<any> | undefined;
  rowRenderer?: (props: ListRowProps) => JSX.Element;
  fixedRowRenderer?: (props: ListRowProps) => JSX.Element;
  setHoverIndex: (index: number | undefined) => void;
  onRowClick?: (index: number) => void;
};

export class RowWrapperComponent extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
    this.onRowClickHandler = this.onRowClickHandler.bind(this);
  }

  // Disable for testings purposes
  // shouldComponentUpdate(nextProps: Props) {
  //   return (
  //     this.props.index !== nextProps.index ||
  //     this.props.hover !== nextProps.hover
  //   );
  // }

  render() {
    const {
      rowRenderer,
      fixedRowRenderer,
      getColumnConfig,
      index,
      style,
      hover,
    } = this.props;

    const zebra = index % 2 === 0;
    const payload = { index, zebra, getColumnConfig, hover };

    return (
      <div
        style={style}
        onMouseEnter={this.onMouseEnterHandler}
        onClick={this.onRowClickHandler}
      >
        {rowRenderer?.(payload) || fixedRowRenderer?.(payload) || null}
      </div>
    );
  }

  onMouseEnterHandler() {
    this.props.setHoverIndex(this.props.index);
  }

  onRowClickHandler() {
    this.props.onRowClick?.(this.props.index);
  }
}
