import React, { FC } from "react";
import styles from "./style.module.scss";
import classnames from "classnames";

type Props = {
  style?: React.CSSProperties;
  className?: string;
  title: string;
};

export const SectionComponent: FC<Props> = ({
  style,
  className,
  title,
  children,
}) => {
  return (
    <div className={classnames(styles.section, className)} style={style}>
      <h2>{title}</h2>
      {children}
    </div>
  );
};
