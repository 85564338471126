import { Button, Field, Modal } from "@kolibri/ui";
import { unwrapResult } from "@reduxjs/toolkit";
import { Form, Formik } from "formik";
import { useDispatch } from "hooks/dispatch";
import { useNavigate } from "hooks/navigate";
import React, { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { thunks as employeesThunks } from "store/employees";
import { thunks as realEstateAgenciesThunks } from "store/real-estate-agencies";
import styles from "./style.module.scss";

type Props = {
  name?: string;
  visible: boolean;
  onClose: () => void;
  onSubmit?: (realEstateAgencyId: string) => void;
};

export const CreateEmployeeComponent: FC<Props> = ({
  visible,
  name,
  onClose,
  onSubmit,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const translations = useMemo(() => {
    return {
      title: intl.formatMessage({ id: "createNewEmployeeModal.title" }),
      body: intl.formatMessage({ id: "createNewEmployeeModal.body" }),
      createUser: intl.formatMessage({
        id: "createNewEmployeeModal.action.createUser",
      }),
      selectPlaceholder: intl.formatMessage({
        id: "createNewEmployeeModal.select.placeholder",
      }),
      emptyState: intl.formatMessage({
        id: "createNewEmployeeModal.select.emptyState",
      }),
    };
  }, [intl]);

  const onSubmitCallback = (values: { realEstateAgencyId: string }) => {
    const { realEstateAgencyId } = values;
    if (!realEstateAgencyId) return;

    if (typeof onSubmit === "function") {
      onSubmit(realEstateAgencyId);
      return;
    }

    dispatch(
      employeesThunks.employees.createNewEmployee({
        realEstateAgencyId,
        name,
        navigate,
      })
    );
    onClose();
  };

  const search = async (term: string) => {
    return await dispatch(
      realEstateAgenciesThunks.cache.searchRealEstateAgencies({ term })
    )
      .then(unwrapResult)
      .then(response => response || []);
  };

  return !visible ? null : (
    <Formik
      initialValues={{ realEstateAgencyId: "" }}
      onSubmit={onSubmitCallback}
    >
      {({ values, submitForm }) => (
        <Modal
          visible={visible}
          onClose={onClose}
          title={translations.title}
          size="small"
          actions={() => (
            <Button
              theme="primary"
              disabled={!values.realEstateAgencyId}
              onClick={submitForm}
            >
              {translations.createUser}
            </Button>
          )}
        >
          <div className={styles.body}>
            <div className={styles.body__text}>{translations.body}</div>
            <Form>
              <Field.Select
                name="realEstateAgencyId"
                matchOn={{ in: "id" }}
                values={[]}
                query={search}
                labelFn={value => (
                  <div className={styles.selectItem}>{value?.name}</div>
                )}
                style={{ width: "100%" }}
                placeholder={translations.selectPlaceholder}
                queryPlaceholder={translations.selectPlaceholder}
                emptyStateLabel={translations.emptyState}
                mapValue={value => value?.id}
              >
                {({ item }) => (
                  <div className={styles.selectItem}>{item?.name}</div>
                )}
              </Field.Select>
            </Form>
          </div>
        </Modal>
      )}
    </Formik>
  );
};
