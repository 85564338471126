import { RealEstateAgencySnapShot } from "@kolibri/search-api";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

export const adapter = createEntityAdapter<RealEstateAgencySnapShot>();

export const slice = createSlice({
  name: "real-estate-agencies/cache",
  initialState: adapter.getInitialState(),
  reducers: {
    setAll: adapter.setAll,
  },
});
