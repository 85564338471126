import { RequestStatus } from "enums/request-status";
import React, { FC, useCallback, useEffect, useRef } from "react";
import { IntlProvider } from "react-intl";
import ErrorScreen from "screens/error";
import LayoutScreen from "screens/layout";
import LoadingScreen from "screens/loading";
import { useContainer } from "./controller";
import Routing from "./routing";
import HoistIntlContext from "components/hoist-intl-context";
import { UiIntlProvider } from "@kolibri/ui";
import AccountOnBehalfOfSCreen from "screens/account-on-behalf-of";

type ExtendedWindow = Window & {
  onUsersnapLoad: Function;
  Usersnap: any;
};

declare let window: ExtendedWindow;

export const RootModule: FC = () => {
  const { appStatus, locale, messages, settings } = useContainer();
  const isUsersnapBinded = useRef(false);

  const bindUsersnap = useCallback(() => {
    if (
      process.env.NODE_ENV === "development" ||
      isUsersnapBinded.current ||
      (!!settings?.Environment &&
        ["Local", "Sandbox"].includes(settings.Environment))
    ) {
      return;
    }

    isUsersnapBinded.current = true;

    window.onUsersnapLoad = (api: any) => {
      api.init();
      window.Usersnap = api;
    };

    const script = document.createElement("script");
    script.async = true;
    script.src = `https://api.usersnap.com/load/177fd875-8f1f-4552-b2c5-2b660fcb6dc8.js?onload=onUsersnapLoad`;
    document.getElementsByTagName("head")[0].appendChild(script);
  }, [settings]);

  useEffect(() => {
    bindUsersnap();
  }, [bindUsersnap]);

  switch (appStatus) {
    case RequestStatus.Success: {
      return (
        <IntlProvider locale={locale} messages={messages}>
          <UiIntlProvider locale={locale} messages={messages}>
            <HoistIntlContext />
            <LayoutScreen>
              <Routing />
            </LayoutScreen>
          </UiIntlProvider>
        </IntlProvider>
      );
    }
    case RequestStatus.Failed: {
      return <ErrorScreen />;
    }
    case RequestStatus.AccountOnBehalfOf: {
      return <AccountOnBehalfOfSCreen />;
    }
    default: {
      return <LoadingScreen />;
    }
  }
};
