import { PublicationsClient, PublicationStatus } from "@kolibri/core-api";
import { createThunk } from "helpers/store";
import { slice } from "./slices";
import { mapRouteParams } from "mappers/route";

type FetchArgs = { assignmentId: string; realEstateAgencyId: string };
const fetchPublications = createThunk(
  "fetchPublications",
  async ({ http, dispatch, handleError, settings }, args: FetchArgs) => {
    try {
      const client = new PublicationsClient(settings?.core_api_url, http);
      const { realEstateAgencyId, assignmentId } = args;

      const publications = await client
        .search(
          {
            assignmentId,
          },
          realEstateAgencyId
        )
        .then(response => response?.results || [])
        .then(response =>
          response.filter(
            publication =>
              publication.publicationStatus === PublicationStatus.Rejected &&
              publication.isActive
          )
        );

      dispatch(slice.actions.add(publications));
      return;
    } catch (error) {
      handleError(error);
    }
  }
);

type FetchListArgs = { assignmentId: string; realEstateAgencyId: string };
const fetchListPublications = createThunk(
  "fetchListPublications",
  async ({ http, handleError, settings }, args: FetchListArgs) => {
    try {
      const client = new PublicationsClient(settings?.core_api_url, http);
      const { realEstateAgencyId, assignmentId } = args;

      return await client
        .search(
          {
            assignmentId,
          },
          realEstateAgencyId
        )
        .then(response =>
          (response?.results || []).filter(publication => publication.isActive)
        );
    } catch (error) {
      handleError(error);
    }
  }
);

type ForcePublicationsArgs = {
  assignmentId: string;
  realEstateAgencyId: string;
  ignoreIsAcceptableForPublication: boolean;
};
const forcePublications = createThunk(
  "forcePublications",
  async ({ http, handleError, settings }, args: ForcePublicationsArgs) => {
    try {
      const client = new PublicationsClient(settings?.core_api_url, http);
      const {
        realEstateAgencyId,
        assignmentId,
        ignoreIsAcceptableForPublication,
      } = args;

      await client.forcePublication(
        { assignmentId, ignoreIsAcceptableForPublication },
        realEstateAgencyId
      );

      return;
    } catch (error) {
      handleError(error);
    }
  }
);

const thunks = {
  fetchPublications,
  fetchListPublications,
  forcePublications,
};
export default thunks;
