import { useCallback } from "react";
import { useIntl } from "react-intl";

export const useTranslation = () => {
  const { formatMessage } = useIntl();

  const translate = useCallback(
    (
      id: string,
      options?: { defaultMessage?: string; values: Record<string, any> }
    ) => {
      try {
        return formatMessage(
          { id, defaultMessage: options?.defaultMessage || id },
          options?.values
        );
      } catch (_) {
        return options?.defaultMessage || id;
      }
    },
    [formatMessage]
  );

  return (
    id: string,
    options?: { defaultMessage?: string; values: Record<string, any> }
  ) => (!id ? "" : translate(id, options));
};
