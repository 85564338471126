import { BasicTranslation } from "@kolibri/authorization-api";
import { AUTHORIZATION_LANGUAGES } from "constants/config";

export const mapBasicTranslations = (
  translations?: BasicTranslation[] | null
) => {
  return AUTHORIZATION_LANGUAGES.map(language => {
    const match = translations?.find(
      t => t.languageCulture === language.locale
    );

    return {
      languageCulture: language.locale,
      title: match?.title || "",
      description: match?.description || "",
    };
  });
};
