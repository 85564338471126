import { faBuilding, faPlus, faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Context, MenuItem } from "@kolibri/ui";
import CreateEmployee from "components/create-employee";
import { useNavigate } from "hooks/navigate";
import { useSelector } from "hooks/selector";
import React, { FC, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { editablesSelector } from "store/editables";
import { thunks as realEstateAgenciesThunks } from "store/real-estate-agencies";
import Tab from "../tab";
import styles from "./style.module.scss";

export const TabsComponent: FC = () => {
  const editables = useSelector(editablesSelector.selectAll);
  const createRef = useRef<HTMLDivElement | null>(null);
  const [createContextVisible, setCreateContextVisible] = useState(false);
  const [createEmployeeVisible, setCreateEmployeeVisible] = useState(false);
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleCreateContextVisible = () => {
    setCreateContextVisible(!createContextVisible);
  };

  const createNewUserAccount = () => {
    setCreateEmployeeVisible(true);
    setCreateContextVisible(false);
  };

  const closeCreateEmployee = () => {
    setCreateEmployeeVisible(false);
  };

  const createNewRealEstateAgency = () => {
    dispatch(
      realEstateAgenciesThunks.realEstateAgencies.createNewRealEstateAgency({
        name: "",
        navigate,
      })
    );
  };

  const translations = useMemo(() => {
    return {
      addNewOffice: intl.formatMessage({ id: "createMenu.label.addNewOffice" }),
      addNewUserAccount: intl.formatMessage({
        id: "createMenu.label.addNewUserAccount",
      }),
    };
  }, [intl]);

  return (
    <div className={styles.tabs}>
      <div
        className={styles.create}
        onClick={toggleCreateContextVisible}
        ref={createRef}
      >
        <div className={styles.typeIcon}>
          <FontAwesomeIcon icon={faPlus as any} fixedWidth />
        </div>
        <div className={styles.label}>
          <FormattedMessage id="root.createNewEntity" />
        </div>
      </div>

      <CreateEmployee
        visible={createEmployeeVisible}
        onClose={closeCreateEmployee}
      />

      <Context
        parent={createRef}
        visible={createContextVisible}
        onClose={() => setCreateContextVisible(false)}
        vOffset={-6}
        hOffset={6}
      >
        <div className={styles.context}>
          <MenuItem
            label={translations.addNewOffice}
            icon={
              <FontAwesomeIcon
                icon={faBuilding as any}
                className={styles.createItemIcon}
              />
            }
            className={styles.createItem}
            onClick={() => {
              createNewRealEstateAgency();
              setCreateContextVisible(false);
            }}
          />
          <MenuItem
            label={translations.addNewUserAccount}
            icon={
              <FontAwesomeIcon
                icon={faUser as any}
                className={styles.createItemIcon}
              />
            }
            className={styles.createItem}
            onClick={createNewUserAccount}
          />
        </div>
      </Context>

      {editables.map((state, idx) => (
        <Tab key={state.id + idx} editable={state} />
      ))}
    </div>
  );
};
