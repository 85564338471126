import React, { FC } from "react";
import styles from "./style.module.scss";

type Props = {
  title: string;
};

export const FormSectionTitleComponent: FC<Props> = ({ title }: Props) => {
  return <h2 className={styles.sectionTitle}>{title}</h2>;
};
