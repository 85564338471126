import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  IconDefinition,
  faExclamationTriangle,
} from "@fortawesome/pro-regular-svg-icons";

type Props = {
  iconName?: string;
  style?: React.CSSProperties;
};

export const CommonIconComponent: FC<Props> = ({ iconName, style }) => {
  let icon: IconDefinition | null = null;

  switch (iconName) {
    case "faFolder": {
      icon = faFolder;
      break;
    }
    case "faExclamationTriangle": {
      icon = faExclamationTriangle;
      break;
    }
    default: {
      break;
    }
  }

  return !icon ? null : <FontAwesomeIcon icon={icon as any} style={style} />;
};
