import {
  faChevronDown,
  faChevronUp,
  faSearch,
  faSpinner,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActiveFilter,
  AssignmentSnapShot,
  HelpdeskSearchType,
  RealEstateAgencySnapShot,
  RelationSnapShot,
} from "@kolibri/search-api";
import CreateEmployee from "components/create-employee";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import AssignmentItem from "./components/assignment-item";
import EmployeeItem from "./components/employee-item";
import RealEstateAgencyItem from "./components/realestate-agency-item";
import Section from "./components/section";
import { useController } from "./controller";
import styles from "./style.module.scss";

export const GlobalSearchComponent: FC = () => {
  const {
    wrapperRefCallback,
    focusCallback,
    input,
    query,
    onChangeCallback,
    loading,
    clearCallback,
    toggleDropdownCallback,
    dropdownVisible,
    dropdownStyles,
    dropdown,
    validQueryLength,
    filterByTypes,
    realEstateAgenciesTotalCount,
    realEstateAgencies,
    realEstateAgenciesRemainingCount,
    employeesTotalCount,
    employees,
    employeesRemainingCount,
    assignmentsTotalCount,
    assignments,
    assignmentsRemainingCount,
    toggleFilterByTypes,
    onLoadMoreCallback,
    triggerLabel,
    filterByActive,
    toggleFilterByActive,
    navigateToEmployee,
    navigateToAssignment,
    createNewEmployee,
    navigateToRealEstateAgency,
    createNewRealEstateAgency,
    createEmployeeVisible,
    closeCreateEmployee,
  } = useController();

  // const keyUpEventListener = useCallback(
  //   (event: KeyboardEvent) => {
  //     if ((event.target as HTMLElement).tagName === "INPUT") {
  //       return;
  //     }

  //     if (
  //       input.current !== document.activeElement &&
  //       ["s", "f"].includes(event.key)
  //     ) {
  //       input.current?.focus();
  //     }
  //   },
  //   [input]
  // );

  // useEffect(() => {
  //   window.addEventListener("keyup", keyUpEventListener, true);

  //   return () => {
  //     window.removeEventListener("keyup", keyUpEventListener, true);
  //   };
  // }, [keyUpEventListener]);

  return (
    <div className={styles.globalSearch} ref={wrapperRefCallback as any}>
      <div className={styles.globalSearch__wrapper}>
        <div className={styles.search} onClick={focusCallback}>
          <FontAwesomeIcon icon={faSearch as any} />
        </div>
        <input
          type="text"
          name="query"
          ref={input}
          value={query}
          onChange={onChangeCallback}
          maxLength={50}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck={false}
        />
        {(loading || !!query) && (
          <div className={styles.status} onClick={clearCallback}>
            <FontAwesomeIcon
              icon={(loading ? faSpinner : faTimes) as any}
              spin={loading}
            />
          </div>
        )}
        <button type="button" onClick={toggleDropdownCallback}>
          <span>{triggerLabel}</span>
          <FontAwesomeIcon
            icon={(dropdownVisible ? faChevronUp : faChevronDown) as any}
          />
        </button>
      </div>

      {dropdownVisible && (
        <div className={styles.dropdown__overlay}>
          <div
            className={styles.dropdown}
            style={dropdownStyles}
            ref={dropdown}
          >
            <div className={styles.dropdown__results}>
              {/** Start result sections */}

              {validQueryLength && !filterByTypes.length && (
                <div className={styles.noTypesSelected}>
                  <FormattedMessage id="helpdesk.search.noTypesSelected" />
                </div>
              )}

              {!validQueryLength && (
                <div className={styles.noQuery}>
                  <FormattedMessage id="helpdesk.search.noQuery" />
                </div>
              )}

              {/** Section offices */}
              <Section
                type={HelpdeskSearchType.RealEstateAgencies}
                visible={
                  validQueryLength &&
                  filterByTypes.includes(HelpdeskSearchType.RealEstateAgencies)
                }
                totalCount={realEstateAgenciesTotalCount}
                remainingCount={realEstateAgenciesRemainingCount}
                values={realEstateAgencies?.results || []}
                onLoadMore={onLoadMoreCallback}
                onDefineNew={createNewRealEstateAgency}
              >
                {(item: RealEstateAgencySnapShot, key) => (
                  <RealEstateAgencyItem
                    key={item.id + key}
                    item={item}
                    query={query}
                    onClick={navigateToRealEstateAgency}
                  />
                )}
              </Section>

              {/** Section accounts */}
              <Section
                type={HelpdeskSearchType.Employees}
                visible={
                  validQueryLength &&
                  filterByTypes.includes(HelpdeskSearchType.Employees)
                }
                totalCount={employeesTotalCount}
                remainingCount={employeesRemainingCount}
                values={employees?.results || []}
                onLoadMore={onLoadMoreCallback}
                onDefineNew={createNewEmployee}
              >
                {(item: RelationSnapShot, key) => (
                  <EmployeeItem
                    key={item.id + key}
                    item={item}
                    query={query}
                    onClick={navigateToEmployee}
                  />
                )}
              </Section>

              {/** Section objects */}
              <Section
                type={HelpdeskSearchType.Assignments}
                visible={
                  validQueryLength &&
                  filterByTypes.includes(HelpdeskSearchType.Assignments)
                }
                totalCount={assignmentsTotalCount}
                remainingCount={assignmentsRemainingCount}
                values={assignments?.results || []}
                onLoadMore={onLoadMoreCallback}
              >
                {(item: AssignmentSnapShot, key) => (
                  <AssignmentItem
                    key={item.id + key}
                    item={item}
                    query={query}
                    onClick={navigateToAssignment}
                  />
                )}
              </Section>
              {/** End result sections */}
            </div>
            <div className={styles.dropdown__selection}>
              <label>
                <input
                  type="checkbox"
                  checked={filterByTypes.includes(
                    HelpdeskSearchType.RealEstateAgencies
                  )}
                  onChange={() =>
                    toggleFilterByTypes(HelpdeskSearchType.RealEstateAgencies)
                  }
                />
                <span className={styles.checkbox__label}>
                  <FormattedMessage id="helpdesk.search.label.RealEstateAgencies" />
                </span>
              </label>

              <label>
                <input
                  type="checkbox"
                  checked={filterByTypes.includes(HelpdeskSearchType.Employees)}
                  onChange={() =>
                    toggleFilterByTypes(HelpdeskSearchType.Employees)
                  }
                />
                <span className={styles.checkbox__label}>
                  <FormattedMessage id="helpdesk.search.label.Employees" />
                </span>
              </label>

              <label>
                <input
                  type="checkbox"
                  checked={filterByTypes.includes(
                    HelpdeskSearchType.Assignments
                  )}
                  onChange={() =>
                    toggleFilterByTypes(HelpdeskSearchType.Assignments)
                  }
                />
                <span className={styles.checkbox__label}>
                  <FormattedMessage id="helpdesk.search.label.Assignments" />
                </span>
              </label>

              <div className={styles.searchInArchive}>
                <label>
                  <input
                    type="checkbox"
                    checked={filterByActive === ActiveFilter.ActiveOrInactive}
                    onChange={toggleFilterByActive}
                  />
                  <span className={styles.checkbox__label}>
                    <FormattedMessage id="helpdesk.search.label.toggleFilterByActive" />
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
      <CreateEmployee
        name={query}
        visible={createEmployeeVisible}
        onClose={closeCreateEmployee}
      />
    </div>
  );
};
