import {
  ActiveFilter,
  AssignmentClient,
  AssignmentOrderByField,
  AssignmentTermField,
  AvailabilityStatus,
  SearchAssignmentRequest,
  SortOrder,
} from "@kolibri/search-api";
import { createThunk } from "helpers/store";

type FetchAssignmentArgs = {
  skip: number;
  order: SortOrder;
  orderBy: AssignmentOrderByField;
  filterByActive: ActiveFilter;
  realEstateAgencyId: string;
  term: string;
  termFields: AssignmentTermField[];
  availabilityStatuses: AvailabilityStatus[];
  assignmentIds: string[];
  forSaleOrRent: "forSale" | "forRent" | "";
};
const fetchAssignments = createThunk(
  "fetchAssignments",
  async ({ http, handleError, settings }, args: FetchAssignmentArgs) => {
    try {
      const {
        skip,
        filterByActive,
        realEstateAgencyId,
        order,
        orderBy,
        term,
        termFields,
        availabilityStatuses,
        assignmentIds,
        forSaleOrRent,
      } = args;
      const client = new AssignmentClient(settings?.search_api_url, http);
      const take = 100;

      let request: SearchAssignmentRequest = {
        filterByActive,
        order,
        skip,
        take,
        includeStatistics: false,
        orderBy,
        term,
        termFields,
        availabilityStatuses,
        assignmentIds,
        forSale: forSaleOrRent === "forSale",
        forRent: forSaleOrRent === "forRent",
      };

      const response = await client.search(request, realEstateAgencyId);

      if (!response) {
        throw new Error("Could not fetch objects");
      }

      return response;
    } catch (error) {
      handleError(error);
    }
  }
);

const thunks = { fetchAssignments };
export default thunks;
