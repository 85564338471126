import { EmailAddressType, Employee, EmployeesClient } from "@kolibri/core-api";
import {
  Client,
  Client as UserEntitiesClient,
  UserEntity,
} from "@kolibri/id-api";
import {
  ActiveFilter,
  HelpdeskSearchClient,
  HelpdeskSearchSearchRequest,
  HelpdeskSearchType,
  RelationOrderByField,
  RelationsClient,
  RelationSnapShot,
  RelationType,
  SearchRelationRequest,
  SortOrder,
} from "@kolibri/search-api";
import { EMPLOYEES } from "constants/routes";
import { EditableType } from "enums/editable-types";
import { RequestStatus } from "enums/request-status";
import { createThunk } from "helpers/store";
import { mapRouteParams } from "mappers/route";
import { editablesActions, editablesSelector } from "store/editables";
import { actions as errorsActions } from "store/errors";
import { mainActions } from "store/main";
import { selectors as realEstateAgenciesSelectors } from "store/real-estate-agencies";
import { actions as toastsActions, Toast } from "store/toasts";
import { batch } from "react-redux";
import { v4 as uuid } from "uuid";
import {
  actions as employeesActions,
  EditableEmployeeShape,
  selectors as employeesSelectors,
} from "../";
import noop from "lodash-es/noop";

type FetchArgs = { id: string; realEstateAgencyId: string };
const fetchEmployee = createThunk(
  "fetchEmployee",
  async ({ http, dispatch, settings }, args: FetchArgs) => {
    try {
      const employeesClient = new EmployeesClient(settings?.core_api_url, http);
      const userEntitiesClient = new UserEntitiesClient(
        settings?.id_api_url,
        http
      );

      const employee = await employeesClient
        .read(args.id, args.realEstateAgencyId)
        .then(response => response?.employee);

      if (!employee) {
        throw Error("Couldn't find employee");
      }

      dispatch(employeesActions.employees.add(employee));

      const userEntity = await userEntitiesClient
        .usersGET(employee.id, employee.realEstateAgencyId)
        .then(response => response.user)
        .catch(_ => {
          /** Do nothing */
        });

      if (userEntity) {
        dispatch(employeesActions.userEntities.add(userEntity));
      }

      return employee;
    } catch (error) {
      throw error;
    }
  }
);

type CreateNewEmployeeArgs = {
  name?: string | null;
  realEstateAgencyId: string;
  queryParams?: URLSearchParams;
  history?: any;
  navigate: (path: string) => void;
};
const createNewEmployee = createThunk(
  "createNewEmployee",
  async (
    { dispatch, http, getState, settings },
    args: CreateNewEmployeeArgs
  ) => {
    try {
      const employeesClient = new EmployeesClient(settings?.core_api_url, http);
      const { realEstateAgencyId, name, queryParams, history, navigate } = args;
      const state = getState();
      const realEstateAgency = realEstateAgenciesSelectors.cache.selectById(
        state,
        realEstateAgencyId
      );

      const emailAddress = queryParams?.get("email");
      const phoneNumber = queryParams?.get("phoneNumber");

      const response = await employeesClient
        .defineNew({}, realEstateAgencyId)
        .then(response => response?.employee);
      if (!response) throw Error("Could not create new employee");
      let employee = { ...response };

      if (!!name) {
        const [firstName, ...lastNameChunks] = name.split(" ");
        const lastName = lastNameChunks.join(" ");

        employee = {
          ...employee,
          firstName,
          lastName,
        };
      }

      const { id } = employee;
      const rootPath = mapRouteParams(EMPLOYEES.detail, {
        id,
        realEstateAgencyId,
      });
      const currentPath = `${rootPath}/edit`;

      let emptyUserEntity: UserEntity = {
        personId: id,
        id: uuid(),
        emailAddress,
        phoneNumber,
      };

      if (!!(employee.firstName || name) && !!realEstateAgency) {
        const userName = [employee.firstName || name, realEstateAgency.name]
          .filter(d => !!d)
          .join("@")
          .toLowerCase()
          .replace(/\s/g, "");

        if (!!userName) {
          emptyUserEntity = {
            ...emptyUserEntity,
            userName,
          };
        }
      }

      batch(() => {
        dispatch(employeesActions.employees.add(employee));
        dispatch(employeesActions.userEntities.add(emptyUserEntity));
        dispatch(
          editablesActions.addEditable({
            id,
            type: EditableType.Employee,
            rootPath,
            currentPath,
            referrer: !history ? window.location.pathname || "/" : "/",
            hasChanges: false,
            hasError: false,
            isSaving: false,
            title: employee?.displayName || "...",
          })
        );
      });

      history?.replace("/");
      navigate(currentPath);
    } catch (error) {
      throw error;
    }
  }
);

export type SaveEmployeeArgs = EditableEmployeeShape & {
  password?: string;
  sendMail?: boolean;
};
type SaveEmployeeExtraArgs = {
  navigate: (path: string) => void;
  close: boolean;
};
const saveEmployee = createThunk(
  "saveEmployee",
  async (
    { http, dispatch, getState, settings, handleError },
    args: SaveEmployeeArgs & SaveEmployeeExtraArgs
  ) => {
    try {
      const employeesClient = new EmployeesClient(settings?.core_api_url, http);
      const userEntitiesClient = new UserEntitiesClient(
        settings?.id_api_url,
        http
      );
      const {
        password,
        sendMail,
        employee: originalEmployee,
        userEntity,
        navigate,
        close,
      } = args;

      if (!originalEmployee) {
        throw new Error("No employee data found");
      }

      if (!!originalEmployee.isNew && !password) {
        throw new Error("No password provided");
      }

      const notifyZohoFlow = (savedEmployee: Employee, user: UserEntity) => {
        fetch(
          "https://flow.zoho.eu/20065186815/flow/webhook/incoming?zapikey=1001.2d5c53bed20e3e35425a5de665b6d362.8e93fcffd5a9e190f780f865afae19ea&isdebug=false",
          {
            method: "POST",
            headers: {
              "Content-Type": "text/plain",
            },
            mode: "no-cors",
            body: JSON.stringify({
              firstName: savedEmployee?.firstName,
              lastName: savedEmployee?.lastName,
              email: user?.emailAddress,
              trigger: "newKolibriUser",
            }),
          }
        );
      };

      const state = getState();
      const editables = editablesSelector.selectAll(state);
      const editable = editables.find(
        editable =>
          editable.id === originalEmployee.id &&
          editable.type === EditableType.Employee
      );

      if (!editable) {
        throw new Error("No matching editable found");
      }

      let employee = originalEmployee;
      if (!employee?.emailAddresses?.length && !!userEntity?.emailAddress) {
        employee = {
          ...employee,
          emailAddresses: [
            ...(employee.emailAddresses || []),
            { type: EmailAddressType.Work, address: userEntity.emailAddress },
          ],
        };
      }

      if (!!employee.emailAddresses?.length && !!userEntity?.emailAddress) {
        const ref = employee.emailAddresses.find(
          (email: any) => email.address === userEntity.emailAddress
        );
        if (!ref) {
          employee = {
            ...employee,
            emailAddresses: [
              ...(employee.emailAddresses || []),
              { type: EmailAddressType.Work, address: userEntity.emailAddress },
            ],
          };
        }
      }

      if (employee.isNew) {
        // Check if employee exists
        const ref = await employeesClient
          .read(employee.id, employee.realEstateAgencyId)
          .then(response => response?.employee)
          .catch(noop);
        if (!!ref) {
          employee = {
            ...employee,
            isNew: false,
          };
        }
      }

      const savedEmployee = await employeesClient
        .save({ employee }, employee.realEstateAgencyId)
        .then(response => response?.employee);
      if (!savedEmployee) {
        throw new Error("Error saving employee");
      }

      let savedUserEntity: UserEntity | undefined;
      if (!!employee.isNew && !!password) {
        savedUserEntity = await userEntitiesClient
          .usersPOST(savedEmployee.realEstateAgencyId, {
            employeeId: savedEmployee.id,
            password,
            userName: userEntity?.userName || "",
            email: userEntity?.emailAddress || "",
            firstName: savedEmployee.firstName,
            sendNewUserEmail: sendMail,
          })
          .then(response => response.user);
        if (!savedUserEntity) {
          throw new Error("Error saving user entity");
        }
        notifyZohoFlow(savedEmployee, savedUserEntity);

        dispatch(
          editablesActions.updateEditable({
            id: savedEmployee.id,
            changes: { title: savedEmployee?.displayName || "" },
          })
        );
      }

      if (!employee.isNew && !!userEntity?.emailAddress) {
        const user = await userEntitiesClient
          .usersGET(savedEmployee.id, savedEmployee.realEstateAgencyId)
          .then(response => response.user);
        if (!user) return;

        const { emailAddress } = user;
        if (emailAddress !== userEntity.emailAddress) {
          savedUserEntity = await userEntitiesClient
            .updateUserEmail(savedEmployee.realEstateAgencyId, {
              employeeId: savedEmployee.id,
              email: userEntity.emailAddress,
            })
            .then(response => response.user);
          if (!savedUserEntity) {
            throw new Error("Error saving user entity");
          }
        }

        const { phoneNumber } = user;
        if (userEntity.phoneNumber && phoneNumber !== userEntity.phoneNumber) {
          savedUserEntity = await userEntitiesClient
            .updatePhoneNumber(savedEmployee.realEstateAgencyId, {
              employeeId: savedEmployee.id,
              phoneNumber: userEntity.phoneNumber || "",
            })
            .then(response => response.user);
          if (!savedUserEntity) {
            throw new Error("Error saving user entity");
          }
        }
      }

      batch(() => {
        dispatch(
          employeesActions.employees.update({
            id: savedEmployee.id,
            changes: savedEmployee,
          })
        );

        if (!!savedUserEntity?.id) {
          dispatch(
            employeesActions.userEntities.update({
              id: savedUserEntity.id,
              changes: savedUserEntity,
            })
          );
        }
      });

      if (!!close) {
        navigate(editable.rootPath);
      }

      return savedEmployee;
    } catch (error) {
      handleError(error);
    }
  }
);

export type ArchiveEmployeeArgs = EditableEmployeeShape & {};
const archiveEmployee = createThunk(
  "archiveEmployee",
  async ({ http, intl, dispatch, settings }, args: ArchiveEmployeeArgs) => {
    try {
      const employeesClient = new EmployeesClient(settings?.core_api_url, http);
      const { employee } = args;
      if (!employee) return;
      const { id, realEstateAgencyId } = employee;

      await employeesClient.archive({ id }, realEstateAgencyId);

      if (employee) {
        const toast: Toast = {
          id: uuid(),
          message: intl.formatMessage({ id: "toast.employee.archived" }),
          removeAfter: 3000,
        };

        batch(() => {
          dispatch(
            employeesActions.employees.update({
              id: employee.id,
              changes: { isActive: false },
            })
          );
          dispatch(toastsActions.addToast(toast));
        });
      }

      return;
    } catch (error) {
      throw error;
    }
  }
);

export type unArchiveEmployeeArgs = EditableEmployeeShape & {};
const unArchiveEmployee = createThunk(
  "unArchiveEmployee",
  async ({ http, intl, dispatch, settings }, args: unArchiveEmployeeArgs) => {
    try {
      const employeesClient = new EmployeesClient(settings?.core_api_url, http);
      const { employee } = args;

      if (!employee) return;
      const { id, realEstateAgencyId } = employee;

      await employeesClient.unarchive({ id }, realEstateAgencyId);

      if (employee) {
        const toast: Toast = {
          id: uuid(),
          message: intl.formatMessage({ id: "toast.employee.unArchived" }),
          removeAfter: 3000,
        };

        batch(() => {
          dispatch(
            employeesActions.employees.update({
              id: employee.id,
              changes: { isActive: true },
            })
          );
          dispatch(toastsActions.addToast(toast));
        });
      }

      return;
    } catch (error) {
      throw error;
    }
  }
);

export type CheckUserNameArgs = {
  term: string | null | undefined;
  realEstateAgencyId: string | null | undefined;
};
const checkUserName = createThunk(
  "checkUserName",
  async ({ http, settings }, args: CheckUserNameArgs) => {
    try {
      const { term, realEstateAgencyId } = args;
      if (!term || !realEstateAgencyId) {
        return false;
      }

      const client = new HelpdeskSearchClient(settings?.search_api_url, http);
      let request: HelpdeskSearchSearchRequest = {
        filterByTypes: [HelpdeskSearchType.Employees],
        term,
        takePerType: 100,
        filterByActive: ActiveFilter.ActiveOrInactive,
        order: SortOrder.Descending,
        skipPerType: 0,
      };

      const results = await client
        .search(request, realEstateAgencyId)
        .then(response => response.employees?.results || []);

      const match = results.find(employee => employee.userName === term);

      return !match;
    } catch (error) {
      throw error;
    }
  }
);

type DisableTwoFactorArgs = {
  id: string;
  realEstateAgencyId: string;
};
const disableTwoFactor = createThunk(
  "disableTwoFactor",
  async ({ http, intl, dispatch, settings }, args: DisableTwoFactorArgs) => {
    try {
      const client = new UserEntitiesClient(settings?.id_api_url, http);
      await client.disableTwoFactor(args.id, args.realEstateAgencyId);

      const toast: Toast = {
        id: uuid(),
        message: intl.formatMessage({ id: "toast.employee.disableTwoFactor" }),
        removeAfter: 3000,
      };

      dispatch(toastsActions.addToast(toast));
      return;
    } catch (error) {
      throw error;
    }
  }
);

type SendPasswordResetArgs = {
  id: string;
  realEstateAgencyId: string;
};
const sendPasswordReset = createThunk(
  "sendPasswordReset",
  async (
    { http, intl, dispatch, handleError, settings },
    args: SendPasswordResetArgs
  ) => {
    try {
      const client = new UserEntitiesClient(settings?.id_api_url, http);
      await client.sendPasswordReset(args.id, args.realEstateAgencyId);

      const toast: Toast = {
        id: uuid(),
        message: intl.formatMessage({ id: "toast.employee.sendPasswordReset" }),
        removeAfter: 3000,
      };

      dispatch(toastsActions.addToast(toast));
      return;
    } catch (error: any) {
      if (error?.message === "An unexpected server error occurred.") {
        const e = {
          message: intl.formatMessage({
            id: "toast.employee.sendPasswordReset.notVerified",
          }),
        };
        handleError(e);
      } else {
        handleError(error);
      }
    }
  }
);

type ValidateEmailArgs = {
  id: string;
  realEstateAgencyId: string;
};
const validateEmail = createThunk(
  "validateEmail",
  async ({ http, dispatch, settings }, args: ValidateEmailArgs) => {
    try {
      const client = new UserEntitiesClient(settings?.id_api_url, http);
      await client.validateEmail(args.id, args.realEstateAgencyId);
      const userEntity = await client
        .usersGET(args.id, args.realEstateAgencyId)
        .then(response => response.user);

      if (!!userEntity?.id) {
        dispatch(
          employeesActions.userEntities.update({
            id: userEntity.id || "",
            changes: userEntity,
          })
        );
      }

      return;
    } catch (error) {
      throw error;
    }
  }
);

const fetchAllActiveEmployees = createThunk(
  "fetchAllEmployees",
  async ({ http, settings }, realEstateAgencyId: string) => {
    try {
      const client = new RelationsClient(settings?.search_api_url, http);
      const take = 100;

      let employees: RelationSnapShot[] = [];
      let request: SearchRelationRequest = {
        skip: 0,
        take,
        filterByRelationTypes: [RelationType.Employee],
        includeStatistics: false,
        includeBackOfficeEmployees: false,
        includeArchivedEmployees: false,
        orderBy: RelationOrderByField.DisplayName,
        order: SortOrder.Ascending,
        filterByActive: ActiveFilter.ActiveOnly,
      };

      const response = await client.search(request, realEstateAgencyId);
      if (!response) {
        throw new Error("Error retreiving all active employees");
      }

      const { totalResults, results, resultCount } = response;
      employees = results || [];

      if (totalResults > take) {
        const laps = Math.ceil((totalResults - resultCount) / take);
        for (let i = 0; i < laps; i++) {
          request = { ...request, skip: (i + 1) * take };
          const items = await client
            .search(request, realEstateAgencyId)
            .then(response => response.results || []);
          employees = [...employees, ...items];
        }
      }

      return employees;
    } catch (error) {
      throw error;
    }
  }
);

export type ArchiveRealEstateAgencyEmployeeArgs = {
  id: string;
  realEstateAgencyId: string;
};
const archiveRealEstateAgencyEmployee = createThunk(
  "archiveRealEstateAgencyEmployee",
  async ({ http, settings }, args: ArchiveRealEstateAgencyEmployeeArgs) => {
    try {
      const employeesClient = new EmployeesClient(settings?.core_api_url, http);
      const { id, realEstateAgencyId } = args;

      await employeesClient.archive({ id }, realEstateAgencyId);

      return;
    } catch (error) {
      throw error;
    }
  }
);

export type makeAdminArgs = {
  employeeId: string;
  userEntityId: string;
  realEstateAgencyId: string;
};
const makeAdmin = createThunk(
  "makeAdmin",
  async (
    { http, settings, intl, dispatch, handleError },
    args: makeAdminArgs
  ) => {
    try {
      const userEntitiesClient = new UserEntitiesClient(
        settings?.id_api_url,
        http
      );
      const { employeeId, userEntityId, realEstateAgencyId } = args;

      await userEntitiesClient.makeUserAdmin(employeeId, realEstateAgencyId);

      dispatch(
        employeesActions.userEntities.update({
          id: userEntityId,
          changes: { isAdmin: true },
        })
      );

      return;
    } catch (error: any) {
      if (error?.message === "An unexpected server error occurred.") {
        const e = {
          message: intl.formatMessage({
            id: "toast.employee.sendPasswordReset.notVerified",
          }),
        };
        handleError(e);
      } else {
        handleError(error);
      }
    }
  }
);

export type removeAdminArgs = {
  employeeId: string;
  userEntityId: string;
  realEstateAgencyId: string;
};
const removeAdmin = createThunk(
  "removeAdmin",
  async ({ http, settings, dispatch }, args: removeAdminArgs) => {
    try {
      const userEntitiesClient = new UserEntitiesClient(
        settings?.id_api_url,
        http
      );
      const { employeeId, userEntityId, realEstateAgencyId } = args;

      await userEntitiesClient.removeUserAdmin(employeeId, realEstateAgencyId);

      dispatch(
        employeesActions.userEntities.update({
          id: userEntityId,
          changes: { isAdmin: false },
        })
      );

      return;
    } catch (error) {
      throw error;
    }
  }
);

const reset = createThunk(
  "reset",
  async ({ dispatch, getState, handleError }, id: string) => {
    try {
      const state = getState();
      const employee = employeesSelectors.employees.original.selectById(
        state,
        id
      );
      const userEntity = employeesSelectors.userEntities.original
        .selectAll(state)
        .find(entity => entity.personId === id);

      batch(() => {
        if (!!employee) {
          dispatch(
            employeesActions.employees.editable.update({
              id: employee.id,
              changes: employee,
            })
          );
        }

        if (!!userEntity?.id) {
          dispatch(
            employeesActions.userEntities.editable.update({
              id: userEntity.id,
              changes: userEntity,
            })
          );
        }
      });
    } catch (error) {
      handleError(error);
    }
  }
);

const setOnBehalf = createThunk(
  "setOnBehalf",
  async (
    { dispatch, getState, intl, settings, http, fetchNewToken },
    employeeId: string
  ) => {
    try {
      const state = getState();
      const originalPersonId = state.main.account?.id;
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      if (!originalPersonId) {
        throw new Error("Could not retreive original employee GUID");
      }

      const client = new Client(settings?.id_api_url, http);

      await client.setOnBehalf(
        originalPersonId,
        employeeId,
        realEstateAgencyId
      );
      dispatch(mainActions.setAppStatus(RequestStatus.AccountOnBehalfOf));

      await fetchNewToken();
    } catch (error: any) {
      const linkedErrorId = uuid();

      dispatch(
        errorsActions.add({
          id: linkedErrorId,
          name: "setOnBehalfError",
          dateTimeThrown: new Date(),
          message: error?.message,
          status: error?.status,
          response: error?.response,
          headers: error?.headers,
          result: error?.result,
          stack: error?.stack,
        })
      );

      const toast: Toast = {
        id: uuid(),
        message: intl.formatMessage({
          id: "setOnBehalfOf.error",
          defaultMessage: error?.message,
        }),
        canManuallyRemove: true,
        icon: "faExclamationTriangle",
        isError: true,
        errorId: linkedErrorId,
      };
      dispatch(toastsActions.addToast(toast));
    }
  }
);

const resetOnBehalf = createThunk(
  "resetOnBehalf",
  async (
    { handleError, settings, http, fetchNewToken, getState, dispatch },
    employeeId: string
  ) => {
    try {
      const client = new Client(settings?.id_api_url, http);

      await client.resetOnBehalf(employeeId);
      await fetchNewToken();

      const state = getState();
      if (!state.main.account) {
        window.location.reload();
      } else {
        dispatch(mainActions.setAppStatus(RequestStatus.Success));
      }
    } catch (error) {
      handleError(error);
    }
  }
);

const fetchAndSaveEmployee = createThunk(
  "fetchAndSaveEmployee",
  async ({ handleError, settings, http, getState }) => {
    try {
      const state = getState();
      const id = state.main.account?.id;
      const realEstateAgencyId = state.main.account?.realEstateAgencyId;
      const client = new EmployeesClient(settings?.core_api_url, http);

      if (!id || !realEstateAgencyId) {
        throw new Error("Could not find data to fetch employee.");
      }

      const employee = await client
        .read(id, realEstateAgencyId)
        .then(response => response?.employee);

      if (!employee) {
        throw new Error("Could not fetch employee.");
      }

      await client.save({ employee }, realEstateAgencyId);
      return;
    } catch (error) {
      handleError(error);
    }
  }
);

type FetchUserArgs = {
  relationId: string;
  realEstateAgencyId: string;
};
const fetchUser = createThunk(
  "fetchUser",
  async ({ handleError, settings, http, getState }, args: FetchUserArgs) => {
    try {
      const state = getState();
      const { relationId, realEstateAgencyId } = args;
      const client = new UserEntitiesClient(settings?.id_api_url, http);

      return await client
        .usersGET(relationId, realEstateAgencyId)
        .then(response => response.user || null);
    } catch (error) {
      return null;
    }
  }
);

const thunks = {
  fetchEmployee,
  createNewEmployee,
  saveEmployee,
  archiveEmployee,
  unArchiveEmployee,
  checkUserName,
  disableTwoFactor,
  sendPasswordReset,
  validateEmail,
  fetchAllActiveEmployees,
  archiveRealEstateAgencyEmployee,
  makeAdmin,
  removeAdmin,
  reset,
  setOnBehalf,
  resetOnBehalf,
  fetchAndSaveEmployee,
  fetchUser,
};
export default thunks;
