import { RelationSnapShot } from "@kolibri/search-api";
import { HighlightString, Pill } from "@kolibri/ui";
import { useRealEstateAgency } from "hooks/real-estate-agency";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./style.module.scss";

type Props = {
  item: RelationSnapShot;
  query?: string;
  onClick: (item: RelationSnapShot) => void;
};

export const EmployeeItemComponent: FC<Props> = ({ item, query, onClick }) => {
  const realEstateAgency = useRealEstateAgency(item.realEstateAgencyId);

  return (
    <div className={styles.result__item} onClick={() => onClick(item)}>
      {!item.isActive && (
        <div className={styles.pill}>
          <Pill>
            <FormattedMessage id="common.archived" />
          </Pill>
        </div>
      )}

      <div className={styles.label}>
        <HighlightString
          value={item.displayName || ""}
          match={(query || "").trim()}
        />
      </div>
      {!!item.userName && (
        <div className={styles.pill__wrapper}>
          <Pill
            label={item.userName}
            highlightLabel
            query={query}
            className={styles.pill}
          />
        </div>
      )}
      {!!realEstateAgency?.name && (
        <div className={styles.pill__wrapper}>
          <Pill
            label={realEstateAgency.name}
            highlightLabel
            query={query}
            className={styles.pill}
          />
        </div>
      )}
    </div>
  );
};
