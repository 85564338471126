import { AppClient } from "@kolibri/authorization-api";
import { combineReducers } from "redux";
import { RootState } from "store";
import {
  actions as appClientsActions,
  reducer as appClientsReducer,
  selectors as appClientsSelectors,
  thunks as appClientsThunks,
} from "./app-clients";

export const reducer = combineReducers({
  appClients: appClientsReducer,
});

export const actions = {
  appClients: appClientsActions,
};

export const thunks = {
  appClients: appClientsThunks,
};

export const selectors = {
  appClients: appClientsSelectors,
  originalShape: (state: RootState, id: string) => {
    const appClient = appClientsSelectors.original.selectById(state, id);

    return {
      appClient: appClient || null,
    } as EditableAppClientShape;
  },
  editableShape: (state: RootState, id: string) => {
    const appClient = appClientsSelectors.editable.selectById(state, id);

    return {
      appClient: appClient || null,
    } as EditableAppClientShape;
  },
};

export type EditableAppClientShape = {
  appClient: AppClient | null;
};
