import classnames from "classnames";
import scrollbarSize from "dom-helpers/scrollbarSize";
import memoizeOne from "memoize-one";
import React, {
  createElement,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AutoSizer } from "react-virtualized";
import {
  ListColumConfig,
  ListFilterChangeProps,
  ListOrder,
  ListRowProps,
  ListSortChangeProps,
} from ".";
import Actions from "./components/actions";
import Header from "./components/header";
import LoadingState from "./components/loading-state";
import Wrapper from "./components/row-wrapper";
import { useDevErrors } from "./dev-errors";
import styles from "./style.module.scss";
import { useMeasure } from "react-use";

type Props<T = any> = {
  name: string;
  config: ListColumConfig<T>;
  rowCount: number;
  rowHeight: number;
  numberOfFixedColumns?: number;
  noHeader?: boolean;
  showHorizontalDepth?: boolean;
  currentOrder?: ListOrder;
  currentOrderBy?: unknown;
  loadMoreThreshold?: number;
  showLoadingState?: boolean;
  showEmptyState?: boolean;
  emptyState?: JSX.Element;
  actionsLabel?: (index: number) => string | null;
  actions?: (index: number, close: () => void) => JSX.Element | null;
  rowRenderer: (props: ListRowProps) => JSX.Element;
  fixedRowRenderer?: (props: ListRowProps) => JSX.Element;
  onScroll?: (scroll: { top: number; left: number }) => void;
  onSortChange?: (props: ListSortChangeProps) => void;
  extractKey?: (index: number) => any;
  isRowLoaded?: (index: number) => boolean;
  loadMoreRows?: (startIndex: number) => Promise<any>;
  onFilterChange?: (props: ListFilterChangeProps) => void;
  onRowClick?: (index: number) => void;
  onActionClick?: (index: number) => void;
};

const setStateMemoized = memoizeOne(
  (
    startIndex: number,
    stopIndex: number,
    scrolledHorizontal: boolean,
    callback: (state: {
      startIndex: number;
      stopIndex: number;
      scrolledHorizontal: boolean;
    }) => void
  ) => {
    callback({
      startIndex,
      stopIndex,
      scrolledHorizontal,
    });
  }
);

const setScrollingMemoized = memoizeOne(
  (scrolling: boolean, callback: (scrolling: boolean) => void) => {
    callback(scrolling);
  }
);

const ACTION_HEIGHT = 32;
const OVERSCAN_COUNT = 2;

export const ListComponent: FC<Props> = ({
  name,
  config,
  rowCount,
  rowHeight,
  numberOfFixedColumns,
  noHeader,
  showHorizontalDepth,
  currentOrder,
  currentOrderBy,
  loadMoreThreshold,
  showLoadingState,
  showEmptyState,
  emptyState,
  actionsLabel,
  actions,
  rowRenderer,
  fixedRowRenderer,
  onScroll,
  onSortChange,
  extractKey,
  isRowLoaded,
  loadMoreRows,
  onFilterChange,
  onRowClick,
  onActionClick,
}) => {
  useDevErrors(config, numberOfFixedColumns);
  const numberOfVisibleRows = useRef(0);
  const lastScrollTop = useRef(0);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const fixedRef = useRef<HTMLDivElement | null>(null);
  const staticRef = useRef<HTMLDivElement | null>(null);
  const scrollHeight = (rowCount + 1) * rowHeight;
  const [state, setState] = useState({
    startIndex: 0,
    stopIndex: 0,
    scrolledHorizontal: false,
  });
  const loading = useRef(false);
  const [hoverIndex, setHoverIndex] = useState<number | undefined>();
  const [scrolling, setScrolling] = useState(false);
  const scrollTimeout = useRef<any | undefined>();
  const prevRequestedIndex = useRef<number | undefined>();
  const [measureScroll, { height: scrollRefHeight }] = useMeasure();

  const hasActions = useMemo(
    () => (!!onActionClick && !!actionsLabel) || !!actions,
    [actionsLabel, actions, onActionClick]
  );

  const actionsStyles = useMemo(
    () =>
      !hasActions || hoverIndex === undefined || !!scrolling
        ? undefined
        : ({
            top:
              rowHeight * hoverIndex -
              lastScrollTop.current +
              (headerRef.current?.getBoundingClientRect().height || 0) +
              (rowHeight - ACTION_HEIGHT) / 2,
            right: scrollbarSize() + 8,
          } as React.CSSProperties),
    [hoverIndex, rowHeight, scrolling, hasActions]
  );

  const fixedColumnWidth = useMemo(() => {
    if (!!numberOfFixedColumns) {
      const columns = config.slice(0, numberOfFixedColumns);
      return columns.reduce(
        (state, column) => (state += column.width as number),
        0
      );
    }

    return 0;
  }, [numberOfFixedColumns, config]);

  const staticColumnWidth = useMemo(() => {
    const columns = config.slice(numberOfFixedColumns || 0);
    return columns.reduce(
      (state, column) => (state += column.width as number),
      0
    );
  }, [numberOfFixedColumns, config]);

  const columnsWidth = useMemo(
    () => fixedColumnWidth + staticColumnWidth,
    [fixedColumnWidth, staticColumnWidth]
  );

  const onLoadMoreRows = useCallback(
    async (startIndex: number) => {
      if (
        typeof loadMoreRows !== "function" ||
        prevRequestedIndex.current === startIndex
      ) {
        return;
      }
      prevRequestedIndex.current = startIndex;

      loading.current = true;
      await loadMoreRows(startIndex);
      loading.current = false;
    },
    [loadMoreRows]
  );

  const onIsRowLoaded = useCallback(
    (scrollDirection: "up" | "down", startIndex: number) => {
      if (!!loading.current || !isRowLoaded) {
        return;
      }

      const threshold = loadMoreThreshold || 25;
      let from = 0;
      let to = 0;

      if (scrollDirection === "down") {
        from = startIndex;
        to = Math.min(
          Math.max(0, rowCount - threshold) || threshold,
          startIndex + numberOfVisibleRows.current + threshold
        );
      } else {
        from = Math.max(0, startIndex - threshold);
        to = startIndex;
      }

      for (let i = from; i < to; i++) {
        if ((rowCount === 0 || to <= rowCount - 1) && !isRowLoaded(i)) {
          onLoadMoreRows(i);
          break;
        }
      }
    },
    [rowCount, loadMoreThreshold, isRowLoaded, onLoadMoreRows]
  );

  const onScrollHandler = useCallback(() => {
    let top: number = 0,
      left: number = 0,
      scrollDirection: "up" | "down" = "down";

    if (!!scrollRef.current) {
      const { scrollTop, scrollLeft } = scrollRef.current;
      top = scrollTop;
      left = scrollLeft;

      scrollDirection = scrollTop < lastScrollTop.current ? "up" : "down";
      const safeScrollTop = scrollTop <= 0 ? 0 : scrollTop;

      if (lastScrollTop.current !== safeScrollTop) {
        setScrollingMemoized(true, setScrolling);
      }

      lastScrollTop.current = safeScrollTop;
    }

    if (!!headerRef.current) {
      headerRef.current.scrollLeft = left;
    }

    if (!!fixedRef.current) {
      fixedRef.current.scrollTop = top;
    }

    if (!!staticRef.current) {
      staticRef.current.scrollTop = top;
      staticRef.current.scrollLeft = left;
    }

    if (!!onScroll) {
      onScroll({ top, left });
    }

    let startIndex = Math.floor(top / rowHeight);
    onIsRowLoaded(scrollDirection, startIndex);

    let stopIndex = numberOfVisibleRows.current + startIndex + OVERSCAN_COUNT;

    startIndex =
      startIndex >= OVERSCAN_COUNT ? startIndex - OVERSCAN_COUNT : startIndex;
    stopIndex = stopIndex > rowCount ? rowCount : stopIndex;

    setStateMemoized(
      startIndex,
      stopIndex,
      !showHorizontalDepth ? false : left > 0,
      setState
    );

    if (!!scrollTimeout.current) {
      clearTimeout(scrollTimeout.current);
    }
    scrollTimeout.current = setTimeout(() => {
      setScrollingMemoized(false, setScrolling);
    }, 150);
  }, [
    rowHeight,
    rowCount,
    showHorizontalDepth,
    onScroll,
    onIsRowLoaded,
    setScrolling,
  ]);

  const calculateStyles = useCallback(
    (key: number) =>
      ({
        position: "absolute",
        height: rowHeight,
        width: "100%",
        top: key * rowHeight,
        left: 0,
      } as React.CSSProperties),
    [rowHeight]
  );

  const getColumnConfig = useCallback(
    (id: string) => config.find(column => column.id === id),
    [config]
  );

  const rows = useMemo(() => {
    const { startIndex, stopIndex } = state;
    const rows: any[] = [];
    for (let index = startIndex; index < stopIndex; index++) {
      const key = !extractKey ? index : extractKey(index);

      rows.push(
        createElement(Wrapper, {
          rowRenderer,
          index,
          key,
          style: calculateStyles(index),
          getColumnConfig,
          hover: hoverIndex === index,
          setHoverIndex,
          onRowClick,
        })
      );
    }

    return rows;
  }, [
    state,
    hoverIndex,
    rowRenderer,
    calculateStyles,
    getColumnConfig,
    extractKey,
    onRowClick,
  ]);

  const fixedRows = useMemo(() => {
    if (!fixedRowRenderer) return null;
    const { startIndex, stopIndex } = state;
    const rows: any[] = [];
    for (let index = startIndex; index < stopIndex; index++) {
      const key = !extractKey ? index : extractKey(index);

      rows.push(
        createElement(Wrapper, {
          fixedRowRenderer,
          index,
          key,
          style: calculateStyles(index),
          getColumnConfig,
          hover: hoverIndex === index,
          setHoverIndex,
          onRowClick,
        })
      );
    }

    return rows;
  }, [
    state,
    hoverIndex,
    extractKey,
    fixedRowRenderer,
    calculateStyles,
    getColumnConfig,
    onRowClick,
  ]);

  useEffect(() => {
    numberOfVisibleRows.current = Math.ceil(scrollRefHeight / rowHeight);

    if (!!scrollRef.current) {
      onScrollHandler();
    }
  }, [rowHeight, scrollRefHeight, onScrollHandler]);

  useEffect(() => {
    const instance = scrollRef.current;

    instance?.addEventListener("scroll", onScrollHandler);
    return () => {
      instance?.removeEventListener("scroll", onScrollHandler);
    };
  }, [onScrollHandler]);

  const scrollRefCallback = useCallback(
    (node: HTMLDivElement) => {
      scrollRef.current = node;
      measureScroll(node);
    },
    [measureScroll]
  );

  return (
    <AutoSizer>
      {({ height, width }) => (
        <div
          className={styles.rootContainer}
          style={{
            width,
            height,
          }}
          onMouseLeave={() => setHoverIndex(undefined)}
        >
          <Actions
            index={hoverIndex}
            actions={actions}
            actionsLabel={actionsLabel}
            height={ACTION_HEIGHT}
            style={actionsStyles}
            onActionClick={onActionClick}
          />
          {!noHeader && (
            <Header
              listName={name}
              config={config}
              numberOfFixedColumns={numberOfFixedColumns}
              scrolledHorizontal={state.scrolledHorizontal}
              currentOrder={currentOrder}
              currentOrderBy={currentOrderBy}
              ref={headerRef}
              onSortChange={onSortChange}
              onFilterChange={onFilterChange}
            />
          )}
          <div className={styles.container}>
            <AutoSizer>
              {({ height: innerHeight, width: innerWidth }) => (
                <>
                  <div
                    className={styles.scrollContainer}
                    ref={scrollRefCallback}
                    style={{ height: innerHeight, width: innerWidth }}
                  >
                    {!!showLoadingState && (
                      <div className={styles.statusView}>
                        <LoadingState />
                      </div>
                    )}

                    {!showLoadingState && !!showEmptyState && !!emptyState && (
                      <div className={styles.statusView}>{emptyState}</div>
                    )}

                    <div
                      className={styles.scrollArea}
                      style={{
                        height: scrollHeight,
                        width: columnsWidth,
                      }}
                    />

                    {!!fixedRows && (
                      <div
                        className={classnames(styles.fixedContainer, {
                          [styles.scrolledHorizontal]: state.scrolledHorizontal,
                        })}
                        style={{
                          width: fixedColumnWidth,
                          height:
                            innerHeight -
                            (innerWidth < columnsWidth ? scrollbarSize() : 0),
                        }}
                        ref={fixedRef}
                      >
                        {createElement("div", {
                          children: fixedRows,
                          className: styles.fixedContainer__innerElement,
                          style: {
                            width: fixedColumnWidth,
                            height: scrollHeight,
                          },
                        })}
                      </div>
                    )}

                    <div
                      className={styles.staticContainer}
                      style={{
                        height:
                          innerHeight -
                          (innerWidth < columnsWidth ? scrollbarSize() : 0),
                        left: fixedColumnWidth,
                      }}
                      ref={staticRef}
                    >
                      {createElement("div", {
                        children: rows,
                        className: styles.staticContainer__innerElement,
                        style: {
                          width: "100%",
                          minWidth: staticColumnWidth,
                          height: scrollHeight,
                        },
                      })}
                    </div>
                  </div>
                </>
              )}
            </AutoSizer>
          </div>
        </div>
      )}
    </AutoSizer>
  );
};
