import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import Bootstrap from "./bootstrap";
import "whatwg-fetch";
import "@formatjs/intl-listformat/polyfill";
import "@formatjs/intl-listformat/locale-data/en";
import "@formatjs/intl-listformat/locale-data/nl";
import moment from "moment";
import "moment/locale/nl";

moment.locale("nl");

ReactDOM.render(
  <React.StrictMode>
    <Bootstrap />
  </React.StrictMode>,
  document.getElementById("root")
);
