import React, { FC } from "react";
import styles from "./style.module.scss";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";

type Props = {
  title?: string | null;
  subTitle?: string | null;
  centerTitle?: string | null;
  onBackButtonClick?: () => void;
};

export const PageHeaderComponent: FC<Props> = ({
  title,
  subTitle,
  centerTitle,
  children,
  onBackButtonClick,
}) => {
  return (
    <div className={styles.pageHeader}>
      {typeof onBackButtonClick === "function" && (
        <div className={styles.backButton} onClick={onBackButtonClick}>
          <FontAwesomeIcon icon={faChevronLeft as any} />
        </div>
      )}
      {(!!title || !!subTitle) && (
        <div className={classnames(styles.section, styles.titles)}>
          {!!title && <div className={styles.title}>{title}</div>}
          {!!subTitle && <div className={styles.subTitle}>{subTitle}</div>}
        </div>
      )}
      {!centerTitle ? (
        <div className={styles.spacer} />
      ) : (
        <div className={classnames(styles.section, styles.centerTitle)}>
          {centerTitle}
        </div>
      )}
      {!!children && <div className={styles.section}>{children}</div>}
    </div>
  );
};
