import { ObjectAssignment, PublicationSnapShot } from "@kolibri/core-api";
import { CadastreSnapShot } from "@kolibri/search-api";
import { combineReducers } from "redux";
import {
  reducer as assignmentsReducer,
  actions as assignmentsActions,
  selectors as assignmentsSelectors,
  thunks as assignmentsThunks,
} from "./assignments";
import {
  reducer as publicationsReducer,
  actions as publicationsActions,
  selectors as publicationsSelectors,
  thunks as publicationsThunks,
} from "./publications";
import {
  reducer as cadastresReducer,
  actions as cadastresActions,
  selectors as cadastresSelectors,
  thunks as cadastresThunks,
} from "./cadastres";
import cacheThunks from "./thunks";
import { RootState } from "store";

export const reducer = combineReducers({
  assignments: assignmentsReducer,
  publications: publicationsReducer,
  cadastres: cadastresReducer,
});

export const actions = {
  assignments: assignmentsActions,
  publications: publicationsActions,
  cadastres: cadastresActions,
};

export const thunks = {
  assignments: assignmentsThunks,
  publications: publicationsThunks,
  cadastres: cadastresThunks,
  cache: cacheThunks,
};

export const selectors = {
  assignments: assignmentsSelectors,
  publications: publicationsSelectors,
  cadastres: cadastresSelectors,
  shape: (state: RootState, id: string) => {
    const assignment = assignmentsSelectors.selectById(state, id);
    const publications = publicationsSelectors
      .selectAll(state)
      .filter(publication => publication.linkedAssignment?.id === id);
    const cadastres = cadastresSelectors
      .selectAll(state)
      .filter(cadastre => cadastre.linkedAssignment?.id === id);

    return {
      assignment: assignment || null,
      publications: publications || null,
      cadastres: cadastres || null,
    } as EditableObjectShape;
  },
};

export type EditableObjectShape = {
  assignment: ObjectAssignment | null;
  publications: PublicationSnapShot[] | null;
  cadastres: CadastreSnapShot[] | null;
};
