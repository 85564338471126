import React, { FC } from "react";
import styles from "./style.module.scss";
import classnames from "classnames";

type Props = {
  zebra?: boolean;
  hover: boolean;
};

export const ListRowComponent: FC<Props> = ({ children, zebra, hover }) => {
  return (
    <div
      className={classnames(styles.row, {
        [styles.zebra]: zebra,
        [styles.hover]: hover,
      })}
    >
      {children}
    </div>
  );
};
