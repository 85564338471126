import { createThunk } from "helpers/store";
import {
  ActiveFilter,
  RealEstateAgencyClient,
  RealEstateAgencyOrderByField,
  RealEstateAgencySnapShot,
  RealEstateAgencyTermField,
  SearchRealEstateAgencyRequest,
  SortOrder,
} from "@kolibri/search-api";
import { slice } from "./slice";

const fetchAllRealEstateAgencies = createThunk(
  "fetchAllRealEstateAgencies",
  async ({ http, dispatch, handleError, settings }) => {
    try {
      const client = new RealEstateAgencyClient(settings?.search_api_url, http);
      const take = 100;

      let realEstateAgencies: RealEstateAgencySnapShot[];
      let request: SearchRealEstateAgencyRequest = {
        orderBy: RealEstateAgencyOrderByField.Name,
        filterByActive: ActiveFilter.ActiveOrInactive,
        order: SortOrder.Ascending,
        skip: 0,
        take,
      };

      const response = await client.search(request);
      if (!response) return;

      const { totalResults, resultCount, results } = response;
      realEstateAgencies = results || [];

      if (totalResults > take) {
        const laps = Math.ceil((totalResults - resultCount) / take);
        for (let i = 0; i < laps; i++) {
          request = { ...request, skip: (i + 1) * take };
          const items = await client
            .search(request)
            .then(response => response.results || []);
          realEstateAgencies = [...realEstateAgencies, ...items];
        }
      }

      dispatch(slice.actions.setAll(realEstateAgencies));
    } catch (error) {
      handleError(error);
    }
  }
);

type SearchRealEstateAgenciesArgs = { term: string };
const searchRealEstateAgencies = createThunk(
  "searchRealEstateAgencies",
  async ({ http, handleError, settings }, args: SearchRealEstateAgenciesArgs) => {
    try {
      const client = new RealEstateAgencyClient(settings?.search_api_url, http);
      const { term } = args;

      return client
        .search({
          orderBy: RealEstateAgencyOrderByField.Name,
          filterByActive: ActiveFilter.ActiveOrInactive,
          order: SortOrder.Ascending,
          skip: 0,
          take: 5,
          term,
          termFields: [RealEstateAgencyTermField.Name],
        })
        .then(response => response.results || []);
    } catch (error) {
      handleError(error);
    }
  }
);

const thunks = { fetchAllRealEstateAgencies, searchRealEstateAgencies };
export default thunks;
