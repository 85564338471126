import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { ErrorShape } from ".";

export const adapter = createEntityAdapter<ErrorShape>();

export const slice = createSlice({
  name: "errors",
  initialState: adapter.getInitialState(),
  reducers: {
    add: adapter.addOne,
    clear: adapter.removeAll,
  },
});
