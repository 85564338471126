import { useSelector } from "hooks/selector";
import React, { FC, useMemo, useRef, useState } from "react";
import styles from "./style.module.scss";
import head from "lodash-es/head";
import { Avatar, Context, MenuItem } from "@kolibri/ui";
import { useTranslation } from "hooks/translations";
import { useRealEstateAgency } from "hooks/real-estate-agency";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faSignOut,
} from "@fortawesome/pro-regular-svg-icons";
import { httpWebUtil } from "store";

export const AvatarComponent: FC = () => {
  const account = useSelector(state => state.main.account);
  const t = useTranslation();
  const realEstateAgency = useRealEstateAgency(account?.realEstateAgencyId);
  const iconRef = useRef<HTMLDivElement | null>(null);
  const [contextVisible, setContextVisible] = useState(false);

  const translations = useMemo(() => {
    return {
      actionLogout: t("common.logout"),
    };
  }, [t]);

  const avatar = useMemo(() => {
    if (!!account?.passportPhotoBlob) {
      const { urlMedium, urlPreview, urlBig } = account.passportPhotoBlob;
      const image = head([urlMedium, urlPreview, urlBig].filter(d => !!d));

      return <Avatar image={image} size={36} shape="rounded" />;
    }

    if (!!account?.letterAvatar) {
      return <Avatar text={account.letterAvatar} size={36} shape="rounded" />;
    }

    return <Avatar size={36} shape="rounded" />;
  }, [account?.letterAvatar, account?.passportPhotoBlob]);

  const displayName = useMemo(() => {
    if (!!account?.displayName) {
      return account.displayName;
    }

    return (
      [account?.firstName, account?.middleName, account?.lastName]
        .filter(d => !!d)
        .join(" ") || t("common.unknown")
    );
  }, [
    account?.displayName,
    account?.firstName,
    account?.middleName,
    account?.lastName,
    t,
  ]);

  const toggleContextVisible = () => {
    setContextVisible(!contextVisible);
  };

  const onLogoutCallback = async () => {
    if (!!httpWebUtil) {
      await httpWebUtil.signout();
    }
  };

  return (
    <div className={styles.avatar}>
      {avatar}
      <div className={styles.trigger} onClick={toggleContextVisible}>
        <div className={styles.names}>
          <div className={styles.name}>{displayName}</div>
          <div className={styles.realEstateAgency}>
            {realEstateAgency?.name}
          </div>
        </div>
        <div className={styles.icon} ref={iconRef}>
          <FontAwesomeIcon
            icon={(contextVisible ? faChevronUp : faChevronDown) as any}
            style={{ fontSize: 14, color: "white" }}
          />
        </div>
      </div>

      <Context
        visible={contextVisible}
        onClose={() => setContextVisible(false)}
        parent={iconRef}
        attachOn="center"
        expandFrom="topright"
        vOffset={10}
      >
        <div className={styles.context}>
          <MenuItem
            label={translations.actionLogout}
            icon={
              <FontAwesomeIcon
                icon={faSignOut as any}
                className={styles.actionItemIcon}
              />
            }
            className={styles.actionItem}
            onClick={onLogoutCallback}
          />
        </div>
      </Context>
    </div>
  );
};
