import { Form, Formik } from "formik";
import React, { FC, useCallback, useMemo } from "react";
import Button from "../button";
import TextField from "../field/components/text";
import FormRow from "../form-row";
import Modal from "../modal";
import styles from "./style.module.scss";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { useIntl } from "react-intl";

type Props = {
  visible: boolean;
  loading?: boolean;
  inputConfirmation?: string;
  title?: string;
  body: string;
  confirm?: string;
  reject?: string;
  onReject: () => void;
  onConfirm: () => void;
};

export const ConfirmComponent: FC<Props> = ({
  visible,
  loading,
  inputConfirmation,
  title,
  body,
  confirm,
  reject,
  onConfirm,
  onReject,
}) => {
  const onSubmitCallback = useCallback(() => {}, []);
  const intl = useIntl();

  const translations = useMemo(() => {
    const confirm = inputConfirmation || "";

    return {
      confirm: intl.formatMessage({
        id: "kolibri.ui.common.confirm.confirm",
        defaultMessage: "Yes",
      }),
      reject: intl.formatMessage({
        id: "kolibri.ui.common.confirm.reject",
        defaultMessage: "No",
      }),
      requiredError: intl.formatMessage({
        id: "kolibri.ui.common.confirm.requiredError",
        defaultMessage: "Field is required",
      }),
      confirmError: intl.formatMessage(
        {
          id: "kolibri.ui.common.confirm.confirmError",
          defaultMessage: `Field doesn't match "${confirm}"`,
        },
        { confirm }
      ),
    };
  }, [intl, inputConfirmation]);

  const schema = useMemo(() => {
    return Yup.object().shape({
      confirm: Yup.string()
        .required(translations.requiredError)
        .matches(
          new RegExp(inputConfirmation || "", "g"),
          translations.confirmError
        ),
    });
  }, [translations, inputConfirmation]);

  return !visible ? null : (
    <Formik
      initialValues={{ confirm: "" }}
      onSubmit={onSubmitCallback}
      validationSchema={schema}
    >
      {({ touched, isValid }) => (
        <Modal
          title={title}
          visible
          size="small"
          noCloseButton
          onClose={onReject}
          blockCloseEvent={!!loading}
          actions={() => (
            <>
              <Button
                label={reject || translations.reject}
                disabled={loading}
                onClick={onReject}
              />
              <Button
                theme="primary"
                disabled={
                  loading ||
                  (!!inputConfirmation &&
                    (!isValid || Object.keys(touched).length === 0))
                }
                onClick={onConfirm}
                style={{ marginLeft: 6 }}
              >
                {!!loading && (
                  <FontAwesomeIcon
                    icon={faSpinner as any}
                    className={styles.actionsTrigger}
                    spin
                  />
                )}
                <div className={styles.actionsLabel}>
                  {confirm || translations.confirm}
                </div>
              </Button>
            </>
          )}
        >
          <div className={styles.body}>
            {!!body && (
              <div
                className={styles.body__text}
                dangerouslySetInnerHTML={{ __html: body }}
              />
            )}
            {!!inputConfirmation && (
              <div>
                <Form
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck={false}
                >
                  <FormRow>
                    <TextField
                      name="confirm"
                      style={{ width: "100%" }}
                      disabled={loading}
                    />
                  </FormRow>
                </Form>
              </div>
            )}
          </div>
        </Modal>
      )}
    </Formik>
  );
};
