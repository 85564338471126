import classnames from "classnames";
import scrollbarSize from "dom-helpers/scrollbarSize";
import React, { FC, useMemo } from "react";
import { ListColumnProps, ListFilterChangeProps } from "../..";
import Array from "./components/filter-array";
import Text from "./components/filter-text";
import styles from "./style.module.scss";

type Props = {
  listName: string;
  column: ListColumnProps;
  last?: boolean;
  fixed?: boolean;
  onChange?: (props: ListFilterChangeProps) => void;
};

export const FilterComponent: FC<Props> = ({
  listName,
  column,
  last,
  fixed,
  onChange,
}) => {
  const extraWidth = last && !fixed ? scrollbarSize() : 0;
  const { width } = column;

  const view = useMemo(() => {
    switch (column.filterOptions?.type) {
      case "array": {
        return (
          <Array
            listName={listName}
            config={column.filterOptions}
            onChange={value => onChange?.({ column, value })}
          />
        );
      }
      case "date": {
        return <div>date</div>;
      }
      case "number": {
        return <div>number</div>;
      }
      case "string": {
        return (
          <Text
            listName={listName}
            config={column.filterOptions}
            onChange={value => onChange?.({ column, value })}
          />
        );
      }
      default: {
        return null;
      }
    }
  }, [listName, column, onChange]);

  return (
    <>
      <div
        className={classnames(styles.filter, {
          [styles.last]: last,
        })}
        style={{ width, marginRight: extraWidth }}
      >
        <div className={styles.label}>{view}</div>
      </div>
    </>
  );
};
