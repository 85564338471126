import {
  faChevronDown,
  faChevronUp,
  faSpinner,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { useField } from "formik";
import debounce from "lodash-es/debounce";
import findIndex from "lodash-es/findIndex";
import isArray from "lodash-es/isArray";
import isString from "lodash-es/isString";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FIELD_RESET } from "../../../../constants/config";
import Context from "../../../context";
import fieldStyles from "../../style.module.scss";
import styles from "./style.module.scss";

type ChildArguments = {
  item?: any | null;
  value?: any | null;
  query: string;
  active: boolean;
};

type Props = {
  style?: React.CSSProperties;
  className?: string;
  name: string;
  values: any[] | (() => Promise<any[]>);
  matchOn?: string | { in?: string; out?: string };
  placeholder?: string;
  disabled?: boolean;
  emptyStateLabel?: string;
  queryPlaceholder?: string;
  label?: string;
  labelPlacement?: "top" | "right" | "left";
  labelClassName?: string;
  allowEmptyQuery?: boolean;
  children?: (args: ChildArguments) => React.ReactNode;
  labelFn?: (value?: any | null) => React.ReactNode;
  query?: (query: string) => Promise<any[]>;
  mapValue?: (value?: any | null) => any;
};

export const SelectComponent: FC<Props> = ({
  style,
  className,
  values,
  name,
  matchOn,
  disabled,
  placeholder,
  children,
  emptyStateLabel,
  queryPlaceholder,
  labelPlacement,
  labelClassName,
  label,
  allowEmptyQuery,
  labelFn,
  query,
  mapValue,
}) => {
  const [{ onBlur }, meta, { setValue }] = useField(name);
  const ref = useRef<HTMLButtonElement | null>(null);
  const [contextVisible, setContextVisible] = useState(false);
  const [innerValues, setInnerValues] = useState<any[]>([]);
  const initiallyRequested = useRef(false);
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [focussed, setFocussed] = useState(false);
  const [innerQuery, setInnerQuery] = useState("");

  style = style || { width: undefined };
  const { width, ...inputStyle } = style;

  const closeContextAndFocus = useCallback(() => {
    setContextVisible(false);
    setInnerQuery("");
    ref.current?.focus();
  }, [setContextVisible, setInnerQuery]);

  const updateValue = useCallback(
    (value: any) => {
      let mappedValue = !mapValue ? value : mapValue(value);
      setValue(mappedValue);
      closeContextAndFocus();
    },
    [mapValue, setValue, closeContextAndFocus]
  );

  const onFocusCallback = () => {
    setFocussed(true);
  };

  const onBlurCallback = (event: React.FocusEvent<any>) => {
    setFocussed(false);
    onBlur(event);
  };

  const queryInputRefCallback = (element: HTMLInputElement | null) => {
    setTimeout(() => element?.focus(), 0);
  };

  const onQueryClearCallback = () => {
    if (!!innerQuery) {
      setInnerQuery("");
      setValue("");
      return;
    } else {
      closeContextAndFocus();
    }
  };

  const getQueryResults = useMemo(
    () =>
      debounce(
        async (value: string) => {
          try {
            if (!query || loading || (value.length < 2 && !allowEmptyQuery)) {
              return;
            }
            setLoading(true);
            const values = await query(value);
            setInnerValues(values || []);
            setActiveIndex(0);
          } finally {
            setLoading(false);
          }
        },
        250,
        { leading: true }
      ),
    [
      loading,
      allowEmptyQuery,
      setLoading,
      setInnerValues,
      setActiveIndex,
      query,
    ]
  );

  const onQueryChangeCallback = (event: ChangeEvent<HTMLInputElement>) => {
    if (!query) return;
    const innerQuery = event.target.value || "";
    setInnerQuery(innerQuery);
    getQueryResults(innerQuery);
  };

  const selectedValue = useMemo(() => {
    return innerValues.find(value => {
      return !matchOn
        ? value === meta.value
        : isString(matchOn)
        ? value?.[matchOn] === meta.value?.[matchOn]
        : (!matchOn.in ? value : value?.[matchOn.in]) ===
          (!matchOn.out ? meta.value : meta.value?.[matchOn.out]);
    });
  }, [meta.value, innerValues, matchOn]);

  const selectedIndex = useMemo(() => {
    return findIndex(innerValues, value => value === selectedValue);
  }, [innerValues, selectedValue]);

  const viewLabel = useMemo(() => {
    if (!selectedValue && (!meta.value || !!loading) && placeholder) {
      return (
        <div className={classnames(styles.placeholder, styles.singleLine)}>
          {placeholder}
        </div>
      );
    }

    const value = selectedValue || meta.value;

    return !labelFn ? (
      <div className={styles.singleLine}>{value}</div>
    ) : (
      labelFn(value)
    );
  }, [labelFn, selectedValue, meta.value, loading, placeholder]);

  const toggleContextVisibility = () => {
    setContextVisible(!contextVisible);
    setActiveIndex(selectedIndex || 0);
  };

  const fetchInitialValues = useCallback(
    async (initialQuery: () => Promise<any[]>) => {
      if (!!initiallyRequested.current || loading) return;
      setLoading(true);
      initiallyRequested.current = true;
      const response = await initialQuery();
      setInnerValues(response);
      setLoading(false);
    },
    [setInnerValues, loading, setLoading]
  );

  const handleKeyDownEvents = useCallback(
    (event: KeyboardEvent) => {
      if (!contextVisible) {
        if (focussed) {
          switch (event.key) {
            case "ArrowDown":
            case "ArrowUp": {
              event.preventDefault();
              event.stopPropagation();
              setContextVisible(true);
              break;
            }
            default: {
              break;
            }
          }
        }

        return;
      }

      const totalCount = innerValues.length;

      switch (event.key) {
        case "Tab": {
          event.preventDefault();
          event.stopPropagation();
          break;
        }
        case "Escape": {
          event.preventDefault();
          event.stopPropagation();

          if (!!innerQuery) {
            setInnerQuery("");
            setInnerValues([]);
            break;
          }

          setContextVisible(false);
          ref.current?.focus();
          break;
        }
        case "ArrowDown": {
          event.preventDefault();
          event.stopPropagation();
          setActiveIndex(activeIndex => (activeIndex + 1) % totalCount);
          break;
        }
        case "ArrowUp": {
          event.preventDefault();
          event.stopPropagation();
          setActiveIndex(
            activeIndex => (activeIndex - 1 + totalCount) % totalCount
          );
          break;
        }
        case "Enter": {
          event.preventDefault();
          event.stopPropagation();
          updateValue(innerValues[activeIndex]);
          break;
        }
        default: {
          break;
        }
      }
    },
    [
      contextVisible,
      activeIndex,
      innerValues,
      focussed,
      innerQuery,
      setInnerValues,
      setActiveIndex,
      setContextVisible,
      setInnerQuery,
      updateValue,
    ]
  );

  useEffect(() => {
    if (!!query && !allowEmptyQuery) {
      return;
    }
    if (!innerQuery && isArray(values) && values.length !== 0) {
      setInnerValues(values as any[]);
      return;
    }
    if (!innerQuery && typeof values === "function") {
      fetchInitialValues(values);
    }
  }, [
    values,
    query,
    allowEmptyQuery,
    innerQuery,
    setInnerValues,
    fetchInitialValues,
  ]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDownEvents, true);

    return () => {
      window.removeEventListener("keydown", handleKeyDownEvents, true);
    };
  }, [handleKeyDownEvents]);

  return (
    <div className={fieldStyles.wrapper} style={{ width }}>
      {!!label && (
        <label
          className={classnames(
            styles.label,
            styles[labelPlacement || "top"],
            labelClassName
          )}
        >
          {label}
        </label>
      )}
      <button
        name={name}
        type="button"
        ref={ref}
        className={classnames(fieldStyles.field, styles.select, className)}
        style={inputStyle}
        disabled={disabled || loading}
        onClick={toggleContextVisibility}
        onBlur={onBlurCallback}
        onFocus={onFocusCallback}
        data-lpignore={true}
      >
        <div className={styles.viewlabel}>{viewLabel}</div>
        <div className={styles.trigger}>
          <FontAwesomeIcon
            icon={
              (loading
                ? faSpinner
                : contextVisible
                ? faChevronUp
                : faChevronDown) as any
            }
            spin={loading}
          />
        </div>
      </button>

      {meta.touched && meta.error && (
        <div className={fieldStyles.error}>{meta.error}</div>
      )}

      <Context
        parent={ref}
        visible={contextVisible}
        onClose={closeContextAndFocus}
        minWidth="parent"
        attachOn="topleft"
      >
        {({ maxHeight }) => (
          <div className={styles.context} style={{ maxHeight }}>
            {!!query && (
              <div className={styles.query}>
                <input
                  type="text"
                  onChange={onQueryChangeCallback}
                  value={innerQuery}
                  ref={queryInputRefCallback}
                  placeholder={queryPlaceholder}
                  {...FIELD_RESET}
                />
                {!loading ? (
                  <div
                    className={classnames(styles.trigger, styles.clearQuery)}
                    onClick={onQueryClearCallback}
                  >
                    <FontAwesomeIcon icon={faTimes as any} />
                  </div>
                ) : (
                  <div className={styles.trigger}>
                    <FontAwesomeIcon icon={faSpinner as any} spin />
                  </div>
                )}
              </div>
            )}

            {!!innerValues.length && (
              <div className={styles.list}>
                {innerValues.map((item, idx) => (
                  <div
                    key={idx}
                    className={classnames(styles.item, {
                      [styles.hover]: idx === activeIndex,
                      [styles.active]: idx === selectedIndex,
                    })}
                    onClick={() => updateValue(item)}
                  >
                    {!children ? (
                      <div className={styles.singleLine}>{item}</div>
                    ) : (
                      children({
                        item,
                        value: meta.value,
                        query: "",
                        active: idx === selectedIndex,
                      })
                    )}
                  </div>
                ))}
              </div>
            )}

            {!innerValues.length &&
              !loading &&
              (!query || innerQuery.length >= 2) && (
                <div className={styles.emptyState}>
                  {emptyStateLabel || "No results found"}
                </div>
              )}
          </div>
        )}
      </Context>
    </div>
  );
};
