import { Realtor } from "@kolibri/connect-proxy-api";
import {
  Address,
  AssignmentType,
  CompanySettings,
  EmailAddressType,
  EmployeesClient,
  GeoClient,
  Language,
  Office,
  PhoneNumberType,
  RealEstateAgenciesClient,
  RealEstateAgency,
  RealEstateGroup,
} from "@kolibri/core-api";
import { RealEstateAgency as MlsRealEstateAgency } from "@kolibri/mls-api";
import { unwrapResult } from "@reduxjs/toolkit";
import { EditableType } from "enums/editable-types";
import { createThunk } from "helpers/store";
import random from "lodash-es/random";
import { batch } from "react-redux";
import { editablesActions } from "store/editables";
import { v4 as uuid } from "uuid";
import {
  actions as realEstateAgenciesActions,
  EditableRealEstateAgencyShape,
  selectors as realEstateAgenciesSelectors,
  thunks as mlsRealEstateAgenciesThunks,
} from "../";
import { thunks as employeesThunks } from "store/employees";

const fetchRealEstateAgency = createThunk(
  "fetchRealEstateAgency",
  async (
    { http, dispatch, handleError, settings },
    id: string
  ): Promise<RealEstateAgency | undefined> => {
    try {
      const client = new RealEstateAgenciesClient(settings?.core_api_url, http);

      const realEstateAgency = await client
        .read(id)
        .then(response => response?.realEstateAgency);
      if (!realEstateAgency) {
        throw Error("Couldn't find realestate agency");
      }

      await dispatch(
        realEstateAgenciesActions.realEstateAgencies.add(realEstateAgency)
      );

      await dispatch(
        mlsRealEstateAgenciesThunks.realtors.fetchAndAddRealtor({
          realEstateAgencyId: realEstateAgency.id,
        })
      )
        .then(unwrapResult)
        .catch(error => {
          throw error;
        });

      await dispatch(
        mlsRealEstateAgenciesThunks.companySettings.fetchCompanySettings(
          realEstateAgency.id
        )
      )
        .then(unwrapResult)
        .catch(error => {
          throw error;
        });

      await dispatch(
        mlsRealEstateAgenciesThunks.mainOffices.fetchMainOffice(
          realEstateAgency.id
        )
      )
        .then(unwrapResult)
        .catch(error => {
          throw error;
        });

      await dispatch(
        mlsRealEstateAgenciesThunks.mlsRealEstateAgencies.fetchRealEstateAgency(
          realEstateAgency.id
        )
      )
        .then(unwrapResult)
        .catch(error => {
          throw error;
        });

      return realEstateAgency;
    } catch (error) {
      handleError(error);
    }
  }
);

type CreateNewRealEstateAgencyArgs = {
  name?: string | null;
  queryParams?: URLSearchParams;
  navigate: (path: string) => void;
  history?: any;
};
const createNewRealEstateAgency = createThunk(
  "createNewRealEstateAgency",
  async (
    { http, dispatch, getState, handleError, settings },
    args: CreateNewRealEstateAgencyArgs
  ) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const culture = state.settings.accountSettings?.defaultCulture || "nl-NL";
      const geoClient = new GeoClient(settings?.core_api_url, http);
      const realEstateAssociations =
        state.main.mastertable.core?.realEstateAssociations || [];
      const countries = state.main.mastertable.core?.countries || [];

      const client = new RealEstateAgenciesClient(settings?.core_api_url, http);

      let address: Address | undefined;
      const country = args.queryParams?.get("country");
      const street = args.queryParams?.get("street");
      const houseNumber = args.queryParams?.get("houseNumber");
      const houseNumberPostfix = args.queryParams?.get("houseNumberPostfix");
      const postalCode = args.queryParams?.get("postalCode");
      const locality = args.queryParams?.get("locality");
      const email = args.queryParams?.get("email");
      const phoneNumber = args.queryParams?.get("phoneNumber");
      const webAddress = args.queryParams?.get("website");
      const organisation = args.queryParams?.get("organisation");
      const membershipNumber = args.queryParams?.get("organisationId");
      const groupId = args.queryParams?.get("groupId");

      const realEstateAssociation = realEstateAssociations.find(
        option =>
          option.displayName?.toLowerCase() === organisation?.toLowerCase()
      )?.value;
      const countryIso2 =
        countries.find(
          option => option.displayName?.toLowerCase() === country?.toLowerCase()
        )?.iso2CodeValue || "NL";

      const location = [
        street,
        houseNumber,
        houseNumberPostfix,
        postalCode,
        locality,
      ]
        .filter(v => !!v)
        .join(" ");

      if (!!location) {
        address = await geoClient
          .addressSearch(
            {
              countryIso2,
              culture,
              location,
            },
            realEstateAgencyId
          )
          .then(response => response?.results || undefined);
      }

      const response = await client
        .defineNew({}, realEstateAgencyId)
        .then(response => response?.realEstateAgency);

      if (!response) {
        throw Error("Couldn't create realestate agency");
      }

      const realEstateAgency: RealEstateAgency = {
        ...response,
        name: args.name || "",
        associationInfo: {
          ...(response.associationInfo || {}),
          membershipNumber:
            membershipNumber || response.associationInfo?.membershipNumber,
          realEstateAssociation:
            realEstateAssociation ||
            response.associationInfo?.realEstateAssociation,
        },
      };

      const realtor: Realtor = {
        realtorId: random(0, 999999),
        foreignId: realEstateAgency.id,
      };

      const mainOffice: Office = {
        isMainOffice: true,
        postalAddressDiffersVisitAddress: false,
        dateTimeCreated: new Date(),
        dateTimeModified: new Date(),
        isActive: true,
        isNew: true,
        realEstateAgencyId: realEstateAgency.id,
        id: uuid(),
        visitAddress: address || { countryIso2 },
        postalAddress: address,
        webAddress,
        emailAddresses: !email
          ? undefined
          : [{ address: email, type: EmailAddressType.Work }],
        phoneNumbers: !phoneNumber
          ? undefined
          : [{ number: phoneNumber, type: PhoneNumberType.Work }],
      };

      const companySettings: CompanySettings = {
        defaultLanguage: Language.Dutch,
        isDemo: false,
        dateTimeCreated: new Date(),
        dateTimeModified: new Date(),
        isActive: true,
        isNew: true,
        realEstateAgencyId: realEstateAgency.id,
        id: uuid(),
        groupId: groupId,
        enabledLanguages: [Language.Dutch, Language.English],
        enabledRealEstateGroups: [
          RealEstateGroup.Residential,
          RealEstateGroup.Agricultural,
          RealEstateGroup.Commercial,
        ],
        enabledAssignmentTypes: [
          AssignmentType.ObjectType,
          AssignmentType.Object,
          AssignmentType.Project,
        ],
      };

      const mlsRealEstateAgency: MlsRealEstateAgency = {
        id: realEstateAgency.id,
        dateTimeCreated: new Date(),
        dateTimeModified: new Date(),
        isActive: false,
      };

      const { id } = realEstateAgency;
      const rootPath = `/helpdesk/real-estate-agencies/${id}`;
      const currentPath = `${rootPath}/edit`;

      batch(() => {
        dispatch(
          realEstateAgenciesActions.realEstateAgencies.add(realEstateAgency)
        );
        dispatch(
          realEstateAgenciesActions.mlsRealEstateAgencies.add(realEstateAgency)
        );

        dispatch(realEstateAgenciesActions.realtors.editable.add(realtor));
        dispatch(realEstateAgenciesActions.realtors.original.add(realtor));
        dispatch(
          realEstateAgenciesActions.mainOffices.editable.add(mainOffice)
        );
        dispatch(
          realEstateAgenciesActions.mainOffices.original.add(mainOffice)
        );
        dispatch(
          realEstateAgenciesActions.companySettings.editable.add(
            companySettings
          )
        );
        dispatch(
          realEstateAgenciesActions.companySettings.original.add(
            companySettings
          )
        );

        dispatch(
          realEstateAgenciesActions.mlsRealEstateAgencies.add(
            mlsRealEstateAgency
          )
        );
        dispatch(
          editablesActions.addEditable({
            id,
            type: EditableType.RealEstateAgency,
            rootPath,
            currentPath,
            referrer: !args.history ? window.location.pathname || "/" : "/",
            hasChanges: false,
            hasError: false,
            isSaving: false,
            title: "...",
          })
        );
      });

      args.history?.replace("/");
      args.navigate(currentPath);
    } catch (error) {
      handleError(error);
    }
  }
);

type AddBackofficeAccountArgs = {
  employeeId: string;
  backOfficeRealEstateAgencyId: string;
  realEstateAgencyId: string;
};
const addBackofficeAccount = createThunk(
  "addBackofficeAccount",
  async ({ http, handleError, settings }, args: AddBackofficeAccountArgs) => {
    try {
      const client = new EmployeesClient(settings?.core_api_url, http);
      const { realEstateAgencyId, ...request } = args;

      await client.addToBackOffice(request, realEstateAgencyId);
      return;
    } catch (error) {
      handleError(error);
    }
  }
);

type RemoveBackofficeAccountArgs = {
  employeeId: string;
  backOfficeRealEstateAgencyId: string;
  realEstateAgencyId: string;
};
const removeBackofficeAccount = createThunk(
  "removeBackofficeAccount",
  async (
    { http, handleError, settings },
    args: RemoveBackofficeAccountArgs
  ) => {
    try {
      const client = new EmployeesClient(settings?.core_api_url, http);
      const { realEstateAgencyId, ...request } = args;

      await client.removeFromBackOffice(request, realEstateAgencyId);
      return;
    } catch (error) {
      handleError(error);
    }
  }
);

const saveRealEstateAgency = createThunk(
  "saveRealEstateAgency",
  async (
    { http, dispatch, getState, handleError, settings },
    args: EditableRealEstateAgencyShape
  ) => {
    try {
      const { realEstateAgency, mainOffice, companySettings } = args;
      if (!realEstateAgency) {
        throw new Error("No realestate agency passed to save call");
      }

      const waitForRealEstateAgencyRead = (
        client: RealEstateAgenciesClient,
        realEstateAgencyId: string
      ) =>
        new Promise(resolve => {
          const validate = () =>
            client
              .read(realEstateAgencyId)
              .then(resolve)
              .catch(() => setTimeout(validate, 2000));
          validate();
        });

      const client = new RealEstateAgenciesClient(settings?.core_api_url, http);
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";

      const isNew = await client
        .read(realEstateAgency.id)
        .then(response => response?.realEstateAgency?.isNew ?? true)
        .catch(() => true);

      if (isNew) {
        await dispatch(employeesThunks.employees.fetchAndSaveEmployee());
      }

      const response = await client
        .save(
          { realEstateAgency: { ...realEstateAgency, isNew } },
          realEstateAgencyId
        )
        .then(response => response?.realEstateAgency);

      if (!response) {
        throw new Error("Realestate agency couldn't be saved");
      }

      await waitForRealEstateAgencyRead(client, response.id);

      await dispatch(
        editablesActions.updateEditable({
          id: response.id,
          changes: { title: response?.name || "" },
        })
      );

      await dispatch(
        mlsRealEstateAgenciesThunks.realtors.fetchAndAddRealtor({
          realEstateAgencyId: response.id,
          clearTemporaryIds: true,
        })
      )
        .then(unwrapResult)
        .catch(error => {
          throw error;
        });

      if (!!mainOffice) {
        await dispatch(
          mlsRealEstateAgenciesThunks.mainOffices.createMainOffice({
            mainOffice: { ...mainOffice, name: response.name },
            realEstateAgencyId: response.id,
          })
        )
          .then(unwrapResult)
          .catch(error => {
            throw error;
          });
      }

      await dispatch(
        mlsRealEstateAgenciesThunks.companySettings.saveCompanySettings({
          companySettings,
          realEstateAgencyId: response.id,
        })
      )
        .then(unwrapResult)
        .catch(error => {
          throw error;
        });

      return;
    } catch (error) {
      handleError(error);
    }
  }
);

const reset = createThunk(
  "reset",
  async ({ dispatch, getState, handleError }, id: string) => {
    try {
      const state = getState();
      const realEstateAgency =
        realEstateAgenciesSelectors.realEstateAgencies.original.selectById(
          state,
          id
        );
      const realtor = realEstateAgenciesSelectors.realtors.original
        .selectAll(state)
        .find(entity => entity.foreignId === id);
      const companySettings =
        realEstateAgenciesSelectors.companySettings.original
          .selectAll(state)
          .find(entity => entity.realEstateAgencyId === id);
      const mainOffice = realEstateAgenciesSelectors.mainOffices.original
        .selectAll(state)
        .find(entity => entity.realEstateAgencyId === id);

      batch(() => {
        if (!!realEstateAgency) {
          dispatch(
            realEstateAgenciesActions.realEstateAgencies.editable.update({
              id: realEstateAgency.id,
              changes: realEstateAgency,
            })
          );
        }

        if (!!realtor) {
          dispatch(
            realEstateAgenciesActions.realtors.editable.update({
              id: realtor.realtorId,
              changes: realtor,
            })
          );
        }

        if (!!companySettings) {
          dispatch(
            realEstateAgenciesActions.companySettings.editable.update({
              id: companySettings.id,
              changes: companySettings,
            })
          );
        }

        if (!!mainOffice) {
          dispatch(
            realEstateAgenciesActions.mainOffices.editable.update({
              id: mainOffice.id,
              changes: mainOffice,
            })
          );
        }
      });
    } catch (error) {
      handleError(error);
    }
  }
);

const thunks = {
  fetchRealEstateAgency,
  createNewRealEstateAgency,
  addBackofficeAccount,
  removeBackofficeAccount,
  saveRealEstateAgency,
  reset,
};
export default thunks;
