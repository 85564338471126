import { useSelector } from "hooks/selector";
import React, { FC } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Avatar from "./components/avatar";
import GlobalSearch from "./components/global-search";
import MainMenu from "./components/main-menu";
import Snackbar from "./components/snackbar";
import Tabs from "./components/tabs";
import styles from "./style.module.scss";

const DndProviderFixed = DndProvider as any;

export const LayoutScreen: FC = ({ children }) => {
  const { layoutVisible } = useSelector(state => state.main);

  return !layoutVisible ? (
    <>{children}</>
  ) : (
    <div className={styles.layout}>
      <div className={styles.sidebar}>
        <div className={styles.logo}>
          <img
            src={process.env.PUBLIC_URL + "/Icon_dark.svg"}
            alt="Kolibri Admin"
          />
          <div className={styles.logo__payoff}>Admin</div>
        </div>
        <div className={styles.menu}>
          <MainMenu />
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.header}>
          <div className={styles.header__searchBar}>
            <GlobalSearch />
          </div>
          <Avatar />
        </div>
        <Tabs />
        <div className={styles.routes}>
          <DndProviderFixed backend={HTML5Backend}>{children}</DndProviderFixed>
        </div>
      </div>
      <Snackbar />
    </div>
  );
};
