import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import React, { FC } from "react";
import styles from "./style.module.scss";

type Props = {
  value: boolean;
  label?: string | null;
  labelPosition?: "append" | "prepend";
  disabled?: boolean;
  loading?: boolean;
  style?: React.CSSProperties;
  className?: string;
  onToggle: () => void;
};

export const SwitchComponent: FC<Props> = ({
  value,
  label,
  labelPosition,
  disabled,
  loading,
  style,
  className,
  onToggle,
}) => {
  const onToggleCallback = () => {
    if (!!disabled) {
      return;
    }

    onToggle();
  };

  return (
    <div className={classnames(styles.switch, className)} style={style}>
      <div
        className={classnames(
          styles.trigger,
          styles[labelPosition || "prepend"],
          { [styles.disabled]: disabled || loading }
        )}
        onClick={onToggleCallback}
      >
        {!!label && <div className={styles.label}>{label}</div>}
        <div className={classnames(styles.field, { [styles.active]: !!value })}>
          <div className={styles.knob}>
            {!!loading && <FontAwesomeIcon icon={faSpinnerThird as any} spin />}
          </div>
        </div>
      </div>
    </div>
  );
};
