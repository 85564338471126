import { useDispatch } from "hooks/dispatch";
import React, { FC, useEffect } from "react";
import { mainActions } from "store/main";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpaceStationMoonAlt,
  faStarfighterAlt,
  faStarfighter,
} from "@fortawesome/pro-solid-svg-icons";
import styles from "./style.module.scss";
import { Link } from "react-router-dom";

export const NotFoundScreen: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(mainActions.toggleLayoutVisible(false));

    return () => {
      dispatch(mainActions.toggleLayoutVisible(true));
    };
  }, [dispatch]);

  return (
    <div className={styles.notFound}>
      <div className={styles.visual}>
        <FontAwesomeIcon
          icon={faSpaceStationMoonAlt as any}
          style={{ fontSize: 240 }}
        />
        <FontAwesomeIcon
          icon={faStarfighterAlt as any}
          style={{ fontSize: 36 }}
          className={styles.starFighter}
        />
      </div>
      <div className={styles.content}>
        <h1>404</h1>
        <h2>This is not the page you you're looking for, move along...</h2>
        <Link to="/" className={styles.contentNavigation}>
          <div>
            <FontAwesomeIcon
              icon={faStarfighter as any}
              style={{ fontSize: 20 }}
              className={styles.navStarFighter}
            />
          </div>
          <div className={styles.link}>Get me out of here at lightspeed!</div>
        </Link>
      </div>
    </div>
  );
};
