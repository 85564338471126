import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import styles from "./style.module.scss";

export const LoadingStateComponent: FC = () => {
  return (
    <div className={styles.loadingState}>
      <FontAwesomeIcon icon={faSpinner as any} spin />
    </div>
  );
};
