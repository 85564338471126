import classnames from "classnames";
import React, { forwardRef } from "react";
import styles from "./style.module.scss";

type Props = {
  className?: string;
  active?: boolean;
  activeClassName?: string;
  style?: React.CSSProperties;
  icon?: React.ReactElement;
  label?: string;
  onClick?: () => void;
  "data-cy"?: string;
};
export const MenuItemComponent = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      className,
      style,
      icon,
      label,
      active,
      activeClassName,
      onClick,
    },
    innerRef
  ) => {
    const onClickCallback = () => {
      onClick && onClick();
    };

    return (
      <div
        className={classnames(styles.menuItem, className, {
          [activeClassName || ""]: active,
          [styles.clickable]: !!onClick,
        })}
        style={style}
        onClick={onClickCallback}
        ref={innerRef}
      >
        {!!icon && <div className={styles.icon}>{icon}</div>}
        <div className={styles.label}>{label || children}</div>
      </div>
    );
  }
);
