import {
  ActiveFilter,
  AssignmentSnapShot,
  HelpdeskSearchAssignmentsSearchResult,
  HelpdeskSearchEmployeesSearchResult,
  HelpdeskSearchRealEstateAgenciesSearchResult,
  HelpdeskSearchType,
  RealEstateAgencySnapShot,
  RelationSnapShot,
} from "@kolibri/search-api";
import { ASSIGNMENTS, EMPLOYEES, REALESTATEAGENCIES } from "constants/routes";
import { useDispatch } from "hooks/dispatch";
import { useNavigate } from "hooks/navigate";
import { useSelector } from "hooks/selector";
import debounce from "lodash-es/debounce";
import { mapRouteParams } from "mappers/route";
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { globalSearchActions, globalSearchThunks } from "store/global-search";
import { thunks as realEstateAgenciesThunks } from "store/real-estate-agencies";

const MINIMAL_QUERY_LENGTH = 2;
const FILTER_BY_TYPES = [
  HelpdeskSearchType.RealEstateAgencies,
  HelpdeskSearchType.Employees,
  HelpdeskSearchType.Assignments,
];
let promise: any;

type Response = {
  wrapperRefCallback: (element: HTMLDivElement) => void;
  focusCallback: () => void;
  input: React.MutableRefObject<HTMLInputElement | null>;
  query: string;
  onChangeCallback: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
  clearCallback: () => void;
  toggleDropdownCallback: () => void;
  dropdownVisible: boolean;
  dropdownStyles: CSSProperties | undefined;
  dropdown: React.MutableRefObject<HTMLDivElement | null>;
  validQueryLength: boolean;
  filterByTypes: HelpdeskSearchType[];
  realEstateAgenciesTotalCount: number;
  realEstateAgencies: HelpdeskSearchRealEstateAgenciesSearchResult | null;
  realEstateAgenciesRemainingCount: number;
  employeesTotalCount: number;
  employees: HelpdeskSearchEmployeesSearchResult | null;
  employeesRemainingCount: number;
  assignmentsTotalCount: number;
  assignments: HelpdeskSearchAssignmentsSearchResult | null;
  assignmentsRemainingCount: number;
  toggleFilterByTypes: (type: HelpdeskSearchType) => void;
  onLoadMoreCallback: (type: HelpdeskSearchType) => void;
  triggerLabel: string;
  toggleFilterByActive: () => void;
  filterByActive: ActiveFilter;
  navigateToEmployee: (item: RelationSnapShot) => void;
  navigateToAssignment: (item: AssignmentSnapShot) => void;
  createNewEmployee: () => void;
  navigateToRealEstateAgency: (item: RealEstateAgencySnapShot) => void;
  createNewRealEstateAgency: () => void;
  createEmployeeVisible: boolean;
  closeCreateEmployee: () => void;
};

export const useController = (): Response => {
  const navigate = useNavigate();
  const intl = useIntl();
  const assignments = useSelector(state => state.globalSearch.assignments);
  const employees = useSelector(state => state.globalSearch.employees);
  const realEstateAgencies = useSelector(
    state => state.globalSearch.realEstateAgencies
  );
  const wrapper = useRef<HTMLDivElement | null>(null);
  const input = useRef<HTMLInputElement | null>(null);
  const dropdown = useRef<HTMLDivElement | null>(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [query, setQuery] = useState("");
  const [dropdownStyles, setDropdownStyles] = useState<
    CSSProperties | undefined
  >();
  const [filterByTypes, setFilterByTypes] = useState(FILTER_BY_TYPES);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [filterByActive, setFilterByActive] = useState(
    ActiveFilter.ActiveOrInactive
  );
  const [createEmployeeVisible, setCreateEmployeeVisible] = useState(false);

  const assignmentsTotalCount = assignments?.totalResults || 0;
  const assignmentsRemainingCount =
    assignmentsTotalCount - (assignments?.results?.length || 0);
  const employeesTotalCount = employees?.totalResults || 0;
  const employeesRemainingCount =
    employeesTotalCount - (employees?.results?.length || 0);
  const realEstateAgenciesTotalCount = realEstateAgencies?.totalResults || 0;
  const realEstateAgenciesRemainingCount =
    realEstateAgenciesTotalCount - (realEstateAgencies?.results?.length || 0);
  const validQueryLength = query.length >= MINIMAL_QUERY_LENGTH;

  const triggerLabel = useMemo(() => {
    const types = !filterByTypes.length ? FILTER_BY_TYPES : filterByTypes;
    const translations = types.map((type, idx) => {
      const label = intl
        .formatMessage({ id: `helpdesk.search.label.${type.toString()}` })
        .toLowerCase();

      return idx === 0 ? label.charAt(0).toUpperCase() + label.slice(1) : label;
    });

    return intl.formatList(translations);
  }, [filterByTypes, intl]);

  const search = useMemo(
    () =>
      debounce(
        async (
          term: string,
          filterByTypes: HelpdeskSearchType[],
          filterByActive: ActiveFilter
        ) => {
          try {
            setLoading(true);

            if (term.length < MINIMAL_QUERY_LENGTH || !filterByTypes.length) {
              return;
            }

            if (!!promise) {
              promise.abort();
            }

            promise = dispatch(
              globalSearchThunks.search({ term, filterByTypes, filterByActive })
            );

            await promise;
          } finally {
            setLoading(false);
            setDropdownVisible(true);
          }
        },
        500,
        { leading: true }
      ),
    [dispatch, setLoading, setDropdownVisible]
  );

  const toggleFilterByTypes = useCallback(
    (type: HelpdeskSearchType) => {
      let types: HelpdeskSearchType[];
      setFilterByTypes(filterByTypes => {
        if (filterByTypes.includes(type)) {
          types = filterByTypes.filter(filterByType => filterByType !== type);
        } else {
          types = [...filterByTypes, type];
        }

        types.sort((a, b) => {
          const aI = FILTER_BY_TYPES.indexOf(a);
          const bI = FILTER_BY_TYPES.indexOf(b);

          return aI < bI ? -1 : bI < aI ? 1 : 0;
        });

        search(query, types, filterByActive);
        return types;
      });
    },
    [search, query, filterByActive, setFilterByTypes]
  );

  const getDropdownStyles = useCallback(() => {
    if (!!wrapper.current) {
      const { left, width } = wrapper.current.getBoundingClientRect();
      setDropdownStyles({ left, width });
    }
  }, [setDropdownStyles]);

  const wrapperRefCallback = (element: HTMLDivElement) => {
    if (!!element && !wrapper.current) {
      wrapper.current = element;
      getDropdownStyles();
    }
  };

  const focusCallback = () => {
    input.current?.focus();
  };

  const clearCallback = () => {
    setQuery("");
    input.current?.focus();
  };

  const toggleDropdownCallback = () => {
    !dropdownVisible ? setDropdownVisible(true) : setDropdownVisible(false);
  };

  const onChangeCallback = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const query = event.target.value || "";
      setQuery(query);
      search(query, filterByTypes, filterByActive);
      if (query.length < 2) {
        dispatch(globalSearchActions.setSearchResponse(null));
        if (!!promise) {
          promise.abort();
        }
      }
    },
    [setQuery, search, dispatch, filterByTypes, filterByActive]
  );

  const onKeydownCallback = useCallback(
    (event: KeyboardEvent) => {
      if (!dropdownVisible && document.activeElement !== input.current) return;
      const { keyCode, code } = event;
      let keyName = code;

      if (!keyName && keyCode === 27) {
        keyName = "Escape";
      }

      switch (keyName) {
        case "Escape": {
          if (!query) {
            setDropdownVisible(false);
          }
          setQuery("");
          dispatch(globalSearchActions.setSearchResponse(null));
          break;
        }
        default: {
          break;
        }
      }
    },
    [dropdownVisible, input, setDropdownVisible, query, setQuery, dispatch]
  );

  const onLoadMoreCallback = useCallback(
    (type: HelpdeskSearchType) => {
      switch (type) {
        case HelpdeskSearchType.Assignments: {
          dispatch(
            globalSearchThunks.getRemainingAssignments({
              skip: 5,
              term: query,
              filterByActive,
            })
          );
          break;
        }
        case HelpdeskSearchType.Employees: {
          dispatch(
            globalSearchThunks.getRemainingEmployees({
              skip: 5,
              term: query,
              filterByActive,
            })
          );
          break;
        }
        case HelpdeskSearchType.RealEstateAgencies: {
          dispatch(
            globalSearchThunks.getRemainingRealEstateAgencies({
              skip: 5,
              term: query,
              filterByActive,
            })
          );
          break;
        }
        default: {
          break;
        }
      }
    },
    [query, filterByActive, dispatch]
  );

  const toggleFilterByActive = () => {
    const value =
      filterByActive === ActiveFilter.ActiveOnly
        ? ActiveFilter.ActiveOrInactive
        : ActiveFilter.ActiveOnly;

    setFilterByActive(value);
    search(query, filterByTypes, value);
  };

  const navigateToEmployee = (item: RelationSnapShot) => {
    navigate(
      mapRouteParams(EMPLOYEES.detail, {
        id: item.id,
        realEstateAgencyId: item.realEstateAgencyId,
      })
    );
    setDropdownVisible(false);
  };

  const navigateToAssignment = (item: AssignmentSnapShot) => {
    navigate(
      mapRouteParams(ASSIGNMENTS.detail, {
        id: item.id,
        realEstateAgencyId: item.realEstateAgencyId,
      })
    );
    setDropdownVisible(false);
  };

  const createNewEmployee = () => {
    setCreateEmployeeVisible(true);
    setDropdownVisible(false);
  };

  const navigateToRealEstateAgency = (item: RealEstateAgencySnapShot) => {
    navigate(mapRouteParams(REALESTATEAGENCIES.detail, { id: item.id }));
    setDropdownVisible(false);
  };

  const createNewRealEstateAgency = () => {
    dispatch(
      realEstateAgenciesThunks.realEstateAgencies.createNewRealEstateAgency({
        name: query,
        navigate,
      })
    );
    setDropdownVisible(false);
  };

  const closeCreateEmployee = () => {
    setCreateEmployeeVisible(false);
  };

  useEffect(() => {
    window.addEventListener("resize", getDropdownStyles, true);
    return () => {
      window.removeEventListener("resize", getDropdownStyles, true);
    };
  }, [getDropdownStyles]);

  useEffect(() => {
    window.addEventListener("keyup", onKeydownCallback, true);
    return () => {
      window.removeEventListener("keyup", onKeydownCallback, true);
    };
  }, [onKeydownCallback]);

  return {
    wrapperRefCallback,
    focusCallback,
    input,
    query,
    onChangeCallback,
    loading,
    clearCallback,
    toggleDropdownCallback,
    dropdownVisible,
    dropdownStyles,
    dropdown,
    validQueryLength,
    filterByTypes,
    realEstateAgenciesTotalCount,
    realEstateAgencies,
    realEstateAgenciesRemainingCount,
    employeesTotalCount,
    employees,
    employeesRemainingCount,
    assignmentsTotalCount,
    assignments,
    assignmentsRemainingCount,
    toggleFilterByTypes,
    onLoadMoreCallback,
    triggerLabel,
    toggleFilterByActive,
    filterByActive,
    navigateToEmployee,
    navigateToAssignment,
    createNewEmployee,
    navigateToRealEstateAgency,
    createNewRealEstateAgency,
    createEmployeeVisible,
    closeCreateEmployee,
  };
};
