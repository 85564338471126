import React, { FC } from "react";
import classnames from "classnames";
import styles from "./style.module.scss";
import { CommonProps } from "../../types";

type Props = CommonProps & {
  contain?: boolean;
  scrollable?: boolean | "vertical" | "horizontal";
  width?: number;
  height?: number;
};

export const ViewComponent: FC<Props> = ({
  children,
  className,
  style,
  contain,
  scrollable,
  width,
  height,
  flex,
}) => {
  const scrollClass = !scrollable
    ? ""
    : scrollable === "horizontal"
    ? "scrollable-h"
    : scrollable === "vertical"
    ? "scrollable-v"
    : "scrollable";

  style = style || {};
  if (!!width || width === 0) {
    style = { ...style, width, flex: "none" };
  }
  if (!!height || height === 0) {
    style = { ...style, height, flex: "none" };
  }
  if (flex !== undefined) {
    style = { ...style, flex };
  }

  return (
    <div
      className={classnames(
        styles.view,
        { [styles.contain]: contain },
        styles[scrollClass],
        className
      )}
      style={style}
    >
      {children}
    </div>
  );
};
