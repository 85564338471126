import { Form, Formik } from "formik";
import noop from "lodash-es/noop";
import React, { FC, useCallback, useMemo } from "react";
import { ListColumnFilterOptions } from "../../../..";
import SelectField from "../../../../../field/components/select";
import FormikOnChange from "../../../../../formik-onchange";
import styles from "../../style.module.scss";
import { useIntl } from "react-intl";

type Props = {
  listName: string;
  config: ListColumnFilterOptions;
  onChange: (value: string) => void;
};

export const FilterArrayComponent: FC<Props> = ({
  config,
  listName,
  onChange,
}) => {
  const intl = useIntl();

  const values = useMemo(() => {
    const values: { label: string; value: any }[] = [
      {
        value: "",
        label: intl.formatMessage({
          id: `list.${listName}.${config.key}.selectLabel.empty`,
          defaultMessage: "No items found...",
        }),
      },
    ];

    (config.values || []).forEach(value =>
      values.push({
        value: value?.value || value,
        label: !!config?.noTranslate
          ? value.label
          : intl.formatMessage({
              id: !!value.label
                ? value.label
                : !!config.valuesPrefix
                ? `${config.valuesPrefix}.${value.toString()}`
                : `list.${listName}.${
                    config.key
                  }.selectLabel.${value.toString()}`,
              defaultMessage: !!value.label
                ? value.label
                : value?.toString() || "Missing value...",
            }),
      })
    );

    return values;
  }, [config, listName, intl]);

  const onChangeCallback = useCallback(
    (values: any) => {
      const value = values?.select?.value || "";
      onChange(value);
    },
    [onChange]
  );

  return (
    <div className={styles.select}>
      <Formik
        initialValues={{ select: config.initialValue || "" }}
        onSubmit={noop}
      >
        <Form>
          <FormikOnChange onChange={onChangeCallback} />
          <SelectField
            name="select"
            values={values}
            matchOn="value"
            style={{ width: "100%" }}
            labelFn={item => (
              <div className={styles.selectItem}>{item?.label}</div>
            )}
          >
            {({ item }) => (
              <div className={styles.selectItem}>{item.label}</div>
            )}
          </SelectField>
        </Form>
      </Formik>
    </div>
  );
};
