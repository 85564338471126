import {
  createAction,
  createEntityAdapter,
  createSlice,
  Update,
} from "@reduxjs/toolkit";
import { SinglePermissionResource } from "@kolibri/authorization-api";
import { editablesActions } from "store/editables";
import { EditableType } from "enums/editable-types";

export const add = createAction<SinglePermissionResource>(
  "api-resources/permission-resources/add"
);
export const update = createAction<Update<SinglePermissionResource>>(
  "api-resources/permission-resources/update"
);

export const originalAdapter = createEntityAdapter<SinglePermissionResource>();
export const originalSlice = createSlice({
  name: "api-resources/permission-resources/original",
  initialState: originalAdapter.getInitialState(),
  reducers: {
    add: originalAdapter.upsertOne,
    update: originalAdapter.updateOne,
  },
  extraReducers: builder => {
    builder.addCase(editablesActions.removeEditable, (state, action) => {
      if (action.payload.type === EditableType.PermissionResource) {
        originalAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(add, (state, action) => {
      originalAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(update, (state, action) => {
      originalAdapter.updateOne(state, action.payload);
    });
  },
});

export const editableAdapter = createEntityAdapter<SinglePermissionResource>();
export const editableSlice = createSlice({
  name: "api-resources/permission-resources/editable",
  initialState: editableAdapter.getInitialState(),
  reducers: {
    add: editableAdapter.upsertOne,
    update: editableAdapter.updateOne,
  },
  extraReducers: builder => {
    builder.addCase(editablesActions.removeEditable, (state, action) => {
      if (action.payload.type === EditableType.PermissionResource) {
        editableAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(add, (state, action) => {
      editableAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(update, (state, action) => {
      editableAdapter.updateOne(state, action.payload);
    });
  },
});
