import { ObjectAssignment } from "@kolibri/core-api";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { EditableType } from "enums/editable-types";
import { editablesActions } from "store/editables";

export const adapter = createEntityAdapter<ObjectAssignment>();
export const slice = createSlice({
  name: "assignments/assignments",
  initialState: adapter.getInitialState(),
  reducers: {
    add: adapter.upsertOne,
    update: adapter.updateOne,
  },
  extraReducers: builder => {
    builder.addCase(editablesActions.removeEditable, (state, action) => {
      if (action.payload.type === EditableType.Assignment) {
        adapter.removeOne(state, action.payload.id);
      }
    });
  },
});
