import { createThunk } from "helpers/store";
import { Client, CreateUserRequest } from "@kolibri/id-api";
import { Employee } from "@kolibri/core-api";
import { actions as userEntitiesActions } from ".";

type CreateUseraccountArgs = CreateUserRequest & {
  employee: Employee;
};

const createUseraccount = createThunk(
  "user-entities/createUseraccount",
  async ({ http, settings, dispatch }, args: CreateUseraccountArgs) => {
    try {
      const client = new Client(settings?.id_api_url, http);
      const { employee, ...body } = args;
      const { realEstateAgencyId } = employee;

      const user = await client
        .usersPOST(realEstateAgencyId, body)
        .then(response => response.user);

      if (user) {
        await dispatch(userEntitiesActions.add(user));
      }
    } catch (error) {
      throw error;
    }
  }
);

const thunks = {
  createUseraccount,
};
export default thunks;
