import classnames from "classnames";
import { useField } from "formik";
import React, { FC, useCallback, useMemo } from "react";
import fieldStyles from "../../style.module.scss";
import styles from "./style.module.scss";

type Props = {
  style?: React.CSSProperties;
  className?: string;
  name: string;
  on?: any;
  off?: any;
  hint?: string;
  label?: string;
  disabled?: boolean;
  "data-cy"?: string;
};

export const ToggleComponent: FC<Props> = ({
  name,
  hint,
  on,
  off,
  label,
  disabled,
  "data-cy": dataCy,
}) => {
  const [{ value }, { error, touched }, { setValue }] = useField(name);

  const active = useMemo(() => {
    if (!on || !off) {
      return value as boolean;
    } else {
      return value === on ? true : false;
    }
  }, [value, on, off]);

  const onToggleCallback = useCallback(() => {
    if (disabled) {
      return;
    }

    if (!on || !off) {
      setValue(!value);
    } else {
      setValue(value === on ? off : on);
    }
  }, [value, on, off, disabled]);

  return (
    <div className={fieldStyles.wrapper}>
      <input
        name={name}
        id={name}
        type="hidden"
        value={value}
        data-cy={dataCy}
      />
      <div
        onClick={onToggleCallback}
        className={classnames(styles.toggle, {
          [styles.disabled]: disabled,
        })}
      >
        <div
          className={classnames(styles.visual, {
            [styles.active]: active,
            [styles.disabled]: disabled,
          })}
        />
        {label && (
          <div
            className={classnames(styles.label, {
              [styles.disabled]: disabled,
            })}
            dangerouslySetInnerHTML={{ __html: label }}
          />
        )}
      </div>
      {touched && error && <div className={fieldStyles.error}>{error}</div>}
      {!(touched && error) && hint && (
        <div className={fieldStyles.hint}>{hint}</div>
      )}
    </div>
  );
};
