import {
  CompanySettings,
  CompanySettingsClient,
  CompanySettingsSaveRequest,
} from "@kolibri/core-api";
import { createThunk } from "helpers/store";
import { batch } from "react-redux";
import {
  actions as realEstateAgenciesActions,
  selectors as realEstateAgenciesSelectors,
} from "../";

const fetchCompanySettings = createThunk(
  "fetchCompanySettings",
  async ({ http, dispatch, handleError, settings }, id: string) => {
    try {
      const client = new CompanySettingsClient(settings?.core_api_url, http);

      const companySettings = await client
        .read(id)
        .then(response => response?.companySettings);
      if (!companySettings) {
        throw Error("Couldn't find company settings");
      }

      dispatch(realEstateAgenciesActions.companySettings.add(companySettings));

      return;
    } catch (error) {
      handleError(error);
    }
  }
);

type SaveCompanySettingsArgs = {
  realEstateAgencyId: string;
  companySettings?: CompanySettings | null;
};
const saveCompanySettings = createThunk(
  "saveCompanySettings",
  async (
    { http, dispatch, getState, handleError, settings },
    args: SaveCompanySettingsArgs
  ) => {
    try {
      const { realEstateAgencyId, companySettings } = args;
      if (!companySettings) {
        throw new Error("No company settings provided to save method");
      }

      const client = new CompanySettingsClient(settings?.core_api_url, http);

      const companySettingsResponse = await client
        .read(realEstateAgencyId)
        .then(response => response?.companySettings);

      if (!companySettingsResponse) {
        throw Error("Couldn't save company settings");
      }

      const {
        id,
        dateTimeModified,
        dateTimeCreated,
        isNew,
        ...data
      } = companySettings;
      const request: CompanySettingsSaveRequest = {
        companySettings: {
          ...companySettingsResponse,
          ...data,
        },
      };

      const response = await client
        .save(request, realEstateAgencyId)
        .then(response => response?.companySettings);

      if (!!response) {
        const state = getState();
        const companySettings = realEstateAgenciesSelectors.companySettings.editable.selectAll(
          state
        );
        const ids = companySettings
          .filter(
            companySetting =>
              companySetting.realEstateAgencyId === realEstateAgencyId
          )
          .map(companySetting => companySetting.id);

        batch(() => {
          dispatch(realEstateAgenciesActions.companySettings.remove(ids));
          dispatch(realEstateAgenciesActions.companySettings.add(response));
        });
      }

      return;
    } catch (error) {
      handleError(error);
    }
  }
);

const thunks = { fetchCompanySettings, saveCompanySettings };
export default thunks;
