import { DeleteJob, DeleteJobStatus } from "@kolibri/admin-api";
import deleteJobsThunks from "./thunks";
import {
  editableSlice,
  originalSlice,
  add,
  update,
  editableAdapter,
  originalAdapter,
} from "./slices";
import { combineReducers } from "redux";
import { PersistConfig, persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage/session";
import { RootState } from "store";

const combinedReducers = combineReducers({
  original: originalSlice.reducer,
  editable: editableSlice.reducer,
});

const persistConfig: PersistConfig<ReturnType<typeof combinedReducers>> = {
  storage,
  key: "k24/real-estate-agencies/delete-jobs",
};

export const reducer = persistReducer(persistConfig, combinedReducers);

export const selectors = {
  editable: editableAdapter.getSelectors<RootState>(
    state => state.realEstateAgencies.deleteJobs.editable
  ),
  original: originalAdapter.getSelectors<RootState>(
    state => state.realEstateAgencies.deleteJobs.original
  ),
  originalShape: (state: RootState, id: string) => {
    const deleteJob = originalAdapter
      .getSelectors<RootState>(
        state => state.realEstateAgencies.deleteJobs.original
      )
      .selectById(state, id);
    return {
      deleteJob: deleteJob || null,
    } as EditableDeleteJobShape;
  },
};

export const thunks = deleteJobsThunks;

export const actions = {
  editable: editableSlice.actions,
  original: originalSlice.actions,
  add,
  update,
};

export type ExtendedJob = DeleteJob & {
  statuses: DeleteJobStatus[];
};

export type EditableDeleteJobShape = {
  deleteJob: ExtendedJob | null;
};
