import Callback from "modules/auth/components/callback";
import Renew from "modules/auth/components/renew";
import RootModule from "modules/root";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import LoadingScreen from "screens/loading";
import { httpWebUtil, persistor, store } from "store";
import { RequestStatus } from "enums/request-status";
import ErrorScreen from "screens/error";
import storage from "redux-persist/lib/storage/session";
import { REDIRECT_LOCALSTORAGE_KEY } from "constants/config";
import { PersistGate } from "redux-persist/integration/react";

const PersistGateFixed = PersistGate as any;
const Router = BrowserRouter as any;

const RootSwitch = () => {
  const [status, setStatus] = useState(RequestStatus.Idle);
  const location = useLocation();

  const initial = useCallback(async () => {
    try {
      if (await httpWebUtil.shouldRedirect()) {
        await storage.setItem(
          REDIRECT_LOCALSTORAGE_KEY,
          location.pathname + window.location.search
        );
        await httpWebUtil.signinRedirect();
        return;
      }

      setStatus(RequestStatus.Success);
    } catch (error) {
      setStatus(RequestStatus.Failed);
      throw error;
    }
  }, [setStatus, location]);

  useEffect(() => {
    initial();
  }, [initial]);

  switch (status) {
    case RequestStatus.Success: {
      return (
        <Provider store={store}>
          <PersistGateFixed loading={<LoadingScreen />} persistor={persistor}>
            <RootModule />
          </PersistGateFixed>
        </Provider>
      );
    }
    case RequestStatus.Failed: {
      return <ErrorScreen />;
    }
    default: {
      return <LoadingScreen />;
    }
  }
};

const App: FC = () => {
  return (
    <Router>
      <Switch>
        <Route
          path="/callback"
          // @ts-ignore
          exact
        >
          <Callback />
        </Route>
        <Route
          path="/renew"
          // @ts-ignore
          exact
        >
          <Renew />
        </Route>
        <Route path="/">
          <RootSwitch />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
