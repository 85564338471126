import classnames from "classnames";
import React, { FC } from "react";
import { Theme, Shape } from "../../types/common";
import styles from "./style.module.scss";

type Props = {
  image?: string | null;
  text?: string;
  size?: number;
  theme?: Theme;
  inline?: boolean;
  shape?: Shape;
  className?: string;
  style?: React.CSSProperties;
};

export const AvatarComponent: FC<Props> = ({
  image,
  text,
  size,
  theme,
  inline,
  shape,
  className,
  style,
  children,
}) => {
  let content: string | React.ReactElement | null | undefined;

  switch (true) {
    case !!image: {
      content = (
        <div
          className={styles.image}
          style={{ backgroundImage: `url(${JSON.stringify(image)})` }}
        />
      );
      break;
    }
    case !!text: {
      content = text;
      break;
    }
    default: {
      break;
    }
  }

  const avatarStyle: React.CSSProperties | undefined = !size
    ? style
    : { ...style, width: size, height: size };

  return (
    <div
      className={classnames(
        styles.avatar,
        styles[shape || "circle"],
        {
          [styles.inline]: inline,
          [styles[theme || "flat"]]: !!theme || (!className && !image),
        },
        className
      )}
      style={avatarStyle}
    >
      {content || children}
    </div>
  );
};
