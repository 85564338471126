import React, { FC, useState, useCallback, useEffect } from "react";
import LoadingScreen from "screens/loading";
import { useHistory } from "react-router-dom";
import { httpWebUtil } from "store";
import storage from "redux-persist/lib/storage/session";
import { REDIRECT_LOCALSTORAGE_KEY } from "constants/config";
import ErrorScreen from "screens/error";

export const CallbackComponent: FC = () => {
  const [error, setError] = useState(false);
  const history = useHistory();

  const redirectCallback = useCallback(async () => {
    try {
      const user = await httpWebUtil.signinRedirectCallback();
      if (!user || user.expired) {
        setError(true);
        return;
      }

      const redirect = await storage.getItem(REDIRECT_LOCALSTORAGE_KEY);

      if (!!redirect) {
        await storage.removeItem(REDIRECT_LOCALSTORAGE_KEY);
      }

      history.push(redirect || "/");
    } catch (error) {
      setError(true);
      throw error;
    }
  }, [setError, history]);

  useEffect(() => {
    redirectCallback();
  }, [redirectCallback]);

  return <>{error ? <ErrorScreen /> : <LoadingScreen />}</>;
};
