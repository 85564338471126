import {
  ArchiveFilter,
  PermissionClient,
  PermissionOrderByField,
  PermissionResourceClient,
  SinglePermission,
  SinglePermissionResource,
  SortOrder,
} from "@kolibri/authorization-api";
import { API_RESOURCES } from "constants/routes";
import { EditableType } from "enums/editable-types";
import { mapBasicTranslations } from "helpers/authorization";
import { createThunk } from "helpers/store";
import { mapRouteParams } from "mappers/route";
import { editablesActions, editablesSelector } from "store/editables";
import {
  actions as apiResourcesActions,
  selectors as apiResourcesSelectors,
} from "..";

const fetchPermissionResource = createThunk(
  "fetchPermissionResource",
  async (
    { http, dispatch, getState, settings },
    id: string
  ): Promise<SinglePermissionResource | undefined> => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new PermissionResourceClient(
        settings?.authorization_api_url,
        http
      );

      let permissionResource = await client
        .readPermissionResource(id, realEstateAgencyId)
        .then(response => response.permissionResource);

      if (!permissionResource) {
        throw Error("Couldn't find permission resource");
      }

      const translations = mapBasicTranslations(
        permissionResource.translations
      );

      permissionResource = {
        ...permissionResource,
        translations,
      };

      dispatch(apiResourcesActions.permissionResources.add(permissionResource));
      return permissionResource;
    } catch (error) {
      throw error;
    }
  }
);

type FetchPermissionResourcesArgs = { skip: number; apiResourceId: string };
const fetchPermissionResources = createThunk(
  "fetchPermissionResources",
  async (
    { http, handleError, getState, settings },
    args: FetchPermissionResourcesArgs
  ) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const { skip, apiResourceId } = args;
      const client = new PermissionResourceClient(
        settings?.authorization_api_url,
        http
      );

      const response = await client.search(
        {
          filterByArchived: ArchiveFilter.ArchivedAndNot,
          order: SortOrder.Ascending,
          skip,
          take: 100,
          apiResourceId,
        },
        realEstateAgencyId
      );

      if (!response) {
        throw new Error("Could not fetch permission resources");
      }

      const { totalResults, results } = response;

      return {
        totalResults,
        results: results || [],
      };
    } catch (error) {
      handleError(error);
    }
  }
);

type FetchPermissionsArgs = { skip: number; id: string };
const fetchPermissions = createThunk(
  "fetchPermissions",
  async (
    { http, handleError, getState, settings },
    args: FetchPermissionsArgs
  ) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const { skip, id } = args;
      const client = new PermissionClient(
        settings?.authorization_api_url,
        http
      );

      const response = await client.search(
        {
          filterByArchived: ArchiveFilter.ArchivedAndNot,
          order: SortOrder.Ascending,
          orderBy: PermissionOrderByField.Default,
          skip,
          take: 100,
          permissionResourceIds: [id],
        },
        realEstateAgencyId
      );

      if (!response) {
        throw new Error("Could not fetch permissions");
      }

      const { totalResults, results } = response;

      return {
        totalResults,
        results: results || [],
      };
    } catch (error) {
      handleError(error);
    }
  }
);

const createPermission = createThunk(
  "createPermission",
  async (
    { http, handleError, getState, settings },
    permissionResourceId: string
  ) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new PermissionClient(
        settings?.authorization_api_url,
        http
      );

      return client
        .defineNew(
          {
            permissionResourceId,
          },
          realEstateAgencyId
        )
        .then(response => response.permission);
    } catch (error) {
      handleError(error);
    }
  }
);

type CreatePermissionResourceArgs = {
  apiResourceId: string;
  navigate: (path: string) => void;
};
const createPermissionResource = createThunk(
  "createPermissionResource",
  async (
    { http, handleError, dispatch, getState, settings },
    args: CreatePermissionResourceArgs
  ) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const { apiResourceId, navigate } = args;
      const client = new PermissionResourceClient(
        settings?.authorization_api_url,
        http
      );

      let permissionResource = await client
        .defineNew(
          {
            apiResourceId,
          },
          realEstateAgencyId
        )
        .then(response => response.permissionResource);

      if (!permissionResource) {
        throw Error("Couldn't create permission resource");
      }

      const translations = mapBasicTranslations(
        permissionResource.translations
      );

      permissionResource = {
        ...permissionResource,
        translations,
      };

      const { id } = permissionResource;
      const rootPath = mapRouteParams(API_RESOURCES.permissionResource, {
        id,
      });
      const currentPath = `${rootPath}/edit`;

      dispatch(apiResourcesActions.permissionResources.add(permissionResource));
      dispatch(
        editablesActions.addEditable({
          id,
          type: EditableType.PermissionResource,
          rootPath,
          currentPath,
          referrer: window.location.pathname || "/",
          hasChanges: false,
          hasError: false,
          isSaving: false,
          title: "...",
        })
      );

      navigate(currentPath);
      return;
    } catch (error) {
      handleError(error);
    }
  }
);

const fetchPermission = createThunk(
  "fetchPermission",
  async ({ http, handleError, getState, settings }, id: string) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new PermissionClient(
        settings?.authorization_api_url,
        http
      );

      return client
        .readPermission(id, realEstateAgencyId)
        .then(response => response.permission);
    } catch (error) {
      handleError(error);
    }
  }
);

const savePermission = createThunk(
  "savePermission",
  async (
    { http, handleError, getState, settings },
    permission: SinglePermission
  ) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new PermissionClient(
        settings?.authorization_api_url,
        http
      );

      return client.savePermission({ permission }, realEstateAgencyId);
    } catch (error) {
      handleError(error);
    }
  }
);

type SavePermissionResourceArgs = {
  permissionResource: SinglePermissionResource;
  close: boolean;
  navigate: (path: string) => void;
};
const savePermissionResource = createThunk(
  "savePermissionResource",
  async (
    { http, handleError, dispatch, getState, settings },
    args: SavePermissionResourceArgs
  ) => {
    try {
      const { permissionResource, close, navigate } = args;
      const client = new PermissionResourceClient(
        settings?.authorization_api_url,
        http
      );
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const editables = editablesSelector.selectAll(state);
      const editable = editables.find(
        editable =>
          editable.id === permissionResource.id &&
          editable.type === EditableType.PermissionResource
      );

      if (!editable) {
        throw new Error("No matching editable found");
      }

      const resource = await client
        .savePermissionResource(
          {
            permissionResource,
          },
          realEstateAgencyId
        )
        .then(response => response.permissionResource);

      if (!resource) {
        throw new Error("Permission resource could not be saved");
      }

      dispatch(
        apiResourcesActions.permissionResources.update({
          id: resource.id,
          changes: resource,
        })
      );

      if (!!close) {
        navigate(editable.rootPath);
      }

      return;
    } catch (error) {
      handleError(error);
    }
  }
);

const deletePermission = createThunk(
  "deletePermission",
  async ({ http, handleError, getState, settings }, id: string) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new PermissionClient(
        settings?.authorization_api_url,
        http
      );

      return client.deletePermission(id, realEstateAgencyId);
    } catch (error) {
      handleError(error);
    }
  }
);

const deletePermissionResource = createThunk(
  "deletePermissionResource",
  async ({ http, handleError, getState, settings }, id: string) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new PermissionResourceClient(
        settings?.authorization_api_url,
        http
      );

      return client.deletePermissionResource(id, realEstateAgencyId);
    } catch (error) {
      handleError(error);
    }
  }
);

const reset = createThunk(
  "reset",
  async ({ dispatch, getState, handleError }, id: string) => {
    try {
      const state = getState();
      const entity = apiResourcesSelectors.permissionResources.original.selectById(
        state,
        id
      );

      if (!!entity) {
        dispatch(
          apiResourcesActions.permissionResources.editable.update({
            id: entity.id,
            changes: entity,
          })
        );
      }
    } catch (error) {
      handleError(error);
    }
  }
);

const thunks = {
  fetchPermissionResources,
  fetchPermissionResource,
  fetchPermissions,
  createPermission,
  createPermissionResource,
  fetchPermission,
  savePermission,
  savePermissionResource,
  deletePermission,
  deletePermissionResource,
  reset,
};
export default thunks;
