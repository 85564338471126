import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import scrollbarSize from "dom-helpers/scrollbarSize";
import React, { FC, useRef, useState, Fragment } from "react";
import { useIntl } from "react-intl";
import { ListColumnProps, ListOrder, ListSortChangeProps } from "../..";
import ContextMenu from "../../../context";
import styles from "./style.module.scss";

type Props = {
  listName: string;
  column: ListColumnProps;
  last?: boolean;
  currentOrder?: ListOrder;
  currentOrderBy?: unknown;
  onSortChange?: (props: ListSortChangeProps) => void;
};

export const ColumnComponent: FC<Props> = ({
  listName,
  column,
  last,
  currentOrder,
  currentOrderBy,
  onSortChange,
}) => {
  const hasSortOptions = column.sortOptions;
  const extraWidth = last ? scrollbarSize() : 0;
  const ref = useRef<HTMLDivElement | null>(null);
  const [contextVisible, setContextVisible] = useState(false);
  const intl = useIntl();

  const toggleContextMenu = () => {
    if (!hasSortOptions) {
      return;
    }
    setContextVisible(!contextVisible);
  };

  const sortChangeCallback = (order: "asc" | "desc", column: unknown) => {
    setContextVisible(false);

    if (!onSortChange) {
      return;
    }

    onSortChange({ order, column });
  };

  return (
    <>
      <div
        className={classnames(styles.column, {
          [styles.last]: last,
          [styles.sortable]: hasSortOptions,
          [styles.active]: column.sortOptions?.includes(currentOrderBy),
        })}
        style={{
          width: !column.fill ? column.width : undefined,
          minWidth: !column.fill ? undefined : column.width,
          flex: !column.fill ? undefined : 1,
          marginRight: extraWidth,
        }}
        ref={ref}
        onClick={toggleContextMenu}
      >
        <div className={styles.label}>
          {!!column.label &&
            intl.formatMessage({
              id: `list.${listName}.columnLabel.${column.label}`,
              defaultMessage: column.label,
            })}
        </div>
        {!!hasSortOptions && (
          <div className={styles.trigger}>
            <FontAwesomeIcon icon={faChevronDown as any} />
          </div>
        )}
      </div>
      {!!hasSortOptions && (
        <ContextMenu
          visible={contextVisible}
          parent={ref}
          attachOn="bottomright"
          expandFrom="topright"
          vOffset={-10}
          hOffset={-22}
          onClose={() => setContextVisible(false)}
        >
          <div className={styles.context}>
            <div className={styles.sortTitle}>
              {intl.formatMessage({
                id: "kolibri.ui.common.list.sortTitle",
                defaultMessage: "Sort",
              })}
            </div>
            {column.sortOptions?.map((sortOption, idx) => (
              <Fragment key={idx}>
                <div
                  className={classnames(styles.sortOption, {
                    [styles.active]:
                      currentOrderBy === sortOption && currentOrder === "asc",
                  })}
                  onClick={() => sortChangeCallback("asc", sortOption)}
                >
                  {intl.formatMessage({
                    id: `list.${listName}.sortOption.${(
                      sortOption as any
                    ).toString()}.asc`,
                    defaultMessage: (sortOption as any).toString(),
                  })}
                </div>
                <div
                  className={classnames(styles.sortOption, {
                    [styles.active]:
                      currentOrderBy === sortOption && currentOrder === "desc",
                  })}
                  onClick={() => sortChangeCallback("desc", sortOption)}
                >
                  {intl.formatMessage({
                    id: `list.${listName}.sortOption.${(
                      sortOption as any
                    ).toString()}.desc`,
                    defaultMessage: (sortOption as any).toString(),
                  })}
                </div>
              </Fragment>
            ))}
          </div>
        </ContextMenu>
      )}
    </>
  );
};
