import React, { FC, useRef } from "react";
import styles from "./style.module.scss";
import Portal from "../portal";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

type Props = {
  visible: boolean;
  size?: "small" | "default" | "large" | "fill" | "unset";
  title?: string;
  closeOnBackdropClick?: boolean;
  noCloseButton?: boolean;
  useDefaultPadding?: boolean;
  blockCloseEvent?: boolean;
  actions?: (
    onClose: () => void
  ) => React.ReactElement | React.ReactElement[] | React.ReactNode;
  onClose: () => void;
};

export const ModalComponent: FC<Props> = ({
  visible,
  children,
  size,
  title,
  closeOnBackdropClick,
  noCloseButton,
  useDefaultPadding,
  blockCloseEvent,
  actions,
  onClose,
}) => {
  const modal = useRef<HTMLDivElement | null>(null);

  const onCloseCallback = () => {
    !blockCloseEvent && onClose();
  };

  const closeOnBackdropClickCallback = (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    if (
      !!modal.current?.contains(event.target as Node) ||
      !closeOnBackdropClick
    ) {
      return;
    }
    onCloseCallback();
  };

  return !visible ? null : (
    <Portal>
      <>
        <div className={styles.backdrop} />
        <div
          className={classnames(styles.modal__wrapper, {
            [styles.clickable]: closeOnBackdropClick,
          })}
          onClick={closeOnBackdropClickCallback}
        >
          <div className={styles.spacer} />
          <div
            className={classnames(styles.modal, styles[size || "default"])}
            ref={modal}
          >
            {(!!title || !noCloseButton) && (
              <div className={styles.modal__header}>
                <div className={styles.header__title}>{title}</div>
                {!noCloseButton && (
                  <div
                    className={styles.header__close}
                    onClick={onCloseCallback}
                  >
                    <FontAwesomeIcon icon={faTimes as any} />
                  </div>
                )}
              </div>
            )}
            <div
              className={classnames(styles.modal__body, {
                [styles.useDefaultPadding]: useDefaultPadding,
              })}
            >
              {children}
            </div>
            {!!actions && (
              <div className={styles.modal__footer}>
                {actions(onCloseCallback)}
              </div>
            )}
          </div>
          <div className={styles.spacer} />
        </div>
      </>
    </Portal>
  );
};
