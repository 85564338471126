import classnames from "classnames";
import scrollbarSize from "dom-helpers/scrollbarSize";
import React, { forwardRef, useMemo } from "react";
import {
  ListColumConfig,
  ListFilterChangeProps,
  ListOrder,
  ListSortChangeProps,
} from "../..";
import Column from "../column";
import Filter from "../filter";
import styles from "./style.module.scss";

type Props = {
  listName: string;
  config: ListColumConfig;
  scrolledHorizontal: boolean;
  numberOfFixedColumns?: number;
  currentOrder?: ListOrder;
  currentOrderBy?: unknown;
  onSortChange?: (props: ListSortChangeProps) => void;
  onFilterChange?: (props: ListFilterChangeProps) => void;
};

export const HeaderComponent = forwardRef<HTMLDivElement | null, Props>(
  (
    {
      listName,
      config,
      scrolledHorizontal,
      numberOfFixedColumns,
      currentOrder,
      currentOrderBy,
      onSortChange,
      onFilterChange,
    },
    ref
  ) => {
    const fixedColumns = useMemo(
      () => config.slice(0, numberOfFixedColumns || 0),
      [config, numberOfFixedColumns]
    );
    const staticColums = useMemo(
      () => config.slice(numberOfFixedColumns || 0),
      [config, numberOfFixedColumns]
    );
    const fixedColumnWidth = useMemo(() => {
      return fixedColumns.reduce(
        (state, column) => (state += column.width as number),
        0
      );
    }, [fixedColumns]);
    const staticColumnWidth = useMemo(() => {
      return (
        staticColums.reduce(
          (state, column) => (state += column.width as number),
          0
        ) + scrollbarSize()
      );
    }, [staticColums]);
    const hasFilterOptions = !!config.find(column => !!column.filterOptions);
    const width = fixedColumnWidth + staticColumnWidth;

    return (
      <div className={styles.header} ref={ref}>
        <div className={styles.innerColumns} style={{ minWidth: width }}>
          {!!fixedColumns.length && (
            <div
              className={classnames(styles.fixedColumns, {
                [styles.scrolledHorizontal]: scrolledHorizontal,
              })}
              style={{ width: fixedColumnWidth }}
            >
              {fixedColumns.map((column, idx) => (
                <Column
                  column={column}
                  listName={listName}
                  key={idx}
                  currentOrder={currentOrder}
                  currentOrderBy={currentOrderBy}
                  onSortChange={onSortChange}
                />
              ))}
            </div>
          )}
          <div
            className={styles.staticColumns}
            style={{
              minWidth: staticColumnWidth,
              paddingLeft: fixedColumnWidth,
            }}
          >
            {staticColums.map((column, idx) => (
              <Column
                column={column}
                listName={listName}
                key={idx}
                last={idx === staticColums.length - 1}
                currentOrder={currentOrder}
                currentOrderBy={currentOrderBy}
                onSortChange={onSortChange}
              />
            ))}
          </div>
        </div>

        {!!hasFilterOptions && (
          <div className={styles.innerFilters} style={{ width }}>
            {!!fixedColumns.length && (
              <div
                className={classnames(styles.fixedColumns, {
                  [styles.scrolledHorizontal]: scrolledHorizontal,
                })}
                style={{ width: fixedColumnWidth }}
              >
                {fixedColumns.map((column, idx) => (
                  <Filter
                    column={column}
                    listName={listName}
                    key={idx}
                    last={idx === fixedColumns.length - 1}
                    onChange={onFilterChange}
                    fixed
                  />
                ))}
              </div>
            )}

            <div
              className={styles.staticColumns}
              style={{
                minWidth: staticColumnWidth,
                paddingLeft: fixedColumnWidth,
              }}
            >
              {staticColums.map((column, idx) => (
                <Filter
                  column={column}
                  listName={listName}
                  key={idx}
                  onChange={onFilterChange}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
);
