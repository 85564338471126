import React, { FC } from "react";
import styles from "./style.module.scss";
import Portal from "../portal";
import classnames from "classnames";

type Props = {
  position?:
    | "topleft"
    | "topcenter"
    | "topright"
    | "bottomleft"
    | "bottomcenter"
    | "bottomright";
};

export const SnackbarComponent: FC<Props> = ({ position, children }) => {
  return React.Children.count(children) === 0 ? null : (
    <Portal>
      <div
        className={classnames(
          styles.snackbar,
          styles[position || "bottomright"]
        )}
      >
        <div
          className={classnames(
            styles.reset,
            styles[position || "bottomright"]
          )}
        >
          {children}
        </div>
      </div>
    </Portal>
  );
};
