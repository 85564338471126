import { SystemService } from "@kolibri/admin-api";
import { MasterTableResponse as AuthorizationTableResponse } from "@kolibri/authorization-api";
import {
  Employee,
  TableResponse as CoreTableResponse,
} from "@kolibri/core-api";
import { RelationSnapShot } from "@kolibri/search-api";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatus } from "enums/request-status";

type State = {
  account: Employee | null;
  employees: RelationSnapShot[];
  layoutVisible: boolean;
  appStatus: RequestStatus;
  translations: Record<string, string>;
  systemServices: SystemService[];
  mastertable: {
    core: CoreTableResponse | null;
    authorization: AuthorizationTableResponse | null;
  };
};

const initialState: State = {
  account: null,
  employees: [],
  layoutVisible: true,
  appStatus: RequestStatus.Idle,
  translations: {},
  systemServices: [],
  mastertable: {
    core: null,
    authorization: null,
  },
};

export const slice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setAccount: (state, action: PayloadAction<Employee>) => {
      return {
        ...state,
        account: action.payload,
      };
    },
    setAppStatus: (state, action: PayloadAction<RequestStatus>) => {
      return {
        ...state,
        appStatus: action.payload,
      };
    },
    setCoreMasterTableResponse: (
      state,
      action: PayloadAction<CoreTableResponse | null>
    ) => {
      return {
        ...state,
        mastertable: {
          ...state.mastertable,
          core: action.payload,
        },
      };
    },
    setAuthorizationMasterTableResponse: (
      state,
      action: PayloadAction<AuthorizationTableResponse | null>
    ) => {
      return {
        ...state,
        mastertable: {
          ...state.mastertable,
          authorization: action.payload,
        },
      };
    },
    setTranslations: (state, action: PayloadAction<Record<string, string>>) => {
      return {
        ...state,
        translations: action.payload,
      };
    },
    toggleLayoutVisible: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        layoutVisible: action.payload,
      };
    },
    setEmployees: (state, action: PayloadAction<RelationSnapShot[]>) => {
      return {
        ...state,
        employees: action.payload,
      };
    },
    setSystemServices: (state, action: PayloadAction<SystemService[]>) => {
      return {
        ...state,
        systemServices: action.payload,
      };
    },
  },
});
