import React, { FC } from "react";
import { IntlProvider } from "react-intl";

type Props = {
  locale: string | null | undefined;
  messages: Record<string, any> | null | undefined;
};

export const IntlComponent: FC<Props> = ({ children, locale, messages }) => {
  return (
    <IntlProvider locale={locale || "nl"} messages={messages || {}}>
      {children as any}
    </IntlProvider>
  );
};
