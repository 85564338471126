import {
  ActiveFilter,
  HelpdeskSearchClient,
  HelpdeskSearchType,
  SortOrder,
} from "@kolibri/search-api";
import { createThunk } from "helpers/store";
import { globalSearchActions } from ".";

type SearchArgs = {
  term: string;
  filterByTypes?: HelpdeskSearchType[];
  filterByActive: ActiveFilter;
};
const search = createThunk(
  "global-search/search",
  async ({ http, dispatch, getState, settings }, args: SearchArgs) => {
    try {
      const state = getState();
      const { accountSettings } = state.settings;
      const client = new HelpdeskSearchClient(settings?.search_api_url, http);

      dispatch(globalSearchActions.setSearchQuery(args.term));

      const response = await client.search(
        {
          term: args.term.trim(),
          takePerType: 5,
          filterByActive: args.filterByActive,
          filterByTypes: args.filterByTypes,
          order: SortOrder.Ascending,
          skipPerType: 0,
        },
        accountSettings?.realEstateAgencyId || ""
      );

      dispatch(globalSearchActions.setSearchResponse(response));
    } catch (error) {
      throw error;
    }
  }
);

type GetRemainingTypeArgs = {
  term: string;
  skip: number;
  filterByActive: ActiveFilter;
};

const getRemainingRealEstateAgencies = createThunk(
  "global-search/getRemainingRealEstateAgencies",
  async (
    { http, dispatch, handleError, settings, getState },
    args: GetRemainingTypeArgs
  ) => {
    try {
      const state = getState();
      const { accountSettings } = state.settings;
      const client = new HelpdeskSearchClient(settings?.search_api_url, http);
      const realEstateAgenciesLength =
        state.globalSearch.realEstateAgencies?.results?.length;
      const response = await client.search(
        {
          term: args.term.trim(),
          takePerType: 100,
          filterByActive: args.filterByActive,
          filterByTypes: [HelpdeskSearchType.RealEstateAgencies],
          order: SortOrder.Ascending,
          skipPerType: realEstateAgenciesLength || 0,
        },
        accountSettings?.realEstateAgencyId || ""
      );

      if (!response.realEstateAgencies) return;
      dispatch(
        globalSearchActions.setRealEstateAgencies({
          results: [
            ...(state.globalSearch.realEstateAgencies?.results || []),
            ...(response.realEstateAgencies.results || []),
          ],
          totalResults: response.realEstateAgencies.totalResults,
          resultCount: response.realEstateAgencies.resultCount,
        })
      );
    } catch (error) {
      handleError(error);
    }
  }
);

const getRemainingEmployees = createThunk(
  "global-search/getRemainingEmployees",
  async (
    { http, dispatch, getState, handleError, settings },
    args: GetRemainingTypeArgs
  ) => {
    try {
      const state = getState();
      const { accountSettings } = state.settings;
      const client = new HelpdeskSearchClient(settings?.search_api_url, http);
      const employeeLength = state.globalSearch.employees?.results?.length;

      const response = await client.search(
        {
          term: args.term.trim(),
          takePerType: 100,
          filterByActive: args.filterByActive,
          filterByTypes: [HelpdeskSearchType.Employees],
          order: SortOrder.Ascending,
          skipPerType: employeeLength || 0,
        },
        accountSettings?.realEstateAgencyId || ""
      );

      if (!response.employees) return;
      dispatch(
        globalSearchActions.setEmployees({
          results: [
            ...(state.globalSearch.employees?.results || []),
            ...(response.employees.results || []),
          ],
          totalResults: response.employees.totalResults,
          resultCount: response.employees.resultCount,
        })
      );
    } catch (error) {
      handleError(error);
    }
  }
);

const getRemainingAssignments = createThunk(
  "global-search/getRemainingAssignments",
  async (
    { http, dispatch, getState, handleError, settings },
    args: GetRemainingTypeArgs
  ) => {
    try {
      const state = getState();
      const { accountSettings } = state.settings;
      const client = new HelpdeskSearchClient(settings?.search_api_url, http);
      const assignmentLength = state.globalSearch.assignments?.results?.length;

      const response = await client.search(
        {
          term: args.term.trim(),
          takePerType: 100,
          filterByActive: args.filterByActive,
          filterByTypes: [HelpdeskSearchType.Assignments],
          order: SortOrder.Ascending,
          skipPerType: assignmentLength || 0,
        },
        accountSettings?.realEstateAgencyId || ""
      );

      if (!response.assignments) return;
      dispatch(
        globalSearchActions.setAssignments({
          results: [
            ...(state.globalSearch.assignments?.results || []),
            ...(response.assignments.results || []),
          ],
          totalResults: response.assignments.totalResults,
          resultCount: response.assignments.resultCount,
        })
      );
    } catch (error) {
      handleError(error);
    }
  }
);

type SearchEmployeesArgs = {
  term: string;
};
const searchEmployees = createThunk(
  "global-search/searchEmployees",
  async (
    { http, getState, handleError, settings },
    args: SearchEmployeesArgs
  ) => {
    try {
      const state = getState();
      const { accountSettings } = state.settings;
      const client = new HelpdeskSearchClient(settings?.search_api_url, http);

      return await client
        .search(
          {
            term: args.term.trim(),
            takePerType: 5,
            filterByActive: ActiveFilter.ActiveOrInactive,
            order: SortOrder.Ascending,
            filterByTypes: [HelpdeskSearchType.Employees],
            skipPerType: 0,
          },
          accountSettings?.realEstateAgencyId || ""
        )
        .then(response => response.employees?.results || []);
    } catch (error) {
      handleError(error);
    }
  }
);

const thunks = {
  search,
  getRemainingRealEstateAgencies,
  getRemainingEmployees,
  getRemainingAssignments,
  searchEmployees,
};
export default thunks;
