import { useEffect } from "react";
import { ListColumConfig } from ".";

export const useDevErrors = (
  config: ListColumConfig,
  numberOfFixedColumns?: number
) => {
  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      // Check config for faulty setup
      if (config.length === 0) {
        throw new Error("There should atleast be one column defined.");
      }

      const numberOfFilledColumns = config.filter(column => !!column.fill)
        .length;
      if (numberOfFilledColumns > 1) {
        throw new Error(
          'There can only be one column with it\'s width set to "fill".'
        );
      }

      if (!!numberOfFixedColumns && numberOfFixedColumns > config.length - 1) {
        throw new Error("There should atleast be 1 column that is not fixed.");
      }
    }
  }, [config, numberOfFixedColumns]);
};
