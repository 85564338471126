import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItem } from "@kolibri/ui";
import { useNavigate } from "hooks/navigate";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useRouteMatch } from "react-router-dom";
import styles from "./style.module.scss";

const FixedMenuItem = MenuItem as any;
type Props = {
  name: string;
  route: string;
  icon: IconDefinition;
};

export const MenuItemComponent: FC<Props> = ({ name, route, icon }) => {
  const match = useRouteMatch({
    path: route,
    exact: route === "/",
  });
  const navigate = useNavigate();

  return (
    <FixedMenuItem
      active={!!match}
      activeClassName={styles.active}
      icon={<FontAwesomeIcon icon={icon} className={styles.icon} fixedWidth />}
      onClick={() => navigate(route)}
      className={styles.link}
    >
      <FormattedMessage id={name} />
    </FixedMenuItem>
  );
};
