import { UserEntity } from "@kolibri/id-api";
import {
  createAction,
  createEntityAdapter,
  createSlice,
  Update,
} from "@reduxjs/toolkit";
import { EditableType } from "enums/editable-types";
import { editablesActions } from "store/editables";

export const add = createAction<UserEntity>("employees/user-entities/add");
export const update = createAction<Update<UserEntity>>(
  "employees/user-entities/update"
);

export const originalAdapter = createEntityAdapter<UserEntity>();
export const originalSlice = createSlice({
  name: "employees/user-entities/original",
  initialState: originalAdapter.getInitialState(),
  reducers: {
    add: originalAdapter.upsertOne,
    update: originalAdapter.updateOne,
  },
  extraReducers: builder => {
    builder.addCase(editablesActions.removeEditable, (state, action) => {
      const id = originalAdapter
        .getSelectors()
        .selectAll(state)
        .find(entity => entity.personId === action.payload.id)?.id;

      if (action.payload.type === EditableType.Employee && id) {
        originalAdapter.removeOne(state, id);
      }
    });
    builder.addCase(add, (state, action) => {
      originalAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(update, (state, action) => {
      originalAdapter.updateOne(state, action.payload);
    });
  },
});

export const editableAdapter = createEntityAdapter<UserEntity>();
export const editableSlice = createSlice({
  name: "employees/user-entities/editable",
  initialState: editableAdapter.getInitialState(),
  reducers: {
    add: editableAdapter.upsertOne,
    update: editableAdapter.updateOne,
  },
  extraReducers: builder => {
    builder.addCase(editablesActions.removeEditable, (state, action) => {
      const id = editableAdapter
        .getSelectors()
        .selectAll(state)
        .find(entity => entity.personId === action.payload.id)?.id;

      if (action.payload.type === EditableType.Employee && id) {
        editableAdapter.removeOne(state, id);
      }
    });
    builder.addCase(add, (state, action) => {
      editableAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(update, (state, action) => {
      editableAdapter.updateOne(state, action.payload);
    });
  },
});
