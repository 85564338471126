import {
  faSpaceStationMoonAlt,
  faStarfighter,
  faStarfighterAlt,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback } from "react";
import styles from "./style.module.scss";
import { httpWebUtil } from "store";

export const ErrorScreen: FC = () => {
  const onReloadCallback = useCallback(async () => {
    if (!!httpWebUtil) {
      await httpWebUtil.signout();
      return;
    }

    window.location.reload();
  }, []);

  return (
    <div className={styles.notFound}>
      <div className={styles.visual}>
        <FontAwesomeIcon
          icon={faSpaceStationMoonAlt as any}
          style={{ fontSize: 240 }}
        />
        <FontAwesomeIcon
          icon={faStarfighterAlt as any}
          style={{ fontSize: 36 }}
          className={styles.starFighter}
        />
      </div>
      <div className={styles.content}>
        <h1>Oops...</h1>
        <h2>
          Something has gone horribly wrong, please reload this page or contact
          our admins...
        </h2>
        <div className={styles.contentNavigation} onClick={onReloadCallback}>
          <div>
            <FontAwesomeIcon
              icon={faStarfighter as any}
              style={{ fontSize: 20 }}
              className={styles.navStarFighter}
            />
          </div>
          <div className={styles.link}>Clear cache and reload page</div>
        </div>
      </div>
    </div>
  );
};
