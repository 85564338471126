import classnames from "classnames";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Context from "../context";
import styles from "./style.module.scss";

export type Position = "top" | "right" | "bottom" | "left";

type Props = {
  parent: React.MutableRefObject<any>;
  className?: string;
  style?: React.CSSProperties;
  label?: string;
  position?: Position;
  offset?: number;
};

export const TooltipComponent: FC<Props> = ({
  parent,
  className,
  style,
  label,
  children,
  position,
  offset,
}) => {
  const hovering = useRef(false);
  const [visible, setVisible] = useState(false);
  const shouldRender = !!label || !!children;

  const onMouseEnterCallback = useCallback(() => {
    hovering.current = true;
    setTimeout(() => {
      if (!!hovering.current) {
        setVisible(true);
      }
    }, 250);
  }, [setVisible]);

  const onMouseLeaveCallback = useCallback(() => {
    hovering.current = false;
    setVisible(false);
  }, [setVisible]);

  useEffect(() => {
    const ref = parent.current;

    if (shouldRender && typeof ref?.addEventListener === "function") {
      ref.addEventListener("mouseenter", onMouseEnterCallback);
      ref.addEventListener("mouseleave", onMouseLeaveCallback);
    }

    return () => {
      if (shouldRender && typeof ref?.removeEventListener === "function") {
        ref.removeEventListener("mouseenter", onMouseEnterCallback);
        ref.removeEventListener("mouseleave", onMouseLeaveCallback);
      }
    };
  }, [parent, shouldRender, onMouseEnterCallback, onMouseLeaveCallback]);

  const vOffset = useMemo(() => {
    switch (position) {
      case "top": {
        return -(offset || 10);
      }
      case "bottom": {
        return -(offset || 10);
      }
      case "left":
      case "right": {
        return 0;
      }
      default: {
        return 0;
      }
    }
  }, [position, offset]);

  const hOffset = useMemo(() => {
    switch (position) {
      case "right": {
        return offset || 10;
      }
      case "left": {
        return -(offset || 10);
      }
      default: {
        return 0;
      }
    }
  }, [position, offset]);

  const attachOn: any = useMemo(() => {
    switch (position) {
      case "right": {
        return "centerright";
      }
      case "bottom": {
        return "bottomcenter";
      }
      case "left": {
        return "centerleft";
      }
      default: {
        return "topcenter";
      }
    }
  }, [position]);

  const expandFrom: any = useMemo(() => {
    switch (position) {
      case "right": {
        return "centerleft";
      }
      case "bottom": {
        return "topcenter";
      }
      case "left": {
        return "centerright";
      }
      default: {
        return "bottomcenter";
      }
    }
  }, [position]);

  return !shouldRender ? null : (
    <Context
      parent={parent}
      visible={visible}
      onClose={() => setVisible(false)}
      attachOn={attachOn}
      expandFrom={expandFrom}
      hOffset={hOffset}
      vOffset={vOffset}
    >
      <div
        className={classnames(
          styles.tooltip,
          styles[position || "top"],
          className
        )}
        style={style}
      >
        {label || children}
      </div>
    </Context>
  );
};
