import {
  EmailAddressType,
  Office,
  OfficesClient,
  PhoneNumberType,
} from "@kolibri/core-api";
import {
  ActiveFilter,
  RelationOrderByField,
  RelationsClient,
  RelationType,
  SortOrder,
} from "@kolibri/search-api";
import { createThunk } from "helpers/store";
import { batch } from "react-redux";
import {
  actions as realEstateAgenciesActions,
  selectors as realEstateAgenciesSelectors,
} from "../";

const fetchMainOffice = createThunk(
  "fetchMainOffice",
  async (
    { http, dispatch, handleError, settings },
    realEstateAgencyId: string
  ) => {
    try {
      const relationsClient = new RelationsClient(
        settings?.search_api_url,
        http
      );
      const officesClient = new OfficesClient(settings?.core_api_url, http);

      const linkedOffices = await relationsClient
        .search(
          {
            includeStatistics: false,
            includeBackOfficeEmployees: false,
            includeArchivedEmployees: true,
            orderBy: RelationOrderByField.DisplayName,
            filterByActive: ActiveFilter.ActiveOnly,
            order: SortOrder.Ascending,
            skip: 0,
            take: 100,
            filterByRelationTypes: [RelationType.Office],
          },
          realEstateAgencyId
        )
        .then(response => response.results || []);
      const mainLinkedOffice = linkedOffices.find(
        office => office.isMainOffice
      );

      if (!!mainLinkedOffice) {
        const mainOffice = await officesClient
          .read(mainLinkedOffice.id, realEstateAgencyId)
          .then(response => response?.office);

        if (!!mainOffice) {
          dispatch(realEstateAgenciesActions.mainOffices.add(mainOffice));
        }
      }
      return;
    } catch (error) {
      handleError(error);
    }
  }
);

type CreateMainOfficeArgs = {
  realEstateAgencyId: string;
  mainOffice?: Office | null;
};
const createMainOffice = createThunk(
  "createMainOffice",
  async (
    { http, dispatch, getState, handleError, settings },
    args: CreateMainOfficeArgs
  ) => {
    try {
      const { realEstateAgencyId, mainOffice } = args;
      const relationsClient = new RelationsClient(
        settings?.search_api_url,
        http
      );
      const officesClient = new OfficesClient(settings?.core_api_url, http);

      const linkedOffices = await relationsClient
        .search(
          {
            includeStatistics: false,
            includeBackOfficeEmployees: false,
            includeArchivedEmployees: true,
            orderBy: RelationOrderByField.DisplayName,
            filterByActive: ActiveFilter.ActiveOnly,
            order: SortOrder.Ascending,
            skip: 0,
            take: 100,
            filterByRelationTypes: [RelationType.Office],
          },
          realEstateAgencyId
        )
        .then(response => response.results || []);
      const mainLinkedOffice = linkedOffices.find(
        office => office.isMainOffice
      );

      let office = !mainLinkedOffice
        ? await officesClient
            .defineNew({}, realEstateAgencyId)
            .then(response => response?.office)
        : await officesClient
            .read(mainLinkedOffice.id, realEstateAgencyId)
            .then(response => response?.office);

      if (!!office) {
        if (!!mainOffice) {
          const {
            id,
            dateTimeCreated,
            dateTimeModified,
            isNew,
            ...args
          } = mainOffice;
          let { phoneNumbers, emailAddresses } = args;

          phoneNumbers = (phoneNumbers || []).map(phoneNumber => ({
            ...phoneNumber,
            type: PhoneNumberType.Work,
          }));
          emailAddresses = (emailAddresses || []).map(emailAddress => ({
            ...emailAddress,
            type: EmailAddressType.Work,
          }));

          office = {
            ...office,
            ...args,
            phoneNumbers,
            emailAddresses,
          };
        }

        office = { ...office, isMainOffice: true };

        const response = await officesClient
          .save({ office }, realEstateAgencyId)
          .then(response => response?.office);

        if (!!response) {
          const state = getState();
          const mainOffices = realEstateAgenciesSelectors.mainOffices.editable.selectAll(
            state
          );
          const ids = mainOffices
            .filter(
              mainOffice => mainOffice.realEstateAgencyId === realEstateAgencyId
            )
            .map(mainOffice => mainOffice.id);

          batch(() => {
            dispatch(realEstateAgenciesActions.mainOffices.remove(ids));
            dispatch(realEstateAgenciesActions.mainOffices.add(response));
          });
        }
      }

      return;
    } catch (error) {
      handleError(error);
    }
  }
);

const thunks = { fetchMainOffice, createMainOffice };
export default thunks;
