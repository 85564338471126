import {
  AssignmentSnapShot,
  HelpdeskSearchAssignmentsSearchResult,
  HelpdeskSearchEmployeesSearchResult,
  HelpdeskSearchRealEstateAgenciesSearchResult,
  HelpdeskSearchSearchResponse,
  RealEstateAgencySnapShot,
  RelationSnapShot,
} from "@kolibri/search-api";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type State = {
  query: string;
  assignments: HelpdeskSearchAssignmentsSearchResult | null;
  employees: HelpdeskSearchEmployeesSearchResult | null;
  realEstateAgencies: HelpdeskSearchRealEstateAgenciesSearchResult | null;
};

const initialState: State = {
  query: "",
  assignments: null,
  employees: null,
  realEstateAgencies: null,
};

export const slice = createSlice({
  name: "global-search",
  initialState,
  reducers: {
    setSearchQuery: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        query: action.payload || "",
      };
    },
    setSearchResponse: (
      state,
      action: PayloadAction<HelpdeskSearchSearchResponse | null>
    ) => {
      return {
        ...state,
        assignments: action.payload?.assignments || null,
        employees: action.payload?.employees || null,
        realEstateAgencies: action.payload?.realEstateAgencies || null,
      };
    },
    appendAssignments: (state, action: PayloadAction<AssignmentSnapShot[]>) => {
      return {
        ...state,
        assignments: {
          ...(state.assignments || {
            results: [],
            resultCount: 0,
            totalResults: 0,
          }),
          results: [...(state.assignments?.results || []), ...action.payload],
        },
      };
    },
    setAssignments: (
      state,
      action: PayloadAction<{
        results: AssignmentSnapShot[];
        totalResults: number;
        resultCount: number;
      }>
    ) => {
      return {
        ...state,
        assignments: action.payload,
      };
    },
    appendEmployees: (state, action: PayloadAction<RelationSnapShot[]>) => {
      return {
        ...state,
        employees: {
          ...(state.employees || {
            results: [],
            resultCount: 0,
            totalResults: 0,
          }),
          results: [...(state.employees?.results || []), ...action.payload],
        },
      };
    },
    setEmployees: (
      state,
      action: PayloadAction<{
        results: RelationSnapShot[];
        resultCount: number;
        totalResults: number;
      }>
    ) => {
      return { ...state, employees: action.payload };
    },
    appendRealEstateAgencies: (
      state,
      action: PayloadAction<RealEstateAgencySnapShot[]>
    ) => {
      return {
        ...state,
        realEstateAgencies: {
          ...(state.realEstateAgencies || {
            results: [],
            resultCount: 0,
            totalResults: 0,
          }),
          results: [
            ...(state.realEstateAgencies?.results || []),
            ...action.payload,
          ],
        },
      };
    },
    setRealEstateAgencies: (
      state,
      action: PayloadAction<{
        results: RealEstateAgencySnapShot[];
        resultCount: number;
        totalResults: number;
      }>
    ) => {
      return {
        ...state,
        realEstateAgencies: action.payload,
      };
    },
  },
});
