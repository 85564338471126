import { User, UserManagerSettings } from "oidc-client";

class MockUserManager {
  private _settings: UserManagerSettings;

  constructor(settings: UserManagerSettings) {
    this._settings = settings;

    this.signinRedirect = this.signinRedirect.bind(this);
    this.getUser = this.getUser.bind(this);
  }

  async getUser(): Promise<User> {
    const cookieString = document.cookie;
    let expired = false;

    if (cookieString) {
      const override = cookieString
        .split("; ")
        .find(cookie => cookie.startsWith("userManagerReturnUser="))
        ?.split("=")?.[1];

      if (override === "false") {
        expired = true;
      }
    }

    return {
      id_token: "",
      access_token: "access_token",
      token_type: "",
      scope: "",
      profile: { iss: "", sub: "", aud: "", exp: 0, iat: 0 },
      expires_at: 0,
      state: "",
      toStorageString: () => "",
      expires_in: 0,
      expired,
      scopes: [],
    };
  }

  async signinSilent() {
    return this.getUser();
  }

  async signinRedirect() {
    window.open(
      `${window.location.origin}/callback` || this._settings.redirect_uri,
      "_self"
    );
  }

  async signinRedirectCallback() {
    return this.getUser();
  }

  async signinSilentCallback() {}

  async removeUser() {}

  async signoutRedirect() {}
}

export default MockUserManager;
