import classnames from "classnames";
import React, { forwardRef } from "react";
import { Theme } from "../../types/common";
import styles from "./style.module.scss";

type Props = {
  label?: string;
  theme?: Theme;
  disabled?: boolean;
  type?: "submit" | "button" | "reset";
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  ref?: React.MutableRefObject<HTMLButtonElement>;
  "data-cy"?: string;
};

export const ButtonComponent = forwardRef<HTMLButtonElement | null, Props>(
  (
    {
      children,
      label,
      theme,
      disabled,
      type,
      style,
      className,
      "data-cy": dataCy,
      onClick,
    },
    ref
  ) => {
    return (
      <button
        className={classnames(
          styles.button,
          { [styles[theme || "flat"]]: !!theme || !className },
          className
        )}
        disabled={disabled}
        onClick={onClick}
        type={type || "button"}
        style={style}
        ref={ref}
        data-cy={dataCy}
      >
        <div className={styles.label}>{label || children}</div>
      </button>
    );
  }
);
