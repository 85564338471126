import React, { FC } from "react";
import Link from "../menu-item";
import styles from "./style.module.scss";
import {
  faTachometer,
  faHandsHelping,
  faBuilding,
  faLayerGroup,
  faBoxes,
  faChartNetwork,
  faNetworkWired,
  faEraser,
} from "@fortawesome/pro-regular-svg-icons";
import {
  API_RESOURCES,
  APP_CLIENTS,
  HELPDESK,
  MLS_APP_CLIENTS,
  MLS_GROUPS,
  MLS_REALESTATEAGENCIES,
  REALESTATEAGENCIES,
} from "constants/routes";

export const MainMenuComponent: FC = () => {
  return (
    <div className={styles.menu}>
      <Link
        name="mainMenuItem.dashboard"
        route="/"
        icon={faTachometer as any}
      />
      <Link
        name="mainMenuItem.helpdesk"
        route={HELPDESK.root}
        icon={faHandsHelping as any}
      />
      <Link
        name="mainMenuItem.appClients"
        route={APP_CLIENTS.root}
        icon={faChartNetwork as any}
      />
      <Link
        name="mainMenuItem.apiResources"
        route={API_RESOURCES.root}
        icon={faBoxes as any}
      />
      <Link
        name="mainMenuItem.mlsAppClients"
        route={MLS_APP_CLIENTS.root}
        icon={faNetworkWired as any}
      />
      <Link
        name="mainMenuItem.mlsGroups"
        route={MLS_GROUPS.root}
        icon={faLayerGroup as any}
      />
      <Link
        name="mainMenuItem.mlsRealEstateAgencies"
        route={MLS_REALESTATEAGENCIES.root}
        icon={faBuilding as any}
      />
      <Link
        name="mainMenuItem.deletedRealEstateAgencies"
        route={REALESTATEAGENCIES.deleted}
        icon={faEraser as any}
      />
    </div>
  );
};
