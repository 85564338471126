import {
  AccountSettingsClient,
  CompanySettingsClient,
} from "@kolibri/core-api";
import { unwrapResult } from "@reduxjs/toolkit";
import { createThunk } from "helpers/store";
import { settingsActions } from ".";

const getInitialSettings = createThunk(
  "getInitialSettings",
  async ({ dispatch, handleError }) => {
    try {
      await dispatch(getAccountSettings())
        .then(unwrapResult)
        .catch(error => {
          throw error;
        });
      await dispatch(getCompanySettings())
        .then(unwrapResult)
        .catch(error => {
          throw error;
        });
      await dispatch(getGroupSettings())
        .then(unwrapResult)
        .catch(error => {
          throw error;
        });
    } catch (error) {
      handleError(error);
    }
  }
);

const getAccountSettings = createThunk(
  "getAccountSettings",
  async ({ http, dispatch, userId, handleError, settings }) => {
    try {
      const client = new AccountSettingsClient(settings?.core_api_url, http);
      const personId = await userId();

      const response = await client
        .readByEmployeeId({ personId })
        .then(response => response?.accountSettings);

      if (!response) return;
      dispatch(settingsActions.setAccountSettings(response));
    } catch (error) {
      handleError(error);
    }
  }
);

const getCompanySettings = createThunk(
  "getCompanySettings",
  async ({ http, dispatch, getState, handleError, settings }) => {
    try {
      const state = getState();
      const { accountSettings } = state.settings;
      const client = new CompanySettingsClient(settings?.core_api_url, http);

      const response = await client
        .read(accountSettings?.realEstateAgencyId || "")
        .then(response => response?.companySettings);

      if (!response) return;
      dispatch(settingsActions.setCompanySettings(response));
    } catch (error) {
      handleError(error);
    }
  }
);

const getGroupSettings = createThunk(
  "getGroupSettings",
  async ({ http, dispatch, getState, handleError, settings }) => {
    try {
      const state = getState();
      const { accountSettings } = state.settings;
      const client = new CompanySettingsClient(settings?.core_api_url, http);

      const response = await client
        .getAllGroupSettings(accountSettings?.realEstateAgencyId || "")
        .then(response => response?.groupSettings || []);

      if (!response) return;
      dispatch(settingsActions.setGroupSettings(response));
    } catch (error) {
      handleError(error);
    }
  }
);

const openKolibri = createThunk(
  "openKolibri",
  async ({ settings, handleError }) => {
    try {
      if (!settings?.kolibri_postback_url) {
        throw new Error("Kolibri postback url is not defined.");
      }

      window.open(settings.kolibri_postback_url);
    } catch (error) {
      handleError(error);
    }
  }
);

const thunks = { getInitialSettings, openKolibri };
export default thunks;
