import { combineReducers } from "redux";
import { PersistConfig, persistReducer } from "redux-persist";
import { RootState } from "store";
import {
  editableSlice,
  editableAdapter,
  originalSlice,
  originalAdapter,
  add,
  update,
} from "./slices";
import permissionResourcesThunks from "./thunks";
import storage from "redux-persist/es/storage/session";
import { EditablePermissionResourceShape } from "..";

const combinedReducers = combineReducers({
  editable: editableSlice.reducer,
  original: originalSlice.reducer,
});

const persistConfig: PersistConfig<ReturnType<typeof combinedReducers>> = {
  storage,
  key: "k24/api-resources/resource-entities",
};

export const actions = {
  editable: editableSlice.actions,
  original: originalSlice.actions,
  add,
  update,
};
export const reducer = persistReducer(persistConfig, combinedReducers);
export const selectors = {
  editable: editableAdapter.getSelectors<RootState>(
    state => state.apiResources.permissionResources.editable
  ),
  original: originalAdapter.getSelectors<RootState>(
    state => state.apiResources.permissionResources.original
  ),
  originalShape: (state: RootState, id: string) => {
    const permissionResource = originalAdapter
      .getSelectors<RootState>(
        state => state.apiResources.permissionResources.original
      )
      .selectById(state, id);
    return {
      permissionResource: permissionResource || null,
    } as EditablePermissionResourceShape;
  },
  editableShape: (state: RootState, id: string) => {
    const permissionResource = originalAdapter
      .getSelectors<RootState>(
        state => state.apiResources.permissionResources.editable
      )
      .selectById(state, id);
    return {
      permissionResource: permissionResource || null,
    } as EditablePermissionResourceShape;
  },
};
export const thunks = permissionResourcesThunks;
