import { RealtorClient } from "@kolibri/connect-proxy-api";
import { createThunk } from "helpers/store";
import {
  actions as realEstateAgenciesActions,
  selectors as realEstateAgenciesSelectors,
} from "../";

type FetchRealtorArgs = {
  realEstateAgencyId: string;
  clearTemporaryIds?: boolean;
};
const fetchAndAddRealtor = createThunk(
  "fetchRealtor",
  async ({ http, dispatch, getState, handleError, settings }, args: FetchRealtorArgs) => {
    try {
      const { realEstateAgencyId, clearTemporaryIds } = args;
      const client = new RealtorClient(settings?.connect_proxy_api_url, http);

      if (!!clearTemporaryIds) {
        const state = getState();
        const realtors = realEstateAgenciesSelectors.realtors.original.selectAll(
          state
        );
        const ids = realtors
          .filter(realtor => realtor.foreignId === realEstateAgencyId)
          .map(realtor => realtor.realtorId);

        dispatch(realEstateAgenciesActions.realtors.remove(ids));
      }

      const realtor = await client
        .read(realEstateAgencyId)
        .then(response => response.realtor);
      if (!realtor) {
        throw Error("Couldn't find realtor");
      }

      dispatch(realEstateAgenciesActions.realtors.add(realtor));

      return realtor;
    } catch (error) {
      handleError(error);
    }
  }
);

const thunks = { fetchAndAddRealtor };
export default thunks;
