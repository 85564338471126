import { faClipboard, faCopy } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import copy from "copy-to-clipboard";
import React, { FC, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import Tooltip from "../tooltip";
import { Position } from "../tooltip/tooltip";
import styles from "./style.module.scss";

type Props = {
  value: string | null | undefined;
  withIcon?: boolean;
  iconPosition?: "prepend" | "append";
  tooltipPosition?: Position;
  style?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  className?: string;
  copiedClassName?: string;
  tooltipOffset?: number;
  showHover?: boolean;
};

export const CopyComponent: FC<Props> = ({
  children,
  value,
  className,
  copiedClassName,
  style,
  iconStyle,
  withIcon,
  iconPosition,
  tooltipPosition,
  tooltipOffset,
  showHover,
}) => {
  const intl = useIntl();
  const ref = useRef<HTMLDivElement | null>(null);
  const [copied, setCopied] = useState(false);
  const copiedClassNameKey = copied
    ? copiedClassName || styles.copied
    : undefined;

  const onCopyCallback = (event: React.MouseEvent<HTMLDivElement>) => {
    if (value === null || value === undefined) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    if (copy(value, { format: "text/plain" })) {
      setCopied(true);
      setTimeout(() => setCopied(false), 1500);
    }
  };

  const label = useMemo(() => {
    return intl.formatMessage({
      id: copied ? "kolibri.ui.common.copied" : "kolibri.ui.common.clickToCopy",
      defaultMessage: copied ? "Copied!" : "Click to copy",
    });
  }, [copied, intl]);

  return value === null || value === undefined ? (
    <>{children}</>
  ) : (
    <>
      <div
        onClick={onCopyCallback}
        className={classnames(
          styles.copy,
          styles[iconPosition || "append"],
          { [styles.showHover]: showHover },
          className
        )}
        style={style}
        ref={ref}
      >
        <div className={classnames(!!withIcon && styles.inner)}>{children}</div>
        {!!withIcon && (
          <div className={styles.icon} style={iconStyle}>
            <FontAwesomeIcon icon={faClipboard as any} />
          </div>
        )}
      </div>
      <Tooltip
        parent={ref}
        className={copiedClassNameKey}
        position={tooltipPosition || "right"}
        offset={tooltipOffset || undefined}
      >
        <div className={classnames(styles.tooltip)}>
          <div className={styles.tooltip__icon}>
            <FontAwesomeIcon icon={faCopy as any} />
          </div>
          <div className={styles.tooltip__label}>{label}</div>
        </div>
      </Tooltip>
    </>
  );
};
