import { AssignmentSnapShot } from "@kolibri/search-api";
import { Button, HighlightString, Pill } from "@kolibri/ui";
import { useRealEstateAgency } from "hooks/real-estate-agency";
import React, { FC, MouseEventHandler } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./style.module.scss";
import classNames from "classnames";
import { useDispatch } from "hooks/dispatch";
import { thunks as assignmentsThunks } from "store/assignments";

type Props = {
  item: AssignmentSnapShot;
  query?: string;
  onClick: (item: AssignmentSnapShot) => void;
};

export const AssignmentItemComponent: FC<Props> = ({
  item,
  query,
  onClick,
}) => {
  const dispatch = useDispatch();
  const realEstateAgency = useRealEstateAgency(item.realEstateAgencyId);
  const displayName = item.displayName || "";

  const openInKolibri: MouseEventHandler = event => {
    event.stopPropagation();
    dispatch(
      assignmentsThunks.assignments.openObjectInKolibri({
        assignmentId: item?.id || "",
        realEstateAgencyId: item.realEstateAgencyId,
      })
    );
  };

  return (
    <div className={styles.result__item} onClick={() => onClick(item)}>
      {!item.isActive && (
        <div className={styles.pill}>
          <Pill>
            <FormattedMessage id="common.archived" />
          </Pill>
        </div>
      )}

      {!!item.publicReference && (
        <div className={styles.pill}>
          <Pill className={styles.pill__pubref}>{item.publicReference}</Pill>
        </div>
      )}

      <div className={styles.pill}>
        <Pill className={styles.pill__rentOrSale}>
          <FormattedMessage
            id={`helpdesk.pill.${!!item.forSale ? "forSale" : "forRent"}`}
          />
        </Pill>
      </div>

      {!!item.typeOfAssignment && (
        <div className={styles.pill}>
          <Pill className={styles.pill__type}>
            <FormattedMessage
              id={`assignmentTypes.${item.typeOfAssignment.toString()}`}
            />
          </Pill>
        </div>
      )}

      {!!displayName && (
        <div className={styles.label}>
          <HighlightString value={displayName} match={(query || "").trim()} />
        </div>
      )}

      {!!realEstateAgency?.name && (
        <div className={styles.pill}>
          <Pill label={realEstateAgency.name} highlightLabel query={query} />
        </div>
      )}

      <Button
        theme="success"
        onClick={openInKolibri}
        className={classNames(styles.pill, styles.openButton)}
      >
        <FormattedMessage id={"list.realestate-objects.openInKolibri"} />
      </Button>
    </div>
  );
};
