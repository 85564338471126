import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Update,
} from "@reduxjs/toolkit";
import { Editable } from ".";

export const adapter = createEntityAdapter<Editable>();

export const slice = createSlice({
  name: "editables",
  initialState: adapter.getInitialState(),
  reducers: {
    addEditable: adapter.upsertOne,
    updateEditable: adapter.updateOne,
    removeEditable: (state, action: PayloadAction<Editable>) => {
      const matchingReferrers = adapter
        .getSelectors()
        .selectAll(state)
        .filter(entity => entity.referrer === action.payload.currentPath)
        .map(
          entity =>
            ({
              id: entity.id,
              changes: { ...entity, referrer: action.payload.referrer },
            } as Update<Editable>)
        );

      if (!!matchingReferrers.length) {
        adapter.updateMany(state, matchingReferrers);
      }
      adapter.removeOne(state, action.payload.id);
    },
    reset: (state, action: PayloadAction<Editable>) => {
      adapter.updateOne(state, {
        id: action.payload.id,
        changes: { hasChanges: false, hasError: false },
      });
    },
    handleRouteChange: (
      state,
      action: PayloadAction<{ path: string; referrer: string }>
    ) => {
      const entities = adapter.getSelectors().selectAll(state);
      const updatingCurrentPath = !!entities.find(
        state =>
          action.payload.referrer.includes(state.rootPath) &&
          action.payload.path.includes(state.rootPath)
      );

      const updatedEntities = entities
        .filter(entity => action.payload.path.includes(entity.rootPath))
        .map(
          entity =>
            ({
              id: entity.id,
              changes: {
                currentPath: action.payload.path,
              },
            } as Update<Editable>)
        );

      const updatedReferredEntities = entities
        .filter(
          entity =>
            updatingCurrentPath && entity.referrer === action.payload.referrer
        )
        .map(
          entity =>
            ({
              id: entity.id,
              changes: {
                referrer: action.payload.path,
              },
            } as Update<Editable>)
        );

      const updates = [...updatedEntities, ...updatedReferredEntities];
      if (!!updates.length) {
        adapter.updateMany(state, updates);
      }
    },
  },
});
