import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import Context from "../../../context";
import styles from "../../style.module.scss";

type Props = {
  index?: number;
  style?: React.CSSProperties;
  actionsLabel?: (index: number) => string | null;
  actions?: (index: number, close: () => void) => JSX.Element | null;
  height: number;
  onActionClick?: (index: number) => void;
};

export const ActionsComponent: FC<Props> = ({
  index,
  style,
  height,
  actions,
  actionsLabel,
  onActionClick,
}) => {
  const [contextVisible, setContextVisible] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const intl = useIntl();
  const label = useMemo(
    () =>
      index !== undefined
        ? actionsLabel?.(index) ||
          intl.formatMessage({
            id: "kolibri.ui.common.list.actionsLabel",
            defaultMessage: "Actions",
          })
        : intl.formatMessage({
            id: "kolibri.ui.common.list.actionsLabel",
            defaultMessage: "Actions",
          }),
    [index, actionsLabel, intl]
  );

  const toggleContextVisible = useCallback(() => {
    setContextVisible(contextVisible => !contextVisible);
  }, []);

  const onButtonClickHandler = useCallback(() => {
    if (!!actions) {
      toggleContextVisible();
      return;
    }

    if (!!onActionClick && index !== undefined) {
      onActionClick(index);
      setContextVisible(false);
    }
  }, [index, actions, onActionClick, toggleContextVisible, setContextVisible]);

  useEffect(() => {
    setContextVisible(false);
  }, [index]);

  return !style ||
    index === undefined ||
    (!!actionsLabel && !actionsLabel(index)) ||
    (!!actions && !actions(index, () => setContextVisible(false))) ? null : (
    <>
      <div className={styles.rowActions} style={style}>
        <button
          type="button"
          style={{ height }}
          onClick={onButtonClickHandler}
          ref={buttonRef}
        >
          {label}
        </button>
      </div>
      {!!actions && (
        <Context
          parent={buttonRef}
          visible={contextVisible}
          onClose={() => setContextVisible(false)}
          attachOn="center"
          expandFrom="calculate"
        >
          {actions(index, () => setContextVisible(false))}
        </Context>
      )}
    </>
  );
};
