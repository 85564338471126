import { useHistory, useLocation } from "react-router-dom";
import { editablesActions } from "store/editables";
import { useDispatch } from "./dispatch";

export const useNavigate = () => {
  const { push } = useHistory();
  const { pathname: referrer } = useLocation();
  const dispatch = useDispatch();

  return (path?: string | null) => {
    if (!path || path === referrer) return;
    push(path, { referrer });
    dispatch(editablesActions.handleRouteChange({ path, referrer }));
  };
};
