import { RequestStatus } from "enums/request-status";
import { useCurrentCulture } from "hooks/culture";
import { useDispatch } from "hooks/dispatch";
import { useSelector } from "hooks/selector";
import { useCallback, useEffect, useRef } from "react";
import { mainThunks, mainActions } from "store/main";
import { httpWebUtil } from "store";
import { AppSettings } from "@kolibri/utils";

type Response = {
  appStatus: RequestStatus;
  locale: string;
  messages: Record<string, string>;
  settings: AppSettings | null | undefined;
};

export const useContainer = (): Response => {
  const appStatus = useSelector(state => state.main.appStatus);
  const messages = useSelector(state => state.main.translations);
  const settings = httpWebUtil.settings();
  const culture = useCurrentCulture();
  const dispatch = useDispatch();
  const initialized = useRef(false);
  const locale = culture.split(/[-_]/)[0] || "nl";

  const initial = useCallback(async () => {
    if (!!initialized.current || appStatus === RequestStatus.Success) return;
    initialized.current = true;

    const data = await httpWebUtil.getDecodedToken();
    if (!!data?.orgpid) {
      dispatch(mainActions.setAppStatus(RequestStatus.AccountOnBehalfOf));
    } else {
      dispatch(mainThunks.getInitialAppState());
    }
  }, [dispatch, appStatus]);

  useEffect(() => {
    initial();
  }, [initial]);

  return {
    appStatus,
    locale,
    messages,
    settings,
  };
};
