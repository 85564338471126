import classnames from "classnames";
import { useField } from "formik";
import React, { FC, useCallback } from "react";
import fieldStyles from "../../style.module.scss";
import styles from "./style.module.scss";

type Props = {
  value: any;
  style?: React.CSSProperties;
  className?: string;
  name: string;
  hint?: string;
  label?: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
};

export const RadioComponent: FC<Props> = ({
  value,
  name,
  hint,
  style,
  className,
  label,
  disabled,
  children,
  onChange,
  onFocus,
  onBlur,
}) => {
  const [
    { onChange: formikOnchange, onBlur: formikOnBlur, ...field },
    meta,
  ] = useField(name);

  const onChangeCallback = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      formikOnchange(event);
      !!onChange && onChange(event);
    },
    [onChange, formikOnchange]
  );

  const onBlurCallback = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      formikOnBlur(event);
      !!onBlur && onBlur(event);
    },
    [onBlur, formikOnBlur]
  );

  style = style || { width: undefined };
  const { width, ...inputStyle } = style;
  const checked = value === meta.value;

  return (
    <div style={{ width }}>
      <label className={classnames(styles.label, className)}>
        <input
          {...field}
          type="radio"
          value={value}
          data-lpignore={true}
          className={styles.radio}
          style={inputStyle}
          disabled={disabled}
          onChange={onChangeCallback}
          onFocus={onFocus}
          onBlur={onBlurCallback}
        />
        <div
          className={classnames(styles.icon, { [styles.checked]: checked })}
        />
        <div className={styles.innerLabel}>{label || children}</div>
      </label>
      {!(meta.touched && meta.error) && hint && (
        <div className={classnames(fieldStyles.hint, styles.hint)}>{hint}</div>
      )}
    </div>
  );
};
