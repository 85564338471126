import classnames from "classnames";
import React, { FC, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Editable, editablesActions } from "store/editables";
import styles from "./style.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTimes } from "@fortawesome/pro-regular-svg-icons";
import {
  faUser,
  faFolderOpen,
  faBuilding,
  faQuestion,
  faBoxFull,
  faChartNetwork,
  faEraser,
} from "@fortawesome/pro-solid-svg-icons";
import { EditableType } from "enums/editable-types";
import { useDispatch } from "hooks/dispatch";
import { useNavigate } from "hooks/navigate";

type Props = {
  editable: Editable;
};

export const TabComponent: FC<Props> = ({ editable }) => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    rootPath,
    type,
    currentPath,
    hasChanges,
    referrer,
    hasError,
    title,
    isSaving,
  } = editable;
  const isActive =
    pathname.length >= rootPath.length &&
    new RegExp(rootPath, "gi").test(pathname);

  const icon = useMemo(() => {
    switch (type) {
      case EditableType.Employee: {
        return faUser;
      }
      case EditableType.Assignment: {
        return faFolderOpen;
      }
      case EditableType.RealEstateAgency: {
        return faBuilding;
      }
      case EditableType.PermissionResource: {
        return faBoxFull;
      }
      case EditableType.AppClient: {
        return faChartNetwork;
      }
      case EditableType.DeleteJob: {
        return faEraser;
      }
      default: {
        return faQuestion;
      }
    }
  }, [type]);

  const navigateCallback = () => {
    if (isActive) return;
    navigate(currentPath);
  };

  const onCloseCallback = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!!hasChanges || !!isSaving) return;
    event.stopPropagation();
    event.preventDefault();
    if (isActive) {
      push(referrer);
    }
    dispatch(editablesActions.removeEditable(editable));
  };

  return (
    <div
      className={classnames(styles.tab, {
        [styles.active]: isActive,
        [styles.hasError]: hasError,
      })}
      onClick={navigateCallback}
    >
      {!!hasChanges && <div className={styles.hasChanges} />}
      <div className={styles.typeIcon}>
        <FontAwesomeIcon icon={icon as any} fixedWidth />
      </div>
      <div className={styles.label}>{title}</div>
      <div className={styles.close} onClick={onCloseCallback}>
        <FontAwesomeIcon
          icon={isSaving ? faSpinner : (faTimes as any)}
          spin={isSaving}
        />
      </div>
    </div>
  );
};
