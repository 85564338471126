import { Realtor } from "@kolibri/connect-proxy-api";
import { CompanySettings, Office, RealEstateAgency } from "@kolibri/core-api";
import { RealEstateAgency as MlsRealEstateAgency } from "@kolibri/mls-api";
import { combineReducers } from "redux";
import { RootState } from "store";
import {
  cacheActions,
  cacheReducer,
  cacheSelectors,
  cacheThunks,
} from "./cache";
import {
  actions as companySettingsActions,
  reducer as companySettingsReducer,
  selectors as companySettingsSelectors,
  thunks as companySettingsThunks,
} from "./company-settings";
import {
  actions as mainOfficesActions,
  reducer as mainOfficesReducer,
  selectors as mainOfficesSelectors,
  thunks as mainOfficesThunks,
} from "./main-offices";
import {
  actions as mlsRealEstateAgenciesActions,
  reducer as mlsRealEstateAgenciesReducer,
  selectors as mlsRealEstateAgenciesSelectors,
  thunks as mlsRealEstateAgenciesThunks,
} from "./mls-real-estate-agencies";
import {
  actions as realEstateAgenciesActions,
  reducer as realEstateAgenciesReducer,
  selectors as realEstateAgenciesSelectors,
  thunks as realEstateAgenciesThunks,
} from "./real-estate-agencies";
import {
  actions as realtorsActions,
  reducer as realtorsReducer,
  selectors as realtorsSelectors,
  thunks as realtorsThunks,
} from "./realtors";
import {
  actions as deleteJobsActions,
  reducer as deleteJobsReducer,
  selectors as deleteJobsSelectors,
  thunks as deleteJobsThunks,
} from "./delete-jobs";
import requests from "./thunks";

export const reducer = combineReducers({
  cache: cacheReducer,
  realEstateAgencies: realEstateAgenciesReducer,
  realtors: realtorsReducer,
  companySettings: companySettingsReducer,
  mainOffices: mainOfficesReducer,
  mlsRealEstateAgencies: mlsRealEstateAgenciesReducer,
  deleteJobs: deleteJobsReducer,
});

export const actions = {
  cache: cacheActions,
  realEstateAgencies: realEstateAgenciesActions,
  realtors: realtorsActions,
  companySettings: companySettingsActions,
  mainOffices: mainOfficesActions,
  mlsRealEstateAgencies: mlsRealEstateAgenciesActions,
  deleteJobs: deleteJobsActions,
};

export const thunks = {
  cache: cacheThunks,
  realEstateAgencies: realEstateAgenciesThunks,
  realtors: realtorsThunks,
  companySettings: companySettingsThunks,
  mainOffices: mainOfficesThunks,
  mlsRealEstateAgencies: mlsRealEstateAgenciesThunks,
  deleteJobs: deleteJobsThunks,
  requests,
};

export const selectors = {
  realEstateAgencies: realEstateAgenciesSelectors,
  realtors: realtorsSelectors,
  companySettings: companySettingsSelectors,
  mainOffices: mainOfficesSelectors,
  mlsRealEstateAgencies: mlsRealEstateAgenciesSelectors,
  deleteJobs: deleteJobsSelectors,
  cache: cacheSelectors,
  originalShape: (state: RootState, id: string) => {
    const realEstateAgency = realEstateAgenciesSelectors.original.selectById(
      state,
      id
    );
    const realtor = realtorsSelectors.original
      .selectAll(state)
      .find(realtor => realtor.foreignId === id);
    const companySettings = companySettingsSelectors.original
      .selectAll(state)
      .find(companySetting => companySetting.realEstateAgencyId === id);
    const mainOffice = mainOfficesSelectors.original
      .selectAll(state)
      .find(mainOffice => mainOffice.realEstateAgencyId === id);
    const mlsRealEstateAgency =
      mlsRealEstateAgenciesSelectors.original.selectById(state, id);

    return {
      realEstateAgency: realEstateAgency || null,
      realtor: realtor || null,
      companySettings: companySettings || null,
      mainOffice: mainOffice || null,
      mlsRealEstateAgency: mlsRealEstateAgency || null,
    } as EditableRealEstateAgencyShape;
  },
  editableShape: (state: RootState, id: string) => {
    const realEstateAgency = realEstateAgenciesSelectors.editable.selectById(
      state,
      id
    );
    const realtor = realtorsSelectors.editable
      .selectAll(state)
      .find(realtor => realtor.foreignId === id);
    const companySettings = companySettingsSelectors.editable
      .selectAll(state)
      .find(companySetting => companySetting.realEstateAgencyId === id);
    const mainOffice = mainOfficesSelectors.editable
      .selectAll(state)
      .find(mainOffice => mainOffice.realEstateAgencyId === id);
    const mlsRealEstateAgency =
      mlsRealEstateAgenciesSelectors.editable.selectById(state, id);

    return {
      realEstateAgency: realEstateAgency || null,
      realtor: realtor || null,
      companySettings: companySettings || null,
      mainOffice: mainOffice || null,
      mlsRealEstateAgency: mlsRealEstateAgency || null,
    } as EditableRealEstateAgencyShape;
  },
};

export type EditableRealEstateAgencyShape = {
  realEstateAgency: RealEstateAgency | null;
  realtor: Realtor | null;
  companySettings: CompanySettings | null;
  mainOffice: Office | null;
  mlsRealEstateAgency: MlsRealEstateAgency | null;
};
