import { SystemServiceClient } from "@kolibri/admin-api";
import { MasterTableClient as AuthorizationMasterTableClient } from "@kolibri/authorization-api";
import {
  ActiveFilter,
  DocumentTemplateGroupClient,
  DocumentTemplateGroupSnapShot,
  EmployeesClient,
  MasterTableClient as CoreMasterTableClient,
  SortOrder,
} from "@kolibri/core-api";
import {
  ActiveFilter as SearchActiveFilter,
  RelationOrderByField,
  RelationsClient,
  RelationSnapShot,
  SearchRelationRequest,
  SortOrder as SearchSortOrder,
} from "@kolibri/search-api";
import { unwrapResult } from "@reduxjs/toolkit";
import { RequestStatus } from "enums/request-status";
import { createThunk } from "helpers/store";
import {
  mapAuthorizationTableResponseToTranslation,
  mapCoreTableResponseToTranslation,
} from "mappers/mastertable";
import { batch } from "react-redux";
import { thunks as realEstateAgenciesThunks } from "store/real-estate-agencies";
import { settingsThunks } from "store/settings";
import { slice } from "./slice";

const getInitialAppState = createThunk(
  "getInitialAppState",
  async ({ dispatch }) => {
    try {
      dispatch(slice.actions.setAppStatus(RequestStatus.Pending));

      await dispatch(settingsThunks.getInitialSettings())
        .then(unwrapResult)
        .catch(error => {
          throw error;
        });
      await dispatch(getMainAccount())
        .then(unwrapResult)
        .catch(error => {
          throw error;
        });
      await dispatch(getMasterTablesAndTranslations())
        .then(unwrapResult)
        .catch(error => {
          throw error;
        });
      dispatch(realEstateAgenciesThunks.cache.fetchAllRealEstateAgencies());
      dispatch(fetchAllEmployees());
      dispatch(fetchAllSystemServices());

      dispatch(slice.actions.setAppStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(slice.actions.setAppStatus(RequestStatus.Failed));
      throw error;
    }
  }
);

const getMainAccount = createThunk(
  "getMainAccount",
  async ({ dispatch, http, userId, getState, settings }) => {
    try {
      const client = new EmployeesClient(settings?.core_api_url, http);
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";

      const id = await userId();
      const employee = await client
        .read(id, realEstateAgencyId)
        .then(response => response?.employee);
      if (!employee) {
        throw new Error("Main account for employee could not be found");
      }

      dispatch(slice.actions.setAccount(employee));
      return;
    } catch (error) {
      throw error;
    }
  }
);

const getMasterTablesAndTranslations = createThunk(
  "getMasterTablesAndTranslations",
  async ({ http, dispatch, getState, settings }) => {
    try {
      const state = getState();
      const { accountSettings } = state.settings;
      const defaultCulture = accountSettings?.defaultCulture || "nl-NL";
      const coreClient = new CoreMasterTableClient(
        settings?.core_api_url,
        http
      );
      const authorizationClient = new AuthorizationMasterTableClient(
        settings?.authorization_api_url,
        http
      );

      const coreTableResponse = await coreClient.index(
        defaultCulture,
        accountSettings?.realEstateAgencyId || ""
      );
      const authorizationTableResponse = await authorizationClient.index(
        defaultCulture,
        accountSettings?.realEstateAgencyId || ""
      );

      const coreTranslations =
        mapCoreTableResponseToTranslation(coreTableResponse);
      const autorizationTranslations =
        mapAuthorizationTableResponseToTranslation(authorizationTableResponse);

      const translations = await fetch(
        `${process.env.PUBLIC_URL}/translations/${
          defaultCulture.split(/[-_]/)[0] || "nl"
        }.json`,
        {
          cache: "no-cache",
        }
      ).then(response => response.json());

      batch(() => {
        dispatch(slice.actions.setCoreMasterTableResponse(coreTableResponse));
        dispatch(
          slice.actions.setAuthorizationMasterTableResponse(
            authorizationTableResponse
          )
        );
        dispatch(
          slice.actions.setTranslations({
            ...autorizationTranslations,
            ...coreTranslations,
            ...translations,
          })
        );
      });
      return;
    } catch (error) {
      throw error;
    }
  }
);

const fetchDocumentTemplateGroups = createThunk(
  "fetchDocumentTemplateGroups",
  async ({ http, settings }, realEstateAgencyId: string) => {
    try {
      const client = new DocumentTemplateGroupClient(
        settings?.core_api_url,
        http
      );

      return await client
        .search(
          {
            skip: 0,
            take: 100,
            filterByActive: ActiveFilter.ActiveOnly,
            order: SortOrder.Ascending,
          },
          realEstateAgencyId
        )
        .then(response => response?.results || []);
    } catch (error) {
      throw error;
    }
  }
);

type UpdateDocumentTemplateGroupArgs = {
  realEstateAgencyId: string;
  groupId: string;
  group?: DocumentTemplateGroupSnapShot | null;
};
const toggleDocumentTemplateGroup = createThunk(
  "toggleDocumentTemplateGroup",
  async ({ http, settings }, args: UpdateDocumentTemplateGroupArgs) => {
    try {
      const client = new DocumentTemplateGroupClient(
        settings?.core_api_url,
        http
      );

      if (!!args.group?.id) {
        await client.delete(args.group.id, args.realEstateAgencyId);
      } else {
        const documentTemplateGroup = await client
          .defineNew({ groupId: args.groupId }, args.realEstateAgencyId)
          .then(response => response?.documentTemplateGroup);

        if (!!documentTemplateGroup) {
          await client.save({ documentTemplateGroup }, args.realEstateAgencyId);
        }
      }

      return;
    } catch (error) {
      throw error;
    }
  }
);

const fetchAllEmployees = createThunk(
  "fetchAllEmployees",
  async ({ http, dispatch, handleError, getState, settings }) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new RelationsClient(settings?.search_api_url, http);
      const take = 100;

      let employees: RelationSnapShot[];
      let request: SearchRelationRequest = {
        includeStatistics: false,
        includeBackOfficeEmployees: true,
        includeArchivedEmployees: true,
        orderBy: RelationOrderByField.DisplayName,
        filterByActive: SearchActiveFilter.ActiveOrInactive,
        order: SearchSortOrder.Ascending,
        skip: 0,
        take,
      };

      const response = await client.search(request, realEstateAgencyId);
      if (!response) return;

      const { totalResults, resultCount, results } = response;
      employees = results || [];

      if (totalResults > take) {
        const laps = Math.ceil((totalResults - resultCount) / take);
        for (let i = 0; i < laps; i++) {
          request = { ...request, skip: (i + 1) * take };
          const items = await client
            .search(request, realEstateAgencyId)
            .then(response => response.results || []);
          employees = [...employees, ...items];
        }
      }

      dispatch(slice.actions.setEmployees(employees));
    } catch (error) {
      handleError(error);
    }
  }
);

const fetchAllSystemServices = createThunk(
  "fetchAllSystemServices",
  async ({ http, settings, handleError, dispatch }) => {
    try {
      const client = new SystemServiceClient(settings?.admin_api_url, http);

      const services = await client
        .search({ skip: 0, take: 100 })
        .then(response => response.result || []);

      dispatch(slice.actions.setSystemServices(services));
    } catch (error) {
      handleError(error);
    }
  }
);

const thunks = {
  getInitialAppState,
  fetchDocumentTemplateGroups,
  toggleDocumentTemplateGroup,
};
export default thunks;
