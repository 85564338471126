import { PersistConfig, persistReducer } from "redux-persist";
import { slice, adapter } from "./slice";
import storage from "redux-persist/es/storage/session";
import { EditableType } from "enums/editable-types";
import { RootState } from "store";

export type Editable = {
  id: string;
  type: EditableType;
  rootPath: string;
  currentPath: string;
  referrer: string;
  hasChanges: boolean;
  hasError: boolean;
  title: string;
  isSaving: boolean;
};

const persistConfig: PersistConfig<ReturnType<typeof slice.reducer>> = {
  storage,
  key: "k24/editable",
};

export const editablesReducer = persistReducer(persistConfig, slice.reducer);
export const editablesActions = slice.actions;
export const editablesSelector = adapter.getSelectors<RootState>(
  state => state.editable
);
