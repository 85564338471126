import { createThunk } from "helpers/store";
import {
  ApiResourceClient,
  ApiResourceOrderByField,
  ArchiveFilter,
  SortOrder,
} from "@kolibri/authorization-api";

const fetchApiResources = createThunk(
  "fetchApiResources",
  async ({ http, handleError, getState, settings }, skip: number) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new ApiResourceClient(
        settings?.authorization_api_url,
        http
      );

      const response = await client.search(
        {
          filterByArchived: ArchiveFilter.NotArchivedOnly,
          order: SortOrder.Ascending,
          orderBy: ApiResourceOrderByField.Default,
          skip,
          take: 100,
        },
        realEstateAgencyId
      );

      if (!response) {
        throw new Error("Could not fetch app clients");
      }

      const { totalResults, results } = response;

      return {
        totalResults,
        results: results || [],
      };
    } catch (error) {
      handleError(error);
    }
  }
);

const thunks = { fetchApiResources };
export default thunks;
