import {
  ActiveFilter,
  RealEstateAgencyClient,
  RealEstateAgencyOrderByField,
  RealEstateAgencySnapShot,
  SearchRealEstateAgencyRequest,
  SortOrder,
} from "@kolibri/search-api";
import { createThunk } from "helpers/store";

type SearchBackOfficeAccountsArgs = {
  employeeId: string;
  orderBy: RealEstateAgencyOrderByField;
  order: SortOrder;
};
const searchBackOfficeAccounts = createThunk(
  "searchBackOfficeAccounts",
  async (
    { http, handleError, settings },
    args: SearchBackOfficeAccountsArgs
  ) => {
    try {
      const client = new RealEstateAgencyClient(settings?.search_api_url, http);
      const take = 100;
      const { order, orderBy, employeeId } = args;

      let backofficeAccounts: RealEstateAgencySnapShot[];
      let request: SearchRealEstateAgencyRequest = {
        orderBy,
        filterByActive: ActiveFilter.ActiveOrInactive,
        order,
        skip: 0,
        take,
        employeeId,
      };

      const response = await client.search(request);

      if (!response) {
        throw new Error("Could not fetch realestate agencies");
      }

      const { resultCount, results, totalResults } = response;
      backofficeAccounts = results || [];

      if (totalResults > take) {
        const laps = Math.ceil((totalResults - resultCount) / take);
        for (let i = 0; i < laps; i++) {
          request = { ...request, skip: (i + 1) * take };
          const items = await client
            .search(request)
            .then(response => response.results || []);
          backofficeAccounts = [...backofficeAccounts, ...items];
        }
      }

      return backofficeAccounts;
    } catch (error) {
      handleError(error);
    }
  }
);

const thunks = { searchBackOfficeAccounts };
export default thunks;
