import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import styles from "./style.module.scss";

type Props = {
  title: string;
  subTitle?: string;
  icon?: IconDefinition;
};

export const EmptyStateComponent: FC<Props> = ({
  title,
  subTitle,
  icon,
  children,
}) => {
  return (
    <div className={styles.emptyState}>
      {!!icon && (
        <div className={styles.emptyState__icon}>
          <FontAwesomeIcon icon={icon as any} />
        </div>
      )}
      <div className={styles.emptyState__title}>{title}</div>
      {!!subTitle && (
        <div className={styles.emptyState__subTitle}>{subTitle}</div>
      )}
      {!!children && (
        <div className={styles.emptyState__actions}>{children}</div>
      )}
    </div>
  );
};
