import { Office } from "@kolibri/core-api";
import {
  createAction,
  createEntityAdapter,
  createSlice,
  EntityId,
  Update,
} from "@reduxjs/toolkit";
import { EditableType } from "enums/editable-types";
import { editablesActions } from "store/editables";

export const add = createAction<Office>(
  "real-estate-agencies/main-offices/add"
);
export const update = createAction<Update<Office>>(
  "real-estate-agencies/main-offices/update"
);
export const remove = createAction<EntityId[]>(
  "real-estate-agencies/main-offices/remove"
);

export const originalAdapter = createEntityAdapter<Office>();
export const originalSlice = createSlice({
  name: "real-estate-agencies/main-offices/original",
  initialState: originalAdapter.getInitialState(),
  reducers: {
    add: originalAdapter.upsertOne,
    update: originalAdapter.updateOne,
  },
  extraReducers: builder => {
    builder.addCase(editablesActions.removeEditable, (state, action) => {
      const id = originalAdapter
        .getSelectors()
        .selectAll(state)
        .find(entity => entity.realEstateAgencyId === action.payload.id)?.id;

      if (action.payload.type === EditableType.RealEstateAgency && id) {
        originalAdapter.removeOne(state, id);
      }
    });
    builder.addCase(add, (state, action) => {
      originalAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(update, (state, action) => {
      originalAdapter.updateOne(state, action.payload);
    });
    builder.addCase(remove, (state, action) => {
      originalAdapter.removeMany(state, action.payload);
    });
  },
});

export const editableAdapter = createEntityAdapter<Office>();
export const editableSlice = createSlice({
  name: "real-estate-agencies/main-offices/editable",
  initialState: editableAdapter.getInitialState(),
  reducers: {
    add: editableAdapter.upsertOne,
    update: editableAdapter.updateOne,
  },
  extraReducers: builder => {
    builder.addCase(editablesActions.removeEditable, (state, action) => {
      const id = editableAdapter
        .getSelectors()
        .selectAll(state)
        .find(entity => entity.realEstateAgencyId === action.payload.id)?.id;

      if (action.payload.type === EditableType.RealEstateAgency && id) {
        editableAdapter.removeOne(state, id);
      }
    });
    builder.addCase(add, (state, action) => {
      editableAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(update, (state, action) => {
      editableAdapter.updateOne(state, action.payload);
    });
    builder.addCase(remove, (state, action) => {
      editableAdapter.removeMany(state, action.payload);
    });
  },
});
