import {
  AssignmentSnapShot,
  HelpdeskSearchType,
  RealEstateAgencySnapShot,
  RelationSnapShot,
} from "@kolibri/search-api";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./style.module.scss";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearchPlus } from "@fortawesome/pro-light-svg-icons";

type Props = {
  visible: boolean;
  type: HelpdeskSearchType;
  totalCount: number;
  remainingCount: number;
  values:
    | RealEstateAgencySnapShot[]
    | RelationSnapShot[]
    | AssignmentSnapShot[];
  children: (type: any, key: number) => React.ReactElement;
  onDefineNew?: (type: HelpdeskSearchType) => void;
  onLoadMore: (type: HelpdeskSearchType) => void;
};

export const SectionComponent: FC<Props> = ({
  children,
  totalCount,
  remainingCount,
  values,
  type,
  visible,
  onDefineNew,
  onLoadMore,
}) => {
  return !visible ? null : (
    <div className={styles.result__section}>
      <div className={styles.result__title}>
        <FormattedMessage
          id={`helpdesk.search.${type}.title`}
          defaultMessage=""
          values={{ count: totalCount }}
        />
      </div>
      {!!values.length && (
        <div className={styles.result__items}>
          {(values as any).map(children)}
        </div>
      )}

      {!!remainingCount && (
        <div
          className={styles.result__action}
          onClick={() => !!onLoadMore && onLoadMore(type)}
        >
          <div className={classnames(styles.action__icon, styles.secondary)}>
            <FontAwesomeIcon icon={faSearchPlus as any} />
          </div>
          <div className={styles.action__label}>
            <FormattedMessage
              id={`helpdesk.search.${type}.showMore`}
              defaultMessage=""
              values={
                {
                  count: remainingCount,
                  strong: (chunks: string) => <strong>{chunks}</strong>,
                } as any
              }
            />
          </div>
        </div>
      )}

      {type !== HelpdeskSearchType.Assignments && (
        <div
          className={styles.result__action}
          onClick={() => !!onDefineNew && onDefineNew(type)}
        >
          <div className={classnames(styles.action__icon, styles.success)}>
            <FontAwesomeIcon icon={faPlus as any} />
          </div>
          <div className={styles.action__label}>
            <FormattedMessage
              id={`helpdesk.search.${type}.add`}
              defaultMessage=""
            />
          </div>
        </div>
      )}
    </div>
  );
};
