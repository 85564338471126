import { faPlus, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { useField } from "formik";
import React, { FC, useCallback, useState } from "react";
import fieldStyles from "../../style.module.scss";
import styles from "./style.module.scss";

export enum FileError {
  FileSizeToLarge = "FileSizeToLarge",
  InvalidFileFormat = "InvalidFileFormat",
}

type Props = {
  style?: React.CSSProperties;
  className?: string;
  name: string;
  hint?: string;
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  labelPlacement?: "top" | "right" | "left";
  labelClassName?: string;
  multiple?: boolean;
  showFileList?: boolean;
  showPreview?: boolean;
  resize?: { maxWidth?: number; maxHeight?: number };
  onError?: (error: FileError) => void;
  whitelist?: string[];
  blacklist?: string[];
  showResetButton?: boolean;
};

export const FileComponent: FC<Props> = ({
  name,
  style,
  className,
  hint,
  labelClassName,
  labelPlacement,
  label,
  multiple,
  showFileList,
  showResetButton,
  placeholder,
  whitelist,
  blacklist,
  onError,
  children,
}) => {
  const [field, meta, { setValue }] = useField(name);
  const [files, setFiles] = useState<File[]>(field.value || []);
  const [entered, setEntered] = useState(0);

  const processFiles = useCallback(
    (fileList?: FileList | null) => {
      if (!fileList) {
        return;
      }

      let combinedFiles: File[] = [];
      const receivedFiles: File[] = [];
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList.item(i);
        if (!!file) {
          receivedFiles.push(file);
        }
      }

      if (showFileList) {
        combinedFiles = [...receivedFiles, ...files];
      } else {
        combinedFiles = receivedFiles;
      }

      if (!multiple && !!combinedFiles.length) {
        combinedFiles = [combinedFiles[0]];
      }

      if (!!whitelist?.length) {
        const count = combinedFiles.length;
        combinedFiles = combinedFiles.filter(file =>
          whitelist.includes(
            file.name.substring(
              file.name.lastIndexOf(".") + 1,
              file.name.length
            )
          )
        );
        if (count > combinedFiles.length && !!onError) {
          onError(FileError.InvalidFileFormat);
        }
      }

      if (!!blacklist?.length) {
        const count = combinedFiles.length;
        combinedFiles = combinedFiles.filter(
          file =>
            !blacklist.includes(
              file.name.substring(
                file.name.lastIndexOf(".") + 1,
                file.name.length
              )
            )
        );
        if (count > combinedFiles.length && !!onError) {
          onError(FileError.InvalidFileFormat);
        }
      }

      setValue(combinedFiles);
      setFiles(combinedFiles);
      setEntered(0);
    },
    [multiple, files, whitelist, blacklist, onError, setValue, showFileList]
  );

  const onDropCallback = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.stopPropagation();
      event.preventDefault();

      processFiles(event.dataTransfer.files);
    },
    [processFiles]
  );

  const onDragOverCallback = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.stopPropagation();
      event.preventDefault();
    },
    []
  );

  const onDragEnterCallback = useCallback(() => {
    setEntered(entered => entered + 1);
  }, []);

  const onDragLeaveCallback = useCallback(() => {
    setEntered(entered => (entered <= 0 ? 0 : entered - 1));
  }, []);

  const onChangeCallback = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
      event.preventDefault();
      processFiles(event.target.files);
    },
    [processFiles]
  );

  const onRemoveFileCallback = useCallback(
    (idx: number) => {
      const filteredFiles = files.filter((_, index) => index !== idx);
      setFiles(filteredFiles);
      setValue(filteredFiles);
    },
    [setValue, files]
  );

  const onClearListCallBack = useCallback(() => {
    setFiles([]);
    setValue([]);
  }, [setValue, setFiles]);

  return (
    <div className={styles.wrapper}>
      <div
        className={classnames(
          fieldStyles.wrapper,
          styles[`label__${labelPlacement || "top"}`]
        )}
      >
        {!!label && (
          <label
            htmlFor={field.name}
            className={classnames(
              styles.label,
              styles[labelPlacement || "top"],
              labelClassName
            )}
          >
            {label}
          </label>
        )}
        <div
          className={classnames(styles.inputContainer, className)}
          style={style}
        >
          <div
            className={styles.dropArea}
            onDrop={onDropCallback}
            onDragOver={onDragOverCallback}
            onDragEnter={onDragEnterCallback}
            onDragLeave={onDragLeaveCallback}
          >
            <label>
              <input
                type="file"
                name={field.name}
                id={field.name}
                multiple={multiple}
                className={styles.input}
                onChange={onChangeCallback}
              />
            </label>
            {!!placeholder && !entered && !children && (
              <div className={styles.placeholder}>{placeholder}</div>
            )}
            {!!children && <div className={styles.children}>{children}</div>}
            {!!entered && (
              <div className={styles.drop}>
                <FontAwesomeIcon icon={faPlus as any} />
              </div>
            )}
            {!!showResetButton && !!files.length && (
              <div className={styles.removeButtonContainer}>
                <button
                  className={styles.removeButton}
                  onClick={onClearListCallBack}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0ZM14.207 12.793L12.793 14.207L10 11.414L7.207 14.207L5.793 12.793L8.586 10L5.793 7.207L7.207 5.793L10 8.586L12.793 5.793L14.207 7.207L11.414 10L14.207 12.793Z"
                      fill="#CE2929"
                    />
                  </svg>
                </button>
              </div>
            )}
          </div>
          {meta.touched && meta.error && (
            <div className={fieldStyles.error}>{meta.error}</div>
          )}
          {!(meta.touched && meta.error) && hint && (
            <div className={fieldStyles.hint}>{hint}</div>
          )}
          {!!showFileList && !!files.length && (
            <div className={styles.fileList}>
              {files.map((file, index) => (
                <div key={index} className={styles.fileList__item}>
                  <div className={styles.filename}>{file.name}</div>
                  <div
                    className={styles.fileaction}
                    onClick={() => onRemoveFileCallback(index)}
                  >
                    <FontAwesomeIcon icon={faTimes as any} />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
