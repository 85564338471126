import { faEyeDropper } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { useField } from "formik";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { ColorState, SketchPicker } from "react-color";
import styles from "./style.module.scss";

type Props = {
  style?: React.CSSProperties;
  className?: string;
  name: string;
  hint?: string;
  disabled?: boolean;
  label?: string;
  labelPlacement?: "top" | "right" | "left";
  labelClassName?: string;
};

export const ColorComponent: FC<Props> = ({
  name,
  className,
  style,
  label,
  labelPlacement,
  labelClassName,
  hint,
  disabled,
}) => {
  const [{ value, ...field }, { touched, error }, { setValue, setTouched }] =
    useField(name);
  const [visible, setVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const onChangeCallback = useCallback(
    (colorState: ColorState) => {
      setValue(colorState.hex);
    },
    [setValue]
  );

  const onFocusCallback = useCallback(() => {
    if (!disabled) {
      setVisible(true);
    }
  }, [disabled]);

  const onClickOutside = useCallback((event: MouseEvent) => {
    if (!ref.current?.contains(event.target as Node)) {
      setVisible(false);
      setTouched(true);
    }
  }, []);

  useEffect(() => {
    if (visible) {
      document.addEventListener("click", onClickOutside, true);
    }

    return () => {
      document.removeEventListener("click", onClickOutside, true);
    };
  }, [visible, onClickOutside]);

  return (
    <div
      className={classnames(
        styles.wrapper,
        styles[`label__${labelPlacement || "top"}`]
      )}
      ref={ref}
    >
      {(label || (touched && error) || hint) && (
        <div
          className={classnames(
            styles.labelWrapper,
            styles[labelPlacement || "top"],
            labelClassName
          )}
        >
          {!!label && (
            <label htmlFor={field.name} onClick={onFocusCallback}>
              {label}
            </label>
          )}

          {touched && error && <div className={styles.error}>{error}</div>}
          {!(touched && error) && hint && (
            <div className={styles.hint}>{hint}</div>
          )}
        </div>
      )}

      <div className={styles.triggerWrapper}>
        <div
          onClick={onFocusCallback}
          className={classnames(styles.trigger, className)}
          style={style}
        >
          <div className={styles.visual} style={{ backgroundColor: value }} />
          {!value && (
            <div className={styles.icon}>
              <FontAwesomeIcon icon={faEyeDropper as any} />
            </div>
          )}
        </div>
        {!!visible && (
          <div className={styles.picker}>
            <SketchPicker
              onChange={onChangeCallback}
              color={value}
              disableAlpha={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};
