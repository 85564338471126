import {
  AvailabilityStatus,
  ObjectAssignment,
  ObjectAssignmentsClient,
  UpdateAvailabilityAction,
  WithdrawReason,
} from "@kolibri/core-api";
import { unwrapResult } from "@reduxjs/toolkit";
import { createThunk } from "helpers/store";
import moment from "moment";
import { Toast, actions as toastsActions } from "store/toasts";
import { v4 as uuid } from "uuid";
import { thunks as cadastresThunks } from "../cadastres";
import { thunks as publicationsThunks } from "../publications";
import { slice } from "./slices";
import { mapRouteParams } from "mappers/route";
import { URLSearchParams } from "url";

type FetchArgs = { id: string; realEstateAgencyId: string };
const fetchAssignment = createThunk(
  "fetchAssignment",
  async (
    { http, dispatch, handleError, settings },
    args: FetchArgs
  ): Promise<ObjectAssignment | undefined> => {
    try {
      const client = new ObjectAssignmentsClient(settings?.core_api_url, http);
      const { id, realEstateAgencyId } = args;

      const objectAssignment = await client
        .read(id, realEstateAgencyId)
        .then(response => response?.objectAssignment);

      if (!objectAssignment) {
        throw Error("Couldn't find object assignment");
      }

      dispatch(slice.actions.add(objectAssignment));

      await dispatch(
        publicationsThunks.fetchPublications({
          assignmentId: objectAssignment.id,
          realEstateAgencyId,
        })
      )
        .then(unwrapResult)
        .catch(error => {
          throw error;
        });

      await dispatch(
        cadastresThunks.fetchCadastres({
          objectAssignmentId: objectAssignment.id,
          realEstateAgencyId,
        })
      )
        .then(unwrapResult)
        .catch(error => {
          throw error;
        });

      return objectAssignment;
    } catch (error) {
      handleError(error);
    }
  }
);

type WithdrawArgs = { id: string; realEstateAgencyId: string };
const withdrawAssignment = createThunk(
  "withdrawAssignment",
  async (
    { http, dispatch, intl, handleError, settings },
    args: WithdrawArgs
  ) => {
    try {
      const client = new ObjectAssignmentsClient(settings?.core_api_url, http);
      const { id, realEstateAgencyId } = args;

      const objectAssignment = await client
        .updateAvailability(
          {
            id,
            updateAvailabilityAction: UpdateAvailabilityAction.ToWithdrawn,
            withdrawnSettings: {
              withdrawnDateTime: moment().subtract(5, "minutes").toDate(),
              withdrawReason: WithdrawReason.AssignmentTerminatedByRealtor,
            },
          },
          realEstateAgencyId
        )
        .then(response => response?.objectAssignment);

      if (!objectAssignment) {
        throw Error("Couldn't update object assignment");
      }

      dispatch(
        slice.actions.update({
          id: objectAssignment.id,
          changes: objectAssignment,
        })
      );

      if (
        objectAssignment.availabilityStatus === AvailabilityStatus.Withdrawn
      ) {
        const toast: Toast = {
          id: uuid(),
          message: intl.formatMessage({ id: "toast.assignment.withdrawn" }),
          removeAfter: 3000,
        };
        dispatch(toastsActions.addToast(toast));

        await dispatch(
          publicationsThunks.fetchPublications({
            assignmentId: objectAssignment.id,
            realEstateAgencyId,
          })
        )
          .then(unwrapResult)
          .catch(error => {
            throw error;
          });
      }

      return;
    } catch (error) {
      handleError(error);
    }
  }
);

type UnArchiveArgs = { id: string; realEstateAgencyId: string };
const unArchiveAssignment = createThunk(
  "unArchiveAssignment",
  async (
    { http, dispatch, intl, handleError, settings },
    args: UnArchiveArgs
  ) => {
    try {
      const client = new ObjectAssignmentsClient(settings?.core_api_url, http);
      const { id, realEstateAgencyId } = args;

      await client.unarchive({ id }, realEstateAgencyId);

      const objectAssignment = await client
        .read(id, realEstateAgencyId)
        .then(response => response?.objectAssignment);

      if (!objectAssignment) {
        throw Error("Couldn't update object assignment");
      }

      dispatch(
        slice.actions.update({
          id: objectAssignment.id,
          changes: objectAssignment,
        })
      );

      if (!!objectAssignment.isActive) {
        const toast: Toast = {
          id: uuid(),
          message: intl.formatMessage({ id: "toast.assignment.unArchived" }),
          removeAfter: 3000,
        };
        dispatch(toastsActions.addToast(toast));

        await dispatch(
          publicationsThunks.fetchPublications({
            assignmentId: objectAssignment.id,
            realEstateAgencyId,
          })
        )
          .then(unwrapResult)
          .catch(error => {
            throw error;
          });
      }

      return;
    } catch (error) {
      handleError(error);
    }
  }
);

type ArchiveArgs = { id: string; realEstateAgencyId: string };
const archiveAssignment = createThunk(
  "archiveAssignment",
  async (
    { http, dispatch, intl, handleError, settings },
    args: ArchiveArgs
  ) => {
    try {
      const client = new ObjectAssignmentsClient(settings?.core_api_url, http);
      const { id, realEstateAgencyId } = args;

      await client.archive({ id }, realEstateAgencyId);

      const objectAssignment = await client
        .read(id, realEstateAgencyId)
        .then(response => response?.objectAssignment);

      if (!objectAssignment) {
        throw Error("Couldn't update object assignment");
      }

      dispatch(
        slice.actions.update({
          id: objectAssignment.id,
          changes: objectAssignment,
        })
      );

      if (!objectAssignment.isActive) {
        const toast: Toast = {
          id: uuid(),
          message: intl.formatMessage({ id: "toast.assignment.archived" }),
          removeAfter: 3000,
        };
        dispatch(toastsActions.addToast(toast));

        await dispatch(
          publicationsThunks.fetchPublications({
            assignmentId: objectAssignment.id,
            realEstateAgencyId,
          })
        )
          .then(unwrapResult)
          .catch(error => {
            throw error;
          });
      }

      return;
    } catch (error) {
      handleError(error);
    }
  }
);

type FetchAndSaveArgs = { id: string; realEstateAgencyId: string };
const fetchAndSaveAssignment = createThunk(
  "fetchAssignment",
  async (
    { http, intl, dispatch, handleError, settings },
    args: FetchAndSaveArgs
  ) => {
    try {
      const client = new ObjectAssignmentsClient(settings?.core_api_url, http);
      const { id, realEstateAgencyId } = args;

      const objectAssignment = await client
        .read(id, realEstateAgencyId)
        .then(response => response?.objectAssignment);

      if (!objectAssignment) {
        throw Error("Couldn't find object assignment");
      }

      const savedAssignment = await client
        .save({ objectAssignment }, realEstateAgencyId)
        .then(response => response?.objectAssignment);
      if (!savedAssignment) {
        throw Error("Couldn't save object assignment");
      }

      const toast: Toast = {
        id: uuid(),
        message: intl.formatMessage({ id: "toast.assignment.updated" }),
        removeAfter: 3000,
      };
      dispatch(toastsActions.addToast(toast));
      dispatch(
        slice.actions.update({
          id: savedAssignment.id,
          changes: savedAssignment,
        })
      );

      return savedAssignment;
    } catch (error) {
      handleError(error);
    }
  }
);

type NavigateToPublicationArgs = {
  realEstateAgencyId: string;
  assignmentId: string;
};
const openObjectInKolibri = createThunk(
  "openObjectInKolibri",
  async (
    { settings },
    { realEstateAgencyId, assignmentId }: NavigateToPublicationArgs
  ) => {
    if (!settings?.kolibri_postback_url) {
      throw new Error("Kolibri postback url is not defined.");
    }

    const path = `${settings.kolibri_postback_url}/app/assignments/${assignmentId}?forRealEstateAgencyId=${realEstateAgencyId}`;

    window.open(path);
  }
);

const thunks = {
  fetchAssignment,
  withdrawAssignment,
  unArchiveAssignment,
  archiveAssignment,
  fetchAndSaveAssignment,
  openObjectInKolibri,
};
export default thunks;
