import { RealEstateAgencySnapShot } from "@kolibri/search-api";
import { HighlightString, Pill } from "@kolibri/ui";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./style.module.scss";

type Props = {
  item: RealEstateAgencySnapShot;
  query?: string;
  onClick: (item: RealEstateAgencySnapShot) => void;
};

export const RealEstateAgencyItemComponent: FC<Props> = ({
  item,
  query,
  onClick,
}) => {
  return (
    <div className={styles.result__item} onClick={() => onClick(item)}>
      {!item.isActive && (
        <div className={styles.pill}>
          <Pill>
            <FormattedMessage id="common.archived" />
          </Pill>
        </div>
      )}

      <div className={styles.label}>
        <HighlightString value={item.name || ""} match={(query || "").trim()} />
      </div>
    </div>
  );
};
