import React, { FC, useCallback, useEffect } from "react";
import styles from "./style.module.scss";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

type Props = {
  id: string;
  message: string;
  icon?: React.ReactNode;
  canManuallyRemove?: boolean;
  removeAfter?: number;
  size?: "small" | "medium" | "large";
  theme?: "dark" | "light";
  className?: string;
  style?: React.CSSProperties;
  onRemove: (id: string) => void;
};

export const ToastComponent: FC<Props> = ({
  id,
  message,
  icon,
  canManuallyRemove,
  removeAfter,
  size,
  theme,
  className,
  style,
  onRemove,
}) => {
  const onRemoveCallback = useCallback(() => {
    onRemove(id);
  }, [onRemove, id]);

  useEffect(() => {
    if (!!removeAfter) {
      setTimeout(onRemoveCallback, removeAfter);
    }
  }, [removeAfter, onRemoveCallback]);

  return (
    <div
      className={classnames(
        styles.toast,
        styles[size || "small"],
        styles[`toast__${theme || "dark"}`],
        className
      )}
      style={style}
    >
      {!!icon && <div className={styles.icon}>{icon}</div>}
      <div
        className={styles.message}
        dangerouslySetInnerHTML={{ __html: message }}
      />
      {!!canManuallyRemove && (
        <div className={styles.close} onClick={onRemoveCallback}>
          <FontAwesomeIcon icon={faTimes as any} />
        </div>
      )}
    </div>
  );
};
