import { SinglePermissionResource } from "@kolibri/authorization-api";
import { combineReducers } from "redux";
import {
  actions as apiResourcesActions,
  reducer as apiResourcesReducer,
  selectors as apiResourcesSelectors,
  thunks as apiResourcesThunks,
} from "./api-resources";
import {
  actions as permissionResourcesActions,
  reducer as permissionResourcesReducer,
  selectors as permissionResourcesSelectors,
  thunks as permissionResourcesThunks,
} from "./permission-resources";

export const reducer = combineReducers({
  apiResources: apiResourcesReducer,
  permissionResources: permissionResourcesReducer,
});

export const actions = {
  apiResources: apiResourcesActions,
  permissionResources: permissionResourcesActions,
};

export const thunks = {
  apiResources: apiResourcesThunks,
  permissionResources: permissionResourcesThunks,
};

export const selectors = {
  apiResources: apiResourcesSelectors,
  permissionResources: permissionResourcesSelectors,
};

export type EditablePermissionResourceShape = {
  permissionResource: SinglePermissionResource | null;
};
