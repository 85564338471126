import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@kolibri/ui";
import { useDispatch } from "hooks/dispatch";
import { httpWebUtil } from "store";
import { thunks as employeesThunks } from "store/employees";
import React, { FC, useCallback, useState } from "react";
import styles from "./style.module.scss";
import { settingsThunks } from "store/settings";

export const AccountOnBehalfOfScreen: FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const resetOnBehalfCallback = useCallback(async () => {
    setLoading(true);
    const data = await httpWebUtil.getDecodedToken();
    if (!!data?.orgpid) {
      await dispatch(employeesThunks.employees.resetOnBehalf(data.orgpid));
    }
  }, [dispatch]);

  const openKolibriCallback = useCallback(() => {
    dispatch(settingsThunks.openKolibri());
  }, [dispatch]);

  return (
    <div className={styles.notFound}>
      <div className={styles.content}>
        <h1>Hey...</h1>
        <h2>
          Je bent nu ingelogd als een andere gebruiker, klik hieronder op één
          van de acties om verder te gaan.
        </h2>
        <Button
          theme="primary"
          label="Open Kolibri"
          onClick={openKolibriCallback}
          disabled={loading}
          style={{ marginRight: 6 }}
        />
        <Button
          theme="secondary"
          onClick={resetOnBehalfCallback}
          disabled={loading}
        >
          {!!loading && (
            <div className={styles.icon}>
              <FontAwesomeIcon icon={faSpinner as any} spin />
            </div>
          )}
          <div className={styles.label}>Terug naar eigen account</div>
        </Button>
      </div>
    </div>
  );
};
