import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import debounce from "lodash-es/debounce";
import React, { ChangeEvent, FC, useMemo, useRef, useState } from "react";
import { ListColumnFilterOptions } from "../../../..";
import styles from "../../style.module.scss";

type Props = {
  listName: string;
  config: ListColumnFilterOptions;
  onChange: (value: string) => void;
};

export const FilterTextComponent: FC<Props> = ({ onChange }) => {
  const [value, setValue] = useState("");
  const [focussed, setFocussed] = useState(false);
  const input = useRef<HTMLInputElement | null>(null);

  const onChangeDebouncedCallback = useMemo(
    () =>
      debounce((value: string) => {
        onChange(value.trim());
      }, 500),
    [onChange]
  );

  const onChangeCallback = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue(value);
    onChangeDebouncedCallback(value);
  };

  const onClearCallback = () => {
    input.current?.focus();
    setValue("");
    onChange("");
  };

  return (
    <div
      className={classnames(styles.textInputWrapper, {
        [styles.focussed]: focussed,
      })}
    >
      <input
        type="text"
        value={value}
        onChange={onChangeCallback}
        className={styles.textInput}
        onFocus={() => setFocussed(true)}
        onBlur={() => setFocussed(false)}
        ref={input}
      />
      {!!value.length && (
        <div className={styles.textInputTrigger} onClick={onClearCallback}>
          <FontAwesomeIcon icon={faTimes as any} />
        </div>
      )}
    </div>
  );
};
