import { RootState } from "store";
import { slice, adapter } from "./slices";
import apiResourcesThunks from "./thunks";

export const actions = slice.actions;
export const reducer = slice.reducer;
export const selectors = adapter.getSelectors<RootState>(
  state => state.apiResources.apiResources
);
export const thunks = apiResourcesThunks;
