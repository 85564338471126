import classnames from "classnames";
import React, { FC, useMemo } from "react";
import { Theme } from "../../types/common";
import HighLightString from "../highlight-string";
import styles from "./style.module.scss";

type Props = {
  label?: string;
  highlightLabel?: boolean;
  query?: string;
  theme?: Theme;
  style?: React.CSSProperties;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export const PillComponent: FC<Props> = ({
  children,
  label,
  highlightLabel,
  query,
  theme,
  style,
  className,
  onClick,
}) => {
  const body = useMemo(() => {
    if (!!children) return children;

    switch (true) {
      case !!label && !!highlightLabel && !!query: {
        return (
          <HighLightString
            value={label as string}
            match={(query || "").trim()}
            style={{ fontWeight: 700 }}
          />
        );
      }
      default: {
        return label || null;
      }
    }
  }, [children, label, highlightLabel, query]);

  return (
    <div
      className={classnames(
        styles.pill,
        { [styles[theme || "flat"]]: !!theme || !className },
        className
      )}
      style={style}
      onClick={onClick}
    >
      {body}
    </div>
  );
};
