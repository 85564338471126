import React, { FC } from "react";
import styles from "./style.module.scss";
import classnames from "classnames";
import CopyToClipboard from "../copy";

type Props<T = any> = {
  style?: React.CSSProperties;
  className?: string;
  labelClassName?: string;
  valueClassName?: string;
  label: string;
  value: T;
  copy?: boolean;
  children?: (value: T) => React.ReactNode;
};

export const SectionRowComponent: FC<Props> = ({
  style,
  className,
  labelClassName,
  valueClassName,
  label,
  value,
  copy,
  children,
}) => {
  return value === undefined || value === null || value === "" ? null : (
    <div className={classnames(styles.row, className)} style={style}>
      <div className={classnames(styles.row__label, labelClassName)}>
        {label}
      </div>
      <div className={classnames(styles.row__value, valueClassName)}>
        {!copy ? (
          typeof children === "function" ? (
            children(value)
          ) : (
            value
          )
        ) : (
          <CopyToClipboard value={value} withIcon>
            {typeof children === "function" ? children(value) : value}
          </CopyToClipboard>
        )}
      </div>
    </div>
  );
};
