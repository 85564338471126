import { PersistConfig, persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage/session";
import { RootState } from "store";
import { slice, adapter } from "./slice";
import thunks from "./thunks";

const persistConfig: PersistConfig<ReturnType<typeof slice.reducer>> = {
  storage,
  key: "k24/real-estate-agencies/cache",
};

export const cacheReducer = persistReducer(persistConfig, slice.reducer);
export const cacheActions = slice.actions;
export const cacheThunks = thunks;
export const cacheSelectors = adapter.getSelectors<RootState>(
  state => state.realEstateAgencies.cache
);
