import {
  ActiveFilter,
  CadastreClient,
  CadastreOrderByField,
  SortOrder,
} from "@kolibri/search-api";
import { createThunk } from "helpers/store";
import { slice } from "./slices";

type FetchArgs = { objectAssignmentId: string; realEstateAgencyId: string };
const fetchCadastres = createThunk(
  "fetchCadastres",
  async ({ http, dispatch, handleError, settings }, args: FetchArgs) => {
    try {
      const client = new CadastreClient(settings?.search_api_url, http);
      const { realEstateAgencyId, objectAssignmentId } = args;

      const cadastres = await client
        .search(
          {
            orderBy: CadastreOrderByField.CreationDateTime,
            filterByActive: ActiveFilter.ActiveOrInactive,
            order: SortOrder.Ascending,
            skip: 0,
            take: 100,
            objectAssignmentId,
          },
          realEstateAgencyId
        )
        .then(response => response.results || []);

      dispatch(slice.actions.add(cadastres));
      return;
    } catch (error) {
      handleError(error);
    }
  }
);

const thunks = { fetchCadastres };
export default thunks;
