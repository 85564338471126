import { createAsyncThunk } from "@reduxjs/toolkit";
import { intl } from "helpers/intl-context";
import isString from "lodash-es/isString";
import { IntlShape } from "react-intl";
import { httpWebUtil, RootDispatch, RootState } from "store";
import { actions as errorsActions } from "store/errors";
import { actions as toastsActions, Toast } from "store/toasts";
import { v4 as uuid } from "uuid";
import { AppSettings } from "@kolibri/utils";

const handleError =
  (dispatch: RootDispatch, name: string) =>
  (error: any, showToast = true) => {
    let message: string = error?.message,
      status: number = error?.status,
      response: string = error?.response,
      headers: Record<string, any> = error?.headers,
      result: any = error?.result,
      stack: string = error?.stack;

    if (!!error && isString(error)) {
      message = error;
    }

    if (!!response && isString(response)) {
      const parsed = JSON.parse(response);
      if (isString(parsed?.message)) {
        message = !message
          ? parsed.message
          : `<strong>${message}</strong><br />${parsed.message}`;
      }
    }

    if (!!result?.brokenBusinessRules && !!result?.message) {
      try {
        const segments: string[] = [];
        const brokenBusinessRules = Object.keys(result.brokenBusinessRules);
        for (let i = 0; i < brokenBusinessRules.length; i++) {
          const brokenBusinessRule =
            result.brokenBusinessRules[brokenBusinessRules[i]];
          if (isString(brokenBusinessRule)) {
            segments.push(brokenBusinessRule);
          }
        }

        message = `<strong>${result.message}</strong><br />${segments.join(
          "<br />"
        )}`;
      } catch (error) {
        throw error;
      }
    }

    let linkedErrorId: string | undefined;

    if (!!dispatch && !!errorsActions?.add) {
      linkedErrorId = uuid();

      dispatch(
        errorsActions.add({
          id: linkedErrorId,
          name,
          dateTimeThrown: new Date(),
          message,
          status,
          response,
          headers,
          result,
          stack,
        })
      );
    }

    if (!!showToast && !!dispatch && toastsActions?.addToast) {
      const toast: Toast = {
        id: uuid(),
        message,
        canManuallyRemove: true,
        icon: "faExclamationTriangle",
        isError: true,
        errorId: linkedErrorId,
      };
      dispatch(toastsActions.addToast(toast));
    }

    throw new Error(error);
  };

/**
 * Creates a async thunk action
 * @param name
 * @param asyncFunction Important! The second argument of asyncFunction is a extended window.fetch object that needs to be passed to every client as second argument
 */
export const createThunk = <ReturnType = unknown, ArgumentType = void>(
  name: string,
  asyncFunction: (
    extra: {
      http: {
        fetch(url: RequestInfo, init?: RequestInit): Promise<Response>;
      };
      intl: IntlShape;
      settings: AppSettings | null | undefined;
      userId: () => Promise<string>;
      getState: () => RootState;
      dispatch: RootDispatch;
      handleError: (error: any) => void;
      fetchNewToken: () => Promise<void>;
    },
    args: ArgumentType
  ) => Promise<ReturnType>
) => {
  return createAsyncThunk<ReturnType, ArgumentType, { state: RootState }>(
    name,
    async (payload, thunkApi) => {
      try {
        const { getState, dispatch, signal } = thunkApi;
        const http = httpWebUtil.http(signal);
        const settings = httpWebUtil.settings();
        const fetchNewToken = httpWebUtil.fetchNewToken;

        return await asyncFunction(
          {
            http,
            intl,
            settings,
            userId: httpWebUtil.getUserId,
            getState,
            dispatch,
            handleError: handleError(dispatch, name),
            fetchNewToken,
          },
          payload
        );
      } catch (error) {
        throw error;
      }
    }
  );
};
