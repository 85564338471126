import { slice, adapter } from "./slices";
import storage from "redux-persist/es/storage/session";
import assignmentsThunks from "./thunks";
import { RootState } from "store";
import { PersistConfig, persistReducer } from "redux-persist";

const persistConfig: PersistConfig<ReturnType<typeof slice.reducer>> = {
  storage,
  key: "k24/assignments/assignments",
};

export const actions = slice.actions;
export const reducer = persistReducer(persistConfig, slice.reducer);
export const selectors = adapter.getSelectors<RootState>(
  state => state.assignments.assignments
);
export const thunks = assignmentsThunks;
