import React, { FC, useMemo } from "react";
import styles from "./style.module.scss";
import classnames from "classnames";
import { ListColumnProps } from "../..";

type Props = {
  className?: string;
  config: ListColumnProps<any> | undefined;
};

export const ListColumnComponent: FC<Props> = ({
  children,
  className,
  config,
}) => {
  const style = useMemo(() => {
    if (!config) return undefined;
    const { width, fill } = config;

    return (!fill
      ? {
          width,
        }
      : {
          minWidth: width,
          flex: 1,
        }) as React.CSSProperties;
  }, [config]);

  return (
    <div className={classnames(styles.column, className)} style={style}>
      {children}
    </div>
  );
};
