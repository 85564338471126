import React, { FC } from "react";
import styles from "./style.module.scss";

type Props = {
  value: string;
  match: string;
  style?: React.CSSProperties;
};

export const HighlightStringComponent: FC<Props> = ({
  value,
  match,
  style,
}) => {
  let html = [<span key={0}>{value}</span>];
  if (!!value && !!match) {
    const startIndex = value.toLowerCase().indexOf(match.toLowerCase());
    const stopIndex = startIndex + match.length;
    if (startIndex >= 0) {
      html = [
        <span className={styles.span} key={0}>
          {value.substring(0, startIndex)}
        </span>,
        <mark
          className={!!style ? styles.reset : styles.mark}
          style={style}
          key={1}
        >
          {value.substring(startIndex, stopIndex)}
        </mark>,
        <span className={styles.span} key={2}>
          {value.substring(stopIndex)}
        </span>,
      ];
    }
  }

  return <>{html}</>;
};
