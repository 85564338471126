import {
  createAction,
  createEntityAdapter,
  createSlice,
  Update,
} from "@reduxjs/toolkit";
import { EditableType } from "enums/editable-types";
import { editablesActions } from "store/editables";
import { ExtendedJob } from ".";

export const add = createAction<ExtendedJob>("delete-jobs/add");
export const update = createAction<Update<ExtendedJob>>("delete-jobs/update");

export const originalAdapter = createEntityAdapter<ExtendedJob>();
export const originalSlice = createSlice({
  name: "delete-jobs/original",
  initialState: originalAdapter.getInitialState(),
  reducers: {
    add: originalAdapter.upsertOne,
    update: originalAdapter.updateOne,
  },
  extraReducers: builder => {
    builder.addCase(editablesActions.removeEditable, (state, action) => {
      if (action.payload.type === EditableType.DeleteJob) {
        originalAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(add, (state, action) => {
      originalAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(update, (state, action) => {
      originalAdapter.updateOne(state, action.payload);
    });
  },
});

export const editableAdapter = createEntityAdapter<ExtendedJob>();
export const editableSlice = createSlice({
  name: "delete-jobs/editable",
  initialState: editableAdapter.getInitialState(),
  reducers: {
    add: editableAdapter.upsertOne,
    update: editableAdapter.updateOne,
  },
  extraReducers: builder => {
    builder.addCase(editablesActions.removeEditable, (state, action) => {
      if (action.payload.type === EditableType.DeleteJob) {
        editableAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(add, (state, action) => {
      editableAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(update, (state, action) => {
      editableAdapter.updateOne(state, action.payload);
    });
  },
});
