import React, { FC } from "react";
import classnames from "classnames";
import styles from "./style.module.scss";
import { CommonProps } from "../../types";

type Props = CommonProps & {};

export const VStackComponent: FC<Props> = ({
  children,
  className,
  style,
  flex,
}) => {
  style = style || {};
  if (flex !== undefined) {
    style = { ...style, flex };
  }

  return (
    <div className={classnames(styles.stack, className)} style={style}>
      {children}
    </div>
  );
};
