import {
  CreateDeleteJobRequest,
  DeleteJobClient,
  DeleteJobStatusClient,
  EntityType,
} from "@kolibri/admin-api";
import { createThunk } from "helpers/store";
import { ExtendedJob, actions as deleteJobsActions } from ".";

const searchDeleteJobs = createThunk(
  "searchDeleteJobs",
  async ({ http, handleError, settings }, skip: number) => {
    try {
      const client = new DeleteJobClient(settings?.admin_api_url, http);

      return await client.search({
        skip,
        take: 50,
        types: [EntityType.RealEstateAgency],
      });
    } catch (error) {
      handleError(error);
    }
  }
);

const fetchExtendedDeleteJob = createThunk(
  "fetchExtendedDeleteJob",
  async ({ http, handleError, settings, dispatch }, id: string) => {
    try {
      const jobClient = new DeleteJobClient(settings?.admin_api_url, http);
      const statusClient = new DeleteJobStatusClient(
        settings?.admin_api_url,
        http
      );

      const job = await jobClient
        .deleteJobs(id)
        .then(response => response.deleteJob);
      if (!job) {
        throw new Error("Could not fetch delete job.");
      }

      const statuses = await statusClient
        .search({ skip: 0, take: 100, deleteJobIds: [id] })
        .then(response => response.result || []);

      const extendedJob: ExtendedJob = {
        ...job,
        statuses,
      };

      dispatch(deleteJobsActions.add(extendedJob));

      return extendedJob;
    } catch (error) {
      handleError(error);
    }
  }
);

const createDeleteJob = createThunk(
  "createDeleteJob",
  async ({ http, settings, handleError }, args: CreateDeleteJobRequest) => {
    try {
      const client = new DeleteJobClient(settings?.admin_api_url, http);

      return await client.create(args).then(response => response.deleteJob);
    } catch (error) {
      handleError(error);
    }
  }
);

const refreshJob = createThunk(
  "refreshJob",
  async ({ http, settings, handleError, dispatch }, id: string | undefined) => {
    try {
      if (!id) {
        return;
      }

      const client = new DeleteJobClient(settings?.admin_api_url, http);

      const changes = await client
        .deleteJobs(id)
        .then(response => response.deleteJob);

      if (changes) {
        dispatch(deleteJobsActions.update({ id, changes }));
      }
    } catch (error) {
      handleError(error);
    }
  }
);

const refreshStatuses = createThunk(
  "refreshStatuses",
  async ({ http, settings, handleError, dispatch }, id: string | undefined) => {
    try {
      if (!id) {
        return;
      }

      const client = new DeleteJobStatusClient(settings?.admin_api_url, http);

      const statuses = await client
        .search({ skip: 0, take: 100, deleteJobIds: [id] })
        .then(response => response.result || []);

      await dispatch(deleteJobsActions.update({ id, changes: { statuses } }));
    } catch (error) {
      handleError(error);
    }
  }
);

const thunks = {
  searchDeleteJobs,
  fetchExtendedDeleteJob,
  createDeleteJob,
  refreshJob,
  refreshStatuses,
};
export default thunks;
