import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import styles from "./style.module.scss";

export const LoadingScreen: FC = () => {
  return (
    <div className={styles.loading}>
      <div className={styles.spacer}></div>
      <div className={styles.logo}>
        <img
          src={process.env.PUBLIC_URL + "/Icon_dark.svg"}
          alt="Kolibri Admin"
        />
      </div>
      <div className={styles.spacer}>
        <FontAwesomeIcon icon={faSpinner as any} className={styles.icon} spin />
      </div>
    </div>
  );
};
