import React from "react";
import { RootState } from "store";
import { slice, adapter } from "./slice";

export type Toast = {
  id: string;
  message: string;
  theme?: "dark" | "light";
  icon?: string;
  canManuallyRemove?: boolean;
  removeAfter?: number;
  style?: React.CSSProperties;
  isError?: boolean;
  errorId?: string;
};

export const reducer = slice.reducer;
export const actions = slice.actions;
export const selectors = adapter.getSelectors<RootState>(state => state.toasts);
