export const HELPDESK = {
  root: "/helpdesk",
};

export const EMPLOYEES = {
  root: `${HELPDESK.root}/employees`,
  detail: `${HELPDESK.root}/employees/:realEstateAgencyId/:id`,
  edit: `${HELPDESK.root}/employees/:realEstateAgencyId/:id/edit`,
  backofficeAccounts: `${HELPDESK.root}/employees/:realEstateAgencyId/:id/backoffice-accounts`,
  create: `${HELPDESK.root}/employees/create`,
};

export const REALESTATEAGENCIES = {
  root: `${HELPDESK.root}/real-estate-agencies`,
  detail: `${HELPDESK.root}/real-estate-agencies/:id`,
  userAccounts: `${HELPDESK.root}/real-estate-agencies/:id/user-accounts`,
  backofficeAccounts: `${HELPDESK.root}/real-estate-agencies/:id/backoffice-accounts`,
  objects: `${HELPDESK.root}/real-estate-agencies/:id/assignments`,
  mediaPartners: `${HELPDESK.root}/real-estate-agencies/:id/media-partners`,
  edit: `${HELPDESK.root}/real-estate-agencies/:id/edit`,
  create: `${HELPDESK.root}/real-estate-agencies/create`,
  mls: `${HELPDESK.root}/real-estate-agencies/:id/mls`,
  emailAccounts: `${HELPDESK.root}/real-estate-agencies/:id/email-accounts`,
  documentTemplateGroups: `${HELPDESK.root}/real-estate-agencies/:id/document-template-groups`,
  deleted: `/real-estate-agencies/deleted`,
  deletedDetail: `/real-estate-agencies/deleted/:id`,
};

export const ASSIGNMENTS = {
  root: `${HELPDESK.root}/assignments`,
  detail: `${HELPDESK.root}/assignments/:realEstateAgencyId/:id`,
  media: `${HELPDESK.root}/assignments/:realEstateAgencyId/:id/media`,
  publications: `${HELPDESK.root}/assignments/:realEstateAgencyId/:id/publications`,
};

export const APP_CLIENTS = {
  root: `/app-clients`,
  detail: `/app-clients/:id`,
  edit: `/app-clients/:id/edit`,
  widgets: `/app-clients/:id/widgets`,
  filters: `/app-clients/:id/filters`,
  faq: `/app-clients/:id/faq`,
  permissions: `/app-clients/:id/permissions`,
  screenshots: `/app-clients/:id/screenshots`,
};

export const API_RESOURCES = {
  root: `/api-resources`,
  permissionResources: `/api-resources/:id`,
  permissionResource: `/api-resources/permission-resource/:id`,
  editPermissionResource: `/api-resources/permission-resource/:id/edit`,
  permissionResourcePermissions: `/api-resources/permission-resource/:id/permissions`,
};

export const MLS_APP_CLIENTS = {
  root: `/mls-app-clients`,
};

export const MLS_GROUPS = {
  root: `/mls-groups`,
};

export const MLS_REALESTATEAGENCIES = {
  root: `/mls-real-estate-agencies`,
};
