import {
  API_RESOURCES,
  APP_CLIENTS,
  ASSIGNMENTS,
  EMPLOYEES,
  HELPDESK,
  MLS_APP_CLIENTS,
  MLS_GROUPS,
  MLS_REALESTATEAGENCIES,
  REALESTATEAGENCIES,
} from "constants/routes";
import React, { FC, lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "screens/not-found";

const EmployeeModule = lazy(() => import("modules/helpdesk/employee"));
const RealEstateAgencyModule = lazy(
  () => import("modules/helpdesk/real-estate-agency")
);
const AssignmentModule = lazy(() => import("modules/helpdesk/assignment"));
const ApiResourcesModule = lazy(() => import("modules/api-resources"));
const AppClientsModule = lazy(() => import("modules/app-clients"));
const MlsAppClientsModule = lazy(() => import("modules/mls-app-clients"));
const MlsGroupsModule = lazy(() => import("modules/mls-groups"));
const MlsRealEstateAgenciesModule = lazy(
  () => import("modules/mls-real-estate-agencies")
);
const DeletedRealEstateAgenciesModule = lazy(
  () => import("modules/deleted-realestate-agencies")
);

const RootRouting: FC = () => {
  return (
    <Switch>
      <Route
        path="/"
        // @ts-ignore
        exact
      >
        <>
          <div>Dashboard</div>
        </>
      </Route>
      <Route path={EMPLOYEES.root}>
        <Suspense fallback={null}>
          <EmployeeModule />
        </Suspense>
      </Route>
      <Route path={REALESTATEAGENCIES.root}>
        <Suspense fallback={null}>
          <RealEstateAgencyModule />
        </Suspense>
      </Route>
      <Route path={ASSIGNMENTS.root}>
        <Suspense fallback={null}>
          <AssignmentModule />
        </Suspense>
      </Route>
      <Route path={HELPDESK.root}>
        <div>
          <p>Root helpdesktool</p>
        </div>
      </Route>

      <Route path={APP_CLIENTS.root}>
        <Suspense fallback={null}>
          <AppClientsModule />
        </Suspense>
      </Route>

      <Route path={API_RESOURCES.root}>
        <Suspense fallback={null}>
          <ApiResourcesModule />
        </Suspense>
      </Route>

      <Route path={MLS_APP_CLIENTS.root}>
        <Suspense fallback={null}>
          <MlsAppClientsModule />
        </Suspense>
      </Route>

      <Route path={MLS_GROUPS.root}>
        <Suspense fallback={null}>
          <MlsGroupsModule />
        </Suspense>
      </Route>

      <Route path={MLS_REALESTATEAGENCIES.root}>
        <Suspense fallback={null}>
          <MlsRealEstateAgenciesModule />
        </Suspense>
      </Route>

      <Route path={REALESTATEAGENCIES.deleted}>
        <Suspense fallback={null}>
          <DeletedRealEstateAgenciesModule />
        </Suspense>
      </Route>

      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};

export default RootRouting;
