import { HttpWebUtil } from "@kolibri/utils";
import {
  Action,
  combineReducers,
  configureStore,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import { createThunk } from "helpers/store";
import { persistStore } from "redux-persist";
import { reducer as apiResourcesReducer } from "./api-resources";
import { reducer as appClientsReducer } from "./app-clients";
import { reducer as assignmentsReducer } from "./assignments";
import { editablesReducer } from "./editables";
import { reducer as employeesReducer } from "./employees";
import { reducer as errorsReducer } from "./errors";
import { globalSearchReducer } from "./global-search";
import { mainReducer } from "./main";
import { reducer as realEstateAgenciesReducer } from "./real-estate-agencies";
import { relationsReducer } from "./relations";
import { settingsReducer } from "./settings";
import { reducer as toastsReducer } from "./toasts";

export const httpWebUtil = new HttpWebUtil(
  process.env.PUBLIC_URL + "/app-settings.json"
);

const reducer = combineReducers({
  editable: editablesReducer,
  employees: employeesReducer,
  errors: errorsReducer,
  globalSearch: globalSearchReducer,
  main: mainReducer,
  realEstateAgencies: realEstateAgenciesReducer,
  relations: relationsReducer,
  settings: settingsReducer,
  toasts: toastsReducer,
  assignments: assignmentsReducer,
  apiResources: apiResourcesReducer,
  appClients: appClientsReducer,
});

export type RootState = ReturnType<typeof reducer>;
export type RootDispatch = ThunkDispatch<RootState, any, Action<any>>;
export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});
export const persistor = persistStore(store);
export { createThunk };
