import { RealEstateAgency } from "@kolibri/core-api";
import {
  createAction,
  createEntityAdapter,
  createSlice,
  Update,
} from "@reduxjs/toolkit";
import { EditableType } from "enums/editable-types";
import { editablesActions } from "store/editables";

export const add = createAction<RealEstateAgency>(
  "real-estate-agencies/real-estate-agencies/add"
);
export const update = createAction<Update<RealEstateAgency>>(
  "real-estate-agencies/real-estate-agencies/update"
);

export const originalAdapter = createEntityAdapter<RealEstateAgency>();
export const originalSlice = createSlice({
  name: "real-estate-agencies/real-estate-agencies/original",
  initialState: originalAdapter.getInitialState(),
  reducers: {
    add: originalAdapter.upsertOne,
    update: originalAdapter.updateOne,
  },
  extraReducers: builder => {
    builder.addCase(editablesActions.removeEditable, (state, action) => {
      if (action.payload.type === EditableType.RealEstateAgency) {
        originalAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(add, (state, action) => {
      originalAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(update, (state, action) => {
      originalAdapter.updateOne(state, action.payload);
    });
  },
});

export const editableAdapter = createEntityAdapter<RealEstateAgency>();
export const editableSlice = createSlice({
  name: "real-estate-agencies/real-estate-agencies/editable",
  initialState: editableAdapter.getInitialState(),
  reducers: {
    add: editableAdapter.upsertOne,
    update: editableAdapter.updateOne,
  },
  extraReducers: builder => {
    builder.addCase(editablesActions.removeEditable, (state, action) => {
      if (action.payload.type === EditableType.RealEstateAgency) {
        editableAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(add, (state, action) => {
      editableAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(update, (state, action) => {
      editableAdapter.updateOne(state, action.payload);
    });
  },
});
