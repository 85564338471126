import { RootState } from "store";
import { slice, adapter } from "./slices";

export type ErrorShape = {
  id: string;
  name: string;
  dateTimeThrown: Date;
  message?: string;
  status?: number;
  response?: string;
  headers?: Record<string, any>;
  result?: any;
  stack?: string;
};

export const reducer = slice.reducer;
export const actions = slice.actions;
export const selectors = adapter.getSelectors<RootState>(state => state.errors);
