import { combineReducers } from "redux";
import { PersistConfig, persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage/session";
import { RootState } from "store";
import {
  add,
  editableAdapter,
  editableSlice,
  originalAdapter,
  originalSlice,
  remove,
  update,
} from "./slices";
import mainOfficeThunks from "./thunks";

const combinedReducers = combineReducers({
  original: originalSlice.reducer,
  editable: editableSlice.reducer,
});

const persistConfig: PersistConfig<ReturnType<typeof combinedReducers>> = {
  storage,
  key: "k24/real-estate-agencies/main-offices",
};

export const actions = {
  editable: editableSlice.actions,
  original: originalSlice.actions,
  add,
  update,
  remove,
};

export const reducer = persistReducer(persistConfig, combinedReducers);

export const selectors = {
  editable: editableAdapter.getSelectors<RootState>(
    state => state.realEstateAgencies.mainOffices.editable
  ),
  original: originalAdapter.getSelectors<RootState>(
    state => state.realEstateAgencies.mainOffices.original
  ),
};

export const thunks = mainOfficeThunks;
