import { useFormikContext } from "formik";
import { FC, useCallback, useEffect } from "react";
import debounce from "lodash-es/debounce";

type Props = {
  onChange: (values: any) => void;
  debounceBy?: number;
};

export const FormikOnChangeComponent: FC<Props> = ({
  onChange,
  debounceBy,
}) => {
  const { values } = useFormikContext();

  const onChangeCallback = useCallback(
    debounce((values: unknown) => onChange(values), debounceBy || 50),
    [debounceBy, onChange]
  );

  useEffect(() => {
    onChangeCallback(values);
  }, [values]);

  return null;
};
