import React, { FC } from "react";
import styles from "./style.module.scss";

type Props = {
  style?: React.CSSProperties;
};

export const FormRowComponent: FC<Props> = ({ children, style }) => {
  return (
    <div style={style} className={styles.row}>
      {children}
    </div>
  );
};
