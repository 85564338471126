import isNumber from "lodash-es/isNumber";
import React, {
  forwardRef,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Portal from "../portal";
import styles from "./style.module.scss";

const DEFAULT_COLLISION_OFFSET = 24;

type Position =
  | "topleft"
  | "topcenter"
  | "topright"
  | "centerright"
  | "bottomright"
  | "bottomcenter"
  | "bottomleft"
  | "centerleft"
  | "center"
  | "calculate";

type ChildrenAsFunction = (props: {
  maxHeight: number | "none" | undefined;
}) => React.ReactNode;
type Props = {
  visible: boolean;
  parent: React.MutableRefObject<any>;
  width?: number | "parent";
  minWidth?: number | "parent";
  maxWidth?: number | "parent";
  vOffset?: number;
  hOffset?: number;
  children: React.ReactNode | ChildrenAsFunction;
  attachOn?: Position;
  expandFrom?: Position;
  collisionOffset?: number;
  noOverflow?: boolean;
  onClose: () => void;
};

export const ContextComponent = forwardRef<HTMLDivElement | null, Props>(
  (
    {
      children,
      visible,
      parent,
      width,
      minWidth,
      maxWidth,
      vOffset,
      hOffset,
      attachOn,
      expandFrom,
      collisionOffset,
      onClose,
    },
    forwardedRef
  ) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const [wrapperStyle, setWrapperStyle] = useState<React.CSSProperties>();
    const [style, setStyle] = useState<React.CSSProperties>();
    const [maxHeight, setMaxHeight] = useState<number | "none" | undefined>();

    if (!!forwardedRef) {
      if (typeof forwardedRef === "function") {
        forwardedRef(ref.current);
      } else {
        forwardedRef.current = ref.current;
      }
    }

    const calculateContextWrapperStyle = useCallback(() => {
      if (!visible || !parent.current) {
        return;
      }

      const {
        width: pWidth,
        height: pHeight,
        top: pTop,
        left: pLeft,
      } = parent.current.getBoundingClientRect();
      const attachOnWhere = attachOn || "bottomleft";

      let top: any = pHeight + pTop;
      let left: any = pLeft;

      switch (attachOnWhere) {
        case "topleft":
        case "topcenter":
        case "topright": {
          top = pTop;
          left =
            attachOnWhere === "topcenter"
              ? pLeft + pWidth / 2
              : attachOnWhere === "topright"
              ? pLeft + pWidth
              : left;
          break;
        }
        case "centerleft":
        case "center":
        case "centerright": {
          top = top - pHeight / 2;
          left =
            attachOnWhere === "center"
              ? pLeft + pWidth / 2
              : attachOn === "centerright"
              ? pLeft + pWidth
              : left;
          break;
        }
        case "bottomleft":
        case "bottomcenter":
        case "bottomright": {
          top = pTop + pHeight;
          left =
            attachOnWhere === "bottomcenter"
              ? pLeft + pWidth / 2
              : attachOnWhere === "bottomright"
              ? pLeft + pWidth
              : left;
          break;
        }
        default: {
          break;
        }
      }

      top = top + (vOffset || 0);
      left = left + (hOffset || 0);

      setWrapperStyle({
        top,
        left,
      });
    }, [visible, parent, vOffset, hOffset, attachOn, setWrapperStyle]);

    const calculateContextStyle = useCallback(() => {
      if (!visible || !parent.current || !ref.current) return;
      const {
        width: pWidth,
        height: pHeight,
        top: pTop,
        left: pLeft,
      } = parent.current.getBoundingClientRect();
      const { width: rWidth, height: rHeight } =
        ref.current.getBoundingClientRect();

      let expandFromWhere = expandFrom || "topleft";

      if (expandFromWhere === "calculate") {
        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;
        const pBottom = windowHeight - (pTop + pHeight);
        const pRight = windowWidth - (pLeft + pWidth);

        switch (true) {
          case pTop > pBottom && pLeft <= pRight: {
            expandFromWhere = "bottomleft";
            break;
          }
          case pTop > pBottom && pLeft > pRight: {
            expandFromWhere = "bottomright";
            break;
          }
          case pTop <= pBottom && pLeft > pRight: {
            expandFromWhere = "topright";
            break;
          }
          default: {
            expandFromWhere = "topleft";
            break;
          }
        }
      }

      let top: number | "auto" = 0;
      let left: number | "auto" = 0;
      let bottom: number | "auto" = "auto";
      let right: number | "auto" = "auto";

      switch (expandFromWhere) {
        case "topcenter": {
          left = -(rWidth / 2);
          break;
        }
        case "topright": {
          left = "auto";
          right = 0;
          break;
        }
        case "centerleft": {
          top = -(rHeight / 2);
          break;
        }
        case "center": {
          top = -(rHeight / 2);
          left = -(rWidth / 2);
          break;
        }
        case "centerright": {
          top = -(rHeight / 2);
          left = "auto";
          right = 0;
          break;
        }
        case "bottomleft": {
          top = "auto";
          bottom = 0;
          break;
        }
        case "bottomcenter": {
          top = "auto";
          left = -(rWidth / 2);
          bottom = 0;
          break;
        }
        case "bottomright": {
          top = "auto";
          left = "auto";
          bottom = 0;
          right = 0;
          break;
        }
        default: {
          break;
        }
      }

      setStyle({
        top,
        left,
        bottom,
        right,
        width: isNumber(width)
          ? width
          : width === "parent"
          ? pWidth
          : undefined,
        minWidth: isNumber(minWidth)
          ? minWidth
          : minWidth === "parent"
          ? pWidth
          : undefined,
        maxWidth: isNumber(maxWidth)
          ? maxWidth
          : maxWidth === "parent"
          ? pWidth
          : undefined,
      });
    }, [visible, parent, width, expandFrom, minWidth, maxWidth, setStyle]);

    const calculateMaxHeight = useCallback(() => {
      if (!visible || !parent.current || !ref.current) return;
      let maxHeight: number | "none" | undefined;
      const offset = collisionOffset ?? DEFAULT_COLLISION_OFFSET;
      let expandFromWhere = expandFrom || "topleft";

      if (expandFromWhere === "calculate") {
        const windowHeight = window.innerHeight;
        const { top, height } = parent.current.getBoundingClientRect();
        const pBottom = windowHeight - (top + height);

        expandFromWhere = top > pBottom ? "bottomleft" : "topleft";
      }

      switch (expandFromWhere) {
        case "topcenter":
        case "topleft":
        case "topright": {
          const { top } = parent.current.getBoundingClientRect();
          const { innerHeight } = window;
          maxHeight = innerHeight - top - offset;
          break;
        }
        case "bottomcenter":
        case "bottomleft":
        case "bottomright": {
          const { top, height } = parent.current.getBoundingClientRect();
          maxHeight = top + height - offset;
          break;
        }
        default: {
          maxHeight = undefined;
          break;
        }
      }

      setMaxHeight(maxHeight);
    }, [visible, expandFrom, collisionOffset]);

    const onClickOutsideCallback = useCallback(
      (event: MouseEvent) => {
        if (
          !visible ||
          (ref as MutableRefObject<any>)?.current?.contains(
            event.target as Node
          ) ||
          parent.current?.contains(event.target)
        ) {
          return;
        }
        onClose();
      },
      [visible, parent, onClose]
    );

    const closeCallback = useCallback(
      (event: Event) => {
        if (!visible || (ref as any)?.current?.contains(event.target as Node)) {
          return;
        }
        onClose();
      },
      [visible, onClose]
    );

    useEffect(() => {
      window.addEventListener("resize", closeCallback, true);
      document.addEventListener("scroll", closeCallback, true);
      document.addEventListener("click", onClickOutsideCallback, true);

      return () => {
        window.removeEventListener("resize", closeCallback, true);
        document.removeEventListener("scroll", closeCallback, true);
        document.removeEventListener("click", onClickOutsideCallback, true);
      };
    }, [closeCallback, onClickOutsideCallback]);

    useEffect(() => {
      calculateContextStyle();
    }, [calculateContextStyle]);

    useEffect(() => {
      calculateContextWrapperStyle();
    }, [calculateContextWrapperStyle]);

    useEffect(() => {
      calculateMaxHeight();
    }, [calculateMaxHeight]);

    return !visible || !parent.current ? null : (
      <Portal>
        <div className={styles.contextWrapper} style={wrapperStyle}>
          <div className={styles.context} style={style} ref={ref}>
            {typeof children === "function"
              ? (children as ChildrenAsFunction)({ maxHeight })
              : children}
          </div>
        </div>
      </Portal>
    );
  }
);
