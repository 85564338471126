import { useField } from "formik";
import React, { FC, useCallback } from "react";
import fieldStyles from "../../style.module.scss";
import styles from "./style.module.scss";
import classnames from "classnames";
import { FIELD_RESET } from "../../../../constants/config";

type Props = {
  style?: React.CSSProperties;
  className?: string;
  name: string;
  placeholder?: string;
  hint?: string;
  disabled?: boolean;
  label?: string;
  labelPlacement?: "top" | "right" | "left";
  labelClassName?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
};

export const TextComponent: FC<Props> = ({
  name,
  placeholder,
  hint,
  style,
  className,
  disabled,
  label,
  labelPlacement,
  labelClassName,
  onChange,
  onFocus,
  onBlur,
}) => {
  const [
    { onChange: formikOnchange, onBlur: formikOnBlur, value, ...field },
    meta,
  ] = useField(name);

  const onChangeCallback = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      formikOnchange(event);
      !!onChange && onChange(event);
    },
    [onChange, formikOnchange]
  );

  const onBlurCallback = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      formikOnBlur(event);
      !!onBlur && onBlur(event);
    },
    [onBlur, formikOnBlur]
  );

  style = style || { width: undefined };
  const { width, ...inputStyle } = style;
  const inputValue = value || "";

  return (
    <div
      className={classnames(
        fieldStyles.wrapper,
        styles[`label__${labelPlacement || "top"}`]
      )}
      style={{ width }}
    >
      {!!label && (
        <label
          htmlFor={field.name}
          className={classnames(
            styles.label,
            styles[labelPlacement || "top"],
            labelClassName
          )}
        >
          {label}
        </label>
      )}

      <div className={styles.inputContainer}>
        <input
          {...field}
          id={field.name}
          value={inputValue}
          type="text"
          placeholder={placeholder}
          data-lpignore={true}
          className={classnames(fieldStyles.field, styles.text, className, {
            [fieldStyles.hasError]: meta.touched && meta.error,
          })}
          style={inputStyle}
          disabled={disabled}
          onChange={onChangeCallback}
          onFocus={onFocus}
          onBlur={onBlurCallback}
          {...FIELD_RESET}
        />
        {meta.touched && meta.error && (
          <div className={fieldStyles.error}>{meta.error}</div>
        )}
        {!(meta.touched && meta.error) && hint && (
          <div className={fieldStyles.hint}>{hint}</div>
        )}
      </div>
    </div>
  );
};
