import {
  AddAppClientFilterRequest,
  AppClient,
  AppClientCategory,
  AppClientClient,
  AppClientFilterClient,
  AppClientFilterOrderByField,
  AppClientOrderByField,
  AppClientSearchRequest,
  AppClientTermField,
  ArchiveFilter,
  EncryptionKeyClient,
  EncryptionKeyOrderByField,
  PermissionClient,
  PermissionOrderByField,
  PermissionSnapShot,
  RemunerationCategory,
  SortOrder,
  Widget,
  WidgetClient,
  WidgetOrderByField,
} from "@kolibri/authorization-api";
import { APP_CLIENTS } from "constants/routes";
import { EditableType } from "enums/editable-types";
import { createThunk } from "helpers/store";
import { mapRouteParams } from "mappers/route";
import { batch } from "react-redux";
import { editablesActions, editablesSelector } from "store/editables";
import {
  actions as appClientsActions,
  selectors as appClientsSelectors,
} from "../";
import { Toast, actions as toastsActions } from "store/toasts";
import { v4 as uuid } from "uuid";
import { AUTHORIZATION_LANGUAGES } from "constants/config";
import sortBy from "lodash-es/sortBy";

type FetchAppClientsArgs = {
  name: string;
  skip: number;
  filterByArchived: ArchiveFilter;
  order: SortOrder;
  hasConsent?: boolean | null;
  isSpecial?: boolean | null;
  categories?: AppClientCategory[] | null;
  remunerationCategories?: RemunerationCategory[] | null;
};
const fetchAppClients = createThunk(
  "fetchAppClients",
  async (
    { http, handleError, getState, settings },
    args: FetchAppClientsArgs
  ) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new AppClientClient(settings?.authorization_api_url, http);
      const {
        name,
        skip,
        filterByArchived,
        order,
        hasConsent,
        isSpecial,
        categories,
        remunerationCategories,
      } = args;

      let request: AppClientSearchRequest = {
        skip,
        take: 100,
        orderBy: AppClientOrderByField.Default,
        filterByArchived,
        order,
        hasConsent,
        isSpecial,
        categories,
        remunerationCategories,
      };

      if (!!name) {
        request = {
          ...request,
          term: name,
          termFields: [AppClientTermField.DisplayName],
        };
      }

      const response = await client.search(request, realEstateAgencyId);

      if (!response) {
        throw new Error("Could not fetch app clients");
      }

      const { totalResults, results } = response;

      return {
        totalResults,
        results: results || [],
      };
    } catch (error) {
      handleError(error);
    }
  }
);

const reset = createThunk(
  "reset",
  async ({ dispatch, getState, handleError }, id: string) => {
    try {
      const state = getState();
      const appClient = appClientsSelectors.appClients.original.selectById(
        state,
        id
      );

      if (!!appClient) {
        dispatch(
          appClientsActions.appClients.editable.update({
            id: appClient.id,
            changes: appClient,
          })
        );
      }
    } catch (error) {
      handleError(error);
    }
  }
);

const fetchAppClient = createThunk(
  "fetchAppClient",
  async (
    { http, dispatch, getState, settings, handleError },
    id: string
  ): Promise<AppClient | undefined> => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new AppClientClient(settings?.authorization_api_url, http);

      let appClient = await client
        .read(id, realEstateAgencyId)
        .then(response => response.appClient);

      if (!appClient) {
        throw Error("Couldn't find app client");
      }

      if (appClient.translations?.length) {
        const locales = AUTHORIZATION_LANGUAGES.map(l => l.locale);
        appClient = {
          ...appClient,
          translations: sortBy(appClient.translations, t =>
            locales.indexOf(t.languageCultureName || "")
          ),
        };
      }

      dispatch(appClientsActions.appClients.add(appClient));

      return appClient;
    } catch (error) {
      handleError(error);
    }
  }
);

const fetchWidgets = createThunk(
  "fetchWidgets",
  async ({ http, handleError, getState, settings }, appClientId: string) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new WidgetClient(settings?.authorization_api_url, http);

      const widgets = await client
        .search(
          {
            appClientId,
            skip: 0,
            take: 100,
            orderBy: WidgetOrderByField.Default,
            filterByArchived: ArchiveFilter.NotArchivedOnly,
            order: SortOrder.Ascending,
          },
          realEstateAgencyId
        )
        .then(response => response.results || []);

      if (!widgets) {
        throw Error("Couldn't find widgets");
      }

      return widgets;
    } catch (error) {
      handleError(error);
    }
  }
);

const fetchWidget = createThunk(
  "fetchWidget",
  async ({ http, handleError, getState, settings }, widgetId: string) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new WidgetClient(settings?.authorization_api_url, http);

      const widget = await client
        .readWidget(widgetId, realEstateAgencyId)
        .then(response => response?.widget);

      if (!widget) {
        throw Error("Couldn't find widget");
      }

      return widget;
    } catch (error) {
      handleError(error);
    }
  }
);

type SaveWidgetArgs = {
  widget: Widget;
  navigate?: (path: string) => void;
  close?: boolean;
};
const saveWidget = createThunk(
  "saveWidget",
  async ({ http, getState, settings }, args: SaveWidgetArgs) => {
    try {
      const client = new WidgetClient(settings?.authorization_api_url, http);
      const { widget } = args;

      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";

      const savedWidget = await client
        .saveWidget({ widget }, realEstateAgencyId)
        .then(response => response?.widget);
      if (!savedWidget) {
        throw new Error("Error saving widget");
      }

      return savedWidget;
    } catch (error) {
      throw error;
    }
  }
);

const defineNewWidget = createThunk(
  "defineNewWidget",
  async ({ http, getState, settings }, appClientId: string) => {
    try {
      const client = new WidgetClient(settings?.authorization_api_url, http);

      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";

      const widget = await client
        .defineNew({ appClientId }, realEstateAgencyId)
        .then(response => response?.widget);

      if (!widget) {
        throw new Error("Error creating widget");
      }

      return widget;
    } catch (error) {
      throw error;
    }
  }
);

const removeWidget = createThunk(
  "removeWidget",
  async ({ http, getState, settings }, widgetId: string) => {
    try {
      const client = new WidgetClient(settings?.authorization_api_url, http);

      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";

      await client.deleteWidget(widgetId, realEstateAgencyId);

      return;
    } catch (error) {
      throw error;
    }
  }
);

type ActivateWidgetArgs = {
  id: string;
  overwriteChecks: boolean;
};
const activateWidget = createThunk(
  "activateWidget",
  async (
    { http, getState, settings, dispatch, intl, handleError },
    args: ActivateWidgetArgs
  ) => {
    try {
      const client = new WidgetClient(settings?.authorization_api_url, http);

      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const { id, overwriteChecks } = args;

      await client.activate({ id, overwriteChecks }, realEstateAgencyId);

      const toast: Toast = {
        id: uuid(),
        message: intl.formatMessage({ id: "toast.widget.success" }),
        removeAfter: 3000,
      };

      dispatch(toastsActions.addToast(toast));

      return;
    } catch (error) {
      handleError(error);
    }
  }
);

const fetchValidationUrls = createThunk(
  "fetchValidationUrls",
  async (
    { http, getState, settings, dispatch, intl, handleError },
    widgetId: string
  ) => {
    try {
      const client = new WidgetClient(settings?.authorization_api_url, http);
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";

      const response = await client.getValidationurls(
        widgetId,
        realEstateAgencyId
      );

      const result = response?.urls || [];

      return result;
    } catch (error) {
      handleError(error);
    }
  }
);

const fetchEncryptionKeys = createThunk(
  "fetchEncryptionKeys",
  async ({ http, handleError, getState, settings }, appClientId: string) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new EncryptionKeyClient(
        settings?.authorization_api_url,
        http
      );

      const encryptionKeys = await client
        .search(
          {
            appClientId,
            skip: 0,
            take: 100,
            orderBy: EncryptionKeyOrderByField.Default,
            filterByArchived: ArchiveFilter.NotArchivedOnly,
            order: SortOrder.Ascending,
          },
          realEstateAgencyId
        )
        .then(response => response.results || []);

      if (!encryptionKeys) {
        throw Error("Couldn't find encryption keys");
      }

      return encryptionKeys;
    } catch (error) {
      handleError(error);
    }
  }
);

const generateEncryptionKey = createThunk(
  "generateEncryptionKey",
  async ({ http, handleError, getState, settings }, appClientId: string) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new EncryptionKeyClient(
        settings?.authorization_api_url,
        http
      );

      const encryptionKey = await client
        .generateNew(
          {
            appClientId,
          },
          realEstateAgencyId
        )
        .then(response => response.encryptionKey || []);

      if (!encryptionKey) {
        throw Error("Couldn't create encryption key");
      }

      return encryptionKey;
    } catch (error) {
      handleError(error);
    }
  }
);

type ToggleEncryptionKeyArgs = {
  appClientId: string;
  version: number;
  isActive: boolean;
};
const toggleEncryptionKey = createThunk(
  "toggleEncryptionKey",
  async (
    { http, handleError, getState, settings },
    args: ToggleEncryptionKeyArgs
  ) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new EncryptionKeyClient(
        settings?.authorization_api_url,
        http
      );

      const { appClientId, version, isActive } = args;

      await client.toggle(
        {
          appClientId,
          version,
          isActive,
        },
        realEstateAgencyId
      );

      return;
    } catch (error) {
      handleError(error);
    }
  }
);

type RemoveEncryptionKeyArgs = {
  appClientId: string;
  version: number;
};
const removeEncryptionKey = createThunk(
  "removeEncryptionKey",
  async (
    { http, handleError, getState, settings },
    args: RemoveEncryptionKeyArgs
  ) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new EncryptionKeyClient(
        settings?.authorization_api_url,
        http
      );

      const { appClientId, version } = args;

      await client.delete(appClientId, version, realEstateAgencyId);

      return;
    } catch (error) {
      handleError(error);
    }
  }
);

type SaveAppClientArgs = {
  appClient: AppClient;
  navigate?: (path: string) => void;
  close?: boolean;
};
const saveAppClient = createThunk(
  "saveAppClient",
  async ({ http, dispatch, getState, settings }, args: SaveAppClientArgs) => {
    try {
      const client = new AppClientClient(settings?.authorization_api_url, http);
      const { appClient, navigate, close } = args;

      if (!appClient) {
        throw new Error("No app client data found");
      }

      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const editables = editablesSelector.selectAll(state);
      const editable = editables.find(
        editable =>
          editable.id === appClient.id &&
          editable.type === EditableType.AppClient
      );

      if (!editable) {
        throw new Error("No matching editable found");
      }

      const savedAppClient = await client
        .saveAppClient({ appClient }, realEstateAgencyId)
        .then(response => response?.appClient);
      if (!savedAppClient) {
        throw new Error("Error saving app client");
      }

      dispatch(
        appClientsActions.appClients.update({
          id: savedAppClient.id,
          changes: savedAppClient,
        })
      );

      if (!!close && !!navigate) {
        navigate(editable.rootPath);
      }

      return;
    } catch (error) {
      throw error;
    }
  }
);

const createNewAppClient = createThunk(
  "createNewAppClient",
  async (
    { dispatch, http, getState, settings },
    navigate: (path: string) => void
  ) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new AppClientClient(settings?.authorization_api_url, http);

      const appClient = await client
        .defineNew({}, realEstateAgencyId)
        .then(response => response?.appClient);
      if (!appClient) {
        throw Error("Could not create new app client");
      }

      const { id } = appClient;

      const rootPath = mapRouteParams(APP_CLIENTS.detail, {
        id,
        realEstateAgencyId,
      });
      const currentPath = `${rootPath}/edit`;

      batch(() => {
        dispatch(appClientsActions.appClients.add(appClient));
        dispatch(
          editablesActions.addEditable({
            id,
            type: EditableType.AppClient,
            rootPath,
            currentPath,
            referrer: window.location.pathname || "/",
            hasChanges: false,
            hasError: false,
            isSaving: false,
            title: "...",
          })
        );
      });

      navigate(currentPath);
    } catch (error) {
      throw error;
    }
  }
);

export type ExtendedPermissionSnapShot = PermissionSnapShot & {
  activated: boolean;
};
type FetchPermissionsArgs = {
  appClientId: string;
  apiResourceId: string;
  permissionResourceId: string;
  skip: number;
};
const fetchPermissions = createThunk(
  "fetchPermissions",
  async (
    { http, handleError, getState, settings },
    args: FetchPermissionsArgs
  ) => {
    try {
      const { appClientId, apiResourceId, permissionResourceId, skip } = args;
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new PermissionClient(
        settings?.authorization_api_url,
        http
      );

      const activated = await client.search(
        {
          orderBy: PermissionOrderByField.Default,
          filterByArchived: ArchiveFilter.NotArchivedOnly,
          order: SortOrder.Ascending,
          skip,
          take: 100,
          appClientId,
          apiResourceId,
          permissionResourceIds: [permissionResourceId],
        },
        realEstateAgencyId
      );

      if (!activated) {
        throw Error("Couldn't find permissions");
      }

      const activedIds = (activated.results || []).map(item => item.id);
      const all = await client.search(
        {
          orderBy: PermissionOrderByField.Default,
          filterByArchived: ArchiveFilter.NotArchivedOnly,
          order: SortOrder.Ascending,
          skip,
          take: 100,
          apiResourceId,
          permissionResourceIds: [permissionResourceId],
        },
        realEstateAgencyId
      );

      if (!all) {
        throw Error("Couldn't find permissions");
      }

      const results = (all.results || []).map(
        item =>
          ({
            ...item,
            activated: activedIds.includes(item.id),
          } as ExtendedPermissionSnapShot)
      );

      return {
        results: results,
        total: all.totalResults,
      };
    } catch (error) {
      handleError(error);
    }
  }
);

type TogglePermissionArgs = {
  appClientId: string;
  permissionId: string;
  hasPermission: boolean;
};
const togglePermission = createThunk(
  "togglePermission",
  async (
    { http, handleError, getState, settings },
    args: TogglePermissionArgs
  ) => {
    try {
      const { appClientId, permissionId, hasPermission } = args;
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new AppClientClient(settings?.authorization_api_url, http);

      await client.togglePermission(
        { appClientId, permissionId, hasPermission },
        realEstateAgencyId
      );

      return;
    } catch (error) {
      handleError(error);
    }
  }
);

type FetchFiltersArgs = {
  appClientId: string;
  skip: number;
};
const fetchFilters = createThunk(
  "fetchFilters",
  async ({ http, handleError, getState, settings }, args: FetchFiltersArgs) => {
    try {
      const { appClientId, skip } = args;
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";
      const client = new AppClientFilterClient(
        settings?.authorization_api_url,
        http
      );

      const response = await client.search(
        {
          orderBy: AppClientFilterOrderByField.Default,
          filterByArchived: ArchiveFilter.NotArchivedOnly,
          order: SortOrder.Ascending,
          skip,
          take: 100,
          appClientId,
        },
        realEstateAgencyId
      );
      if (!response) {
        throw new Error("Couldn't find app client filters");
      }

      return {
        total: response.totalResults,
        results: response.results || [],
      };
    } catch (error) {
      handleError(error);
    }
  }
);

type SaveAppClientFilterArgs = {
  appClientId: string;
  companyId?: string | undefined;
  groupId?: string | undefined;
  navigate?: (path: string) => void;
  close?: boolean;
};

const saveAppClientFilter = createThunk(
  "saveAppClientFilter",
  async (
    { http, handleError, getState, settings },
    args: SaveAppClientFilterArgs
  ) => {
    try {
      const client = new AppClientFilterClient(
        settings?.authorization_api_url,
        http
      );
      const { appClientId, companyId, groupId } = args;
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";

      const request: AddAppClientFilterRequest = {
        appClientId,
        companyId,
        groupId,
      };

      await client.add(request, realEstateAgencyId);

      return;
    } catch (error) {
      handleError(error);
    }
  }
);

type RemoveAppClientFilterArgs = {
  appClientId: string;
  companyId?: string | undefined;
  groupId?: string | undefined;
  navigate?: (path: string) => void;
  close?: boolean;
};
const removeAppClientFilter = createThunk(
  "removeAppClientFilter",
  async (
    { http, handleError, getState, settings },
    args: RemoveAppClientFilterArgs
  ) => {
    try {
      const client = new AppClientFilterClient(
        settings?.authorization_api_url,
        http
      );
      const { appClientId, companyId, groupId } = args;
      const state = getState();
      const realEstateAgencyId =
        state.settings.accountSettings?.realEstateAgencyId || "";

      await client.delete(companyId, groupId, appClientId, realEstateAgencyId);

      return;
    } catch (error) {
      handleError(error);
    }
  }
);

const thunks = {
  fetchAppClients,
  reset,
  fetchAppClient,
  fetchWidgets,
  fetchWidget,
  saveWidget,
  defineNewWidget,
  removeWidget,
  activateWidget,
  fetchValidationUrls,
  fetchEncryptionKeys,
  generateEncryptionKey,
  toggleEncryptionKey,
  removeEncryptionKey,
  saveAppClient,
  createNewAppClient,
  fetchPermissions,
  togglePermission,
  fetchFilters,
  saveAppClientFilter,
  removeAppClientFilter,
};
export default thunks;
