import { Employee } from "@kolibri/core-api";
import {
  createAction,
  createEntityAdapter,
  createSlice,
  Update,
} from "@reduxjs/toolkit";
import { EditableType } from "enums/editable-types";
import { editablesActions } from "store/editables";

export const add = createAction<Employee>("employees/employees/add");
export const update = createAction<Update<Employee>>(
  "employees/employees/update"
);

export const originalAdapter = createEntityAdapter<Employee>();
export const originalSlice = createSlice({
  name: "employees/employees/original",
  initialState: originalAdapter.getInitialState(),
  reducers: {
    add: originalAdapter.upsertOne,
    update: originalAdapter.updateOne,
  },
  extraReducers: builder => {
    builder.addCase(editablesActions.removeEditable, (state, action) => {
      if (action.payload.type === EditableType.Employee) {
        originalAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(add, (state, action) => {
      originalAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(update, (state, action) => {
      originalAdapter.updateOne(state, action.payload);
    });
  },
});

export const editableAdapter = createEntityAdapter<Employee>();
export const editableSlice = createSlice({
  name: "employees/employees/editable",
  initialState: editableAdapter.getInitialState(),
  reducers: {
    add: editableAdapter.upsertOne,
    update: editableAdapter.updateOne,
  },
  extraReducers: builder => {
    builder.addCase(editablesActions.removeEditable, (state, action) => {
      if (action.payload.type === EditableType.Employee) {
        editableAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(add, (state, action) => {
      editableAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(update, (state, action) => {
      editableAdapter.updateOne(state, action.payload);
    });
  },
});
