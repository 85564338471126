import { MasterTableResponse as AuthorizationTableResponse } from "@kolibri/authorization-api";
import { TableResponse as CoreTableResponse } from "@kolibri/core-api";

export const mapCoreTableResponseToTranslation = (
  tableResponse: CoreTableResponse | null
): Record<string, string> => {
  let translations: Record<string, string> = {};

  if (!!tableResponse) {
    for (const key in tableResponse) {
      if (tableResponse.hasOwnProperty(key)) {
        const properties = (tableResponse as Record<string, any[]>)[key] || [];
        properties.forEach(property => {
          if (
            property.displayName &&
            property.value &&
            key !== "taskCategories"
          ) {
            translations[`${key}.${property.value}`] = property.displayName;
          }
        });
      }
    }
  }

  return translations;
};

export const mapAuthorizationTableResponseToTranslation = (
  tableResponse: AuthorizationTableResponse | null
): Record<string, string> => {
  let translations: Record<string, string> = {};

  if (!!tableResponse) {
    for (const key in tableResponse) {
      if (tableResponse.hasOwnProperty(key)) {
        const properties = (tableResponse as Record<string, any[]>)[key] || [];
        properties.forEach(property => {
          if (property.displayName && property.value) {
            translations[`${key}.${property.value}`] = property.displayName;
          }
        });
      }
    }
  }

  return translations;
};
