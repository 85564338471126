import {
  UserManager,
  User,
  WebStorageStateStore,
  UserManagerSettings,
} from "oidc-client";
import storage from "redux-persist/es/storage/session";
import decode from "jwt-decode";
import MockUserManager from "./mockUserManager";

export type AppSettings = {
  client_id: string;
  response_type: string;
  automaticSilentRenew: boolean;
  accessTokenExpiringNotificationTime: number;
  authority: string;
  post_logout_redirect_uri: string;
  redirect_uri: string;
  silent_redirect_uri: string;
  scope: string;
  authorization_api_url: string;
  connect_proxy_api_url: string;
  core_api_url: string;
  id_api_url: string;
  mls_api_url: string;
  search_api_url: string;
  mail_api_url: string;
  document_sign_api_url: string;
  sign_client: string;
  features: (string | { name: string; realEstateAgencyIds: string[] })[];
  kolibri_postback_url: string;
  Environment: string;
  customer_portal_url: string;
  public_customerportal_api_url: string;
  public_customerportal_register_url: string;
  agendasync_api_url: string;
  admin_api_url: string;
  apiUrlCustomerPortalSocket?: string;
};

export class HttpWebUtil {
  private _settingsUrl: string;
  private _userManager: UserManager | MockUserManager | undefined;
  private _loading: Promise<void>;
  private _resolve: (() => void) | undefined;
  private _reject: ((reason?: any) => void) | undefined;
  private _window = window;
  private _fetch = window.fetch;
  private _reAuthorizingUser: Promise<User> | undefined;
  private _user: User | null | undefined;
  private _settings: AppSettings | null | undefined;
  private _override: Partial<UserManagerSettings> | null | undefined;

  constructor(settingsUrl: string, override?: Partial<UserManagerSettings>) {
    this._settingsUrl = settingsUrl;
    this._override = override;
    this._loading = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });

    this._init = this._init.bind(this);
    this._getUser = this._getUser.bind(this);
    this.getUserId = this.getUserId.bind(this);
    this.fetchNewToken = this.fetchNewToken.bind(this);
    this.getDecodedToken = this.getDecodedToken.bind(this);

    this._init();
  }

  private async _init() {
    try {
      const settings: AppSettings = await fetch(this._settingsUrl, {
        cache: "no-cache",
      }).then(response => response.json());

      if (!settings) {
        throw new Error("No valid settings found");
      }

      const {
        client_id,
        response_type,
        automaticSilentRenew,
        accessTokenExpiringNotificationTime,
        authority,
        post_logout_redirect_uri,
        redirect_uri,
        silent_redirect_uri,
        scope,
      } = settings;

      const store = new WebStorageStateStore({ store: storage });
      const { protocol, hostname, search } = window.location;
      const searchParams = new URLSearchParams(search);

      let userManagerSettings: UserManagerSettings = {
        userStore: store,
        stateStore: store,
        client_id,
        response_type,
        automaticSilentRenew,
        accessTokenExpiringNotificationTime,
        authority,
        post_logout_redirect_uri:
          post_logout_redirect_uri || `${protocol}//${hostname}`,
        redirect_uri: redirect_uri || `${protocol}//${hostname}/callback`,
        silent_redirect_uri:
          silent_redirect_uri || `${protocol}//${hostname}/renew`,
        scope,
      };

      if (!!this._override) {
        userManagerSettings = {
          ...userManagerSettings,
          ...this._override,
        };
      }

      this._userManager =
        (window as any).__CYPRESS__ ||
        (window as any).Cypress ||
        searchParams.get("forceMockManager") === "true"
          ? new MockUserManager(userManagerSettings)
          : new UserManager(userManagerSettings);
      this._settings = settings;

      if (this._resolve) this._resolve();
      this._user = await this._getUser();
    } catch (error) {
      if (this._reject) this._reject(error);
      throw error;
    }
  }

  private async _getUser() {
    try {
      await this._loading;
      return await this._userManager?.getUser();
    } catch (error) {
      throw error;
    }
  }

  private async _extendedFetch(
    input: RequestInfo,
    init?: RequestInit | undefined
  ): Promise<Response> {
    try {
      if (!!this._reAuthorizingUser) {
        await this._reAuthorizingUser;
      }

      let options: RequestInit = {
        ...(init || {}),
        headers: {
          "Accept-Language": "nl-NL",
          "Content-Type": "application/json",
        },
      };
      let user = await this._getUser();

      if (user?.expired) {
        this._reAuthorizingUser = this._userManager?.signinSilent();
        user = await this._reAuthorizingUser;
        this._reAuthorizingUser = undefined;
      }

      if (!!user?.access_token) {
        options = {
          ...options,
          headers: {
            ...options.headers,
            Authorization: `Bearer ${user.access_token}`,
          },
        };
      }

      return await this._fetch.bind(this._window)(input, options);
    } catch (error) {
      throw error;
    }
  }

  public http(signal: AbortSignal) {
    const requestInit: RequestInit = {
      signal,
    };

    return {
      fetch: (info: RequestInfo, init?: RequestInit) =>
        this._extendedFetch(info, { ...(init || {}), ...requestInit }),
    };
  }

  public settings() {
    return this._settings;
  }

  public async getUserId(): Promise<string> {
    if (!this._user?.profile?.pid) {
      const user = await this._getUser();
      return user?.profile?.pid || "";
    } else {
      return this._user?.profile?.pid || "";
    }
  }

  public async shouldRedirect() {
    try {
      await this._loading;
      const user = await this._getUser();
      return !user || user.expired;
    } catch (error) {
      throw error;
    }
  }

  public async signinRedirect() {
    try {
      await this._loading;
      await this._userManager?.signinRedirect();
    } catch (error) {
      throw error;
    }
  }

  public async signinRedirectCallback() {
    try {
      await this._loading;
      return await this._userManager?.signinRedirectCallback();
    } catch (error) {
      throw error;
    }
  }

  public async signinSilentCallback() {
    try {
      await this._loading;
      return await this._userManager?.signinSilentCallback();
    } catch (error) {
      throw error;
    }
  }

  public async signout() {
    try {
      await this._loading;
      await this._userManager?.removeUser();
      await this._userManager?.signoutRedirect();
    } catch (error) {
      throw error;
    }
  }

  public async fetchNewToken() {
    try {
      await this._loading;
      this._reAuthorizingUser = this._userManager?.signinSilent();
      await this._reAuthorizingUser;
      this._reAuthorizingUser = undefined;
    } catch (error) {
      throw error;
    }
  }

  public async getDecodedToken() {
    try {
      await this._loading;
      const user = await this._getUser();
      return !user?.access_token ? null : (decode(user.access_token) as any);
    } catch (error) {
      throw error;
    }
  }
}
