import { CadastreSnapShot } from "@kolibri/search-api";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { EditableType } from "enums/editable-types";
import { editablesActions } from "store/editables";

export const adapter = createEntityAdapter<CadastreSnapShot>();

export const slice = createSlice({
  name: "assignments/cadastres",
  initialState: adapter.getInitialState(),
  reducers: {
    add: adapter.addMany,
  },
  extraReducers: builder => {
    builder.addCase(editablesActions.removeEditable, (state, action) => {
      if (action.payload.type === EditableType.Assignment) {
        const ids = adapter
          .getSelectors()
          .selectAll(state)
          .filter(entity => entity.linkedAssignment?.id === action.payload.id)
          .map(entity => entity.id);

        adapter.removeMany(state, ids);
      }
    });
  },
});
