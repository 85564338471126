import { useField } from "formik";
import React, { FC } from "react";
import styles from "./style.module.scss";
import classnames from "classnames";

type Props = {
  name: string;
  hint?: string;
  alignment?: "vertical" | "horizontal";
  label?: string;
  labelPlacement?: "top" | "right" | "left";
  labelClassName?: string;
  children: React.ReactElement | React.ReactElement[];
};

export const RadioCheckboxGroupComponent: FC<Props> = ({
  name: outerName,
  hint,
  alignment,
  children,
  label,
  labelClassName,
  labelPlacement,
}) => {
  const [{ name }, meta] = useField(outerName);

  return (
    <div
      className={classnames(
        styles.radioGroup,
        styles[`label__${labelPlacement || "top"}`]
      )}
      data-name={name}
    >
      {!!label && (
        <label
          className={classnames(
            styles.label,
            styles[labelPlacement || "top"],
            labelClassName
          )}
        >
          {label}
        </label>
      )}

      <div
        className={classnames(styles.inputs, styles[alignment || "vertical"])}
      >
        {React.Children.map(children, (child, idx) => (
          <div className={styles.input} key={idx}>
            {child}
          </div>
        ))}
      </div>
      {meta.touched && meta.error && (
        <div className={styles.error}>{meta.error}</div>
      )}
      {!(meta.touched && meta.error) && hint && (
        <div className={styles.hint}>{hint}</div>
      )}
    </div>
  );
};
