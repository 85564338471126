import { Snackbar, Toast } from "@kolibri/ui";
import CommonIcon from "components/common-icon";
import { useDispatch } from "hooks/dispatch";
import { useSelector } from "hooks/selector";
import React, { FC } from "react";
import {
  actions as toastsActions,
  selectors as toastsSelectors,
} from "store/toasts";
import styles from "./style.module.scss";

export const SnackbarComponent: FC = () => {
  const toasts = useSelector(toastsSelectors.selectAll);
  const dispatch = useDispatch();

  const onRemoveCallback = (id: string) => {
    dispatch(toastsActions.removeToast(id));
  };

  return (
    <Snackbar>
      {toasts.map(toast => (
        <Toast
          key={toast.id}
          id={toast.id}
          message={toast.message}
          theme={toast.theme}
          icon={
            <CommonIcon
              iconName={toast.icon}
              style={{ color: "white", fontSize: 20 }}
            />
          }
          className={toast.isError ? styles.error : undefined}
          canManuallyRemove={toast.canManuallyRemove}
          removeAfter={toast.removeAfter}
          style={toast.style}
          size="medium"
          onRemove={() => onRemoveCallback(toast.id)}
        />
      ))}
    </Snackbar>
  );
};
