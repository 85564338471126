import { PersistConfig, persistReducer } from "redux-persist";
import { slice, adapter } from "./slices";
import storage from "redux-persist/es/storage/session";
import publicationsThunks from "./thunks";
import { RootState } from "store";

const persistConfig: PersistConfig<ReturnType<typeof slice.reducer>> = {
  storage,
  key: "k24/assignments/publications",
};

export const actions = slice.actions;
export const reducer = persistReducer(persistConfig, slice.reducer);
export const selectors = adapter.getSelectors<RootState>(
  state => state.assignments.publications
);
export const thunks = publicationsThunks;
