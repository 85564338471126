import { RelationSnapShot, RelationsSearchResponse } from "@kolibri/core-api";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type State = {
  results: RelationSnapShot[];
  resultCount: number;
  totalResults: number;
};

const initialState: State = {
  results: [],
  resultCount: 0,
  totalResults: 0,
};

export const slice = createSlice({
  name: "relations",
  initialState,
  reducers: {
    setSearchResponse: (
      state,
      action: PayloadAction<RelationsSearchResponse | null>
    ) => {
      return {
        ...state,
        results: action.payload?.results || [],
        resultCount: action.payload?.resultCount || 0,
        totalResults: action.payload?.totalResults || 0,
      };
    },
    appendRelations: (
      state,
      action: PayloadAction<RelationsSearchResponse | null>
    ) => {
      return {
        ...state,
        results: {
          ...(state.results || {
            results: [],
            resultCount: 0,
            totalResults: 0,
          }),
          results: [
            ...state.results,
            ...(action.payload?.results ? action.payload?.results : []),
          ],
        },
      };
    },
  },

  extraReducers: (builder) => {},
});
